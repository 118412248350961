import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { convertToCurrency } from "../../../helpers/functions";

const AccountsTable = props => {
  const { isMobile, accounts, handleAccountClick } = props;

  return isMobile ? (
    // Mobile version
    <TableContainer component={Box}>
      <Table sx={{ maxWidth: 600, border: 0 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ "& th": { borderBottom: 0 } }}>
            <TableCell>
              <Typography variant="fairTableCellHeader">Kontonr.</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="fairTableCellHeader" sx={{ float: "right" }}>
                Totalt marknadsvärde
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="fairTableCellHeader" sx={{ float: "right" }}>
                Avkastning
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts?.map(account => (
            <TableRow
              key={account.AccountNo}
              sx={{ "&:last-child td": { border: 0 } }}
              onClick={handleAccountClick(account)}
            >
              <TableCell>
                <Typography variant="fairTableCell">
                  {account.AccountName}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="fairTableCell">
                  {account.TotalMarketValue
                    ? convertToCurrency(account.TotalMarketValue)
                    : 0}
                  SEK
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="fairTableCell">
                  {account.Performance ? `${account.Performance} %` : "-"}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    // Desktop version
    <TableContainer component={Box}>
      <Table sx={{ minWidth: 600, border: 0 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ "& th": { borderBottom: 0 } }}>
            <TableCell>
              <Typography variant="fairTableCellHeader">Kontonr.</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="fairTableCellHeader">Namn</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="fairTableCellHeader" sx={{ float: "right" }}>
                Totalt marknadsvärde
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts?.map(account => (
            <TableRow
              key={account.AccountNo}
              sx={{
                "&:last-child td": { border: 0 },
                transition: "color 0.3s, background-color 0.3s",
                "&:hover": {
                  backgroundColor: "secondary.main",
                  cursor: "pointer"
                }
              }}
              onClick={handleAccountClick(account)}
            >
              <TableCell>
                <Typography variant="fairTableCell">
                  {account.AccountNo}
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="fairTableCell">
                  {account.AccountName}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="fairTableCell">
                  {account.TotalMarketValue
                    ? convertToCurrency(account.TotalMarketValue)
                    : 0}{" "}
                  SEK
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

AccountsTable.propTypes = {
  isMobile: PropTypes.bool,
  accounts: PropTypes.array,
  handleAccountClick: PropTypes.func
};

export default AccountsTable;
