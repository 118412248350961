import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import WebsiteIcon from "@mui/icons-material/Language";
import PhoneIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/EmailOutlined";

import DashboardPaper from "../../theme/styled/DashboardPaper";

import { filterArrayDupsByObjectPropertyValue } from "../../helpers/functions";

import { DashboardConsumer } from "../../contexts/DashboardProvider";

import AdvisorDefaultImg from "../../assets/img/fair_investments_logo.png";
import ContactSupportImg from "../../assets/img/contact_f.png";

const iconStyle = {
  stroke: "white",
  mr: 1,
  fill: "#000",
  width: "18px",
  height: "18px"
};

const ContactUs = () => {
  const dashboardCtxt = DashboardConsumer();

  const advisors = dashboardCtxt.state?.advisors;

  let contacts = [
    {
      Name: "Kundtjänst",
      OpeningHours: "Helgfria vardagar 09-16",
      PictureUrl: ContactSupportImg,
      Phone: "08-557 799 00",
      Email: "backoffice@fairinvestments.se",
      Website: "https://fairinvestments.se"
    }
  ];

  if (advisors?.length > 0) {
    advisors.forEach(advisor => {
      advisor.Type =
        advisor.Role == "Advisor"
          ? "Rådgivare"
          : advisor.Role == "AccountManager"
          ? "Kontaktperson"
          : advisor.Role == "Assistant"
          ? "Rådgivarassistent"
          : "";
      contacts.unshift(advisor);
    });
  }

  // filter dups (data fix)
  contacts = filterArrayDupsByObjectPropertyValue(contacts, "LegalEntityId");

  return (
    <DashboardPaper>
      <Typography variant="h6" align="left">
        Kontakta oss
      </Typography>

      <List sx={{ width: "100%", maxWidth: 600, pb: 0 }}>
        {contacts.map((contact, key) => {
          const phoneNo =
            contact.Phone || contact.PhoneMobile || contact.PhoneAlternative;

          return (
            <Box key={key}>
              <ListItem
                alignItems="flex-start"
                sx={{
                  py: 2,
                  px: 0,
                  pt: key === 0 ? 1 : 2,
                  pb: key === contacts.length - 1 ? 0 : 2,
                  flexFlow: "column"
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ display: "flex" }}>
                    <ListItemAvatar sx={{ minWidth: 70, mt: 0 }}>
                      <Avatar
                        variant="round"
                        alt="contacts image"
                        src={contact.PictureUrl || AdvisorDefaultImg}
                        sx={{
                          minWidth: 70,
                          minHeight: 70
                        }}
                      />
                    </ListItemAvatar>
                  </Box>
                  <Box sx={{ p: 1, ml: 1 }}>
                    <Typography
                      variant="h7"
                      sx={{ display: "block", mt: 0.38, mb: 1, mx: 0 }}
                    >
                      {contact.Name ||
                        contact.FirstName + " " + contact.LastName}
                    </Typography>
                    {contact.Type && (
                      <Typography
                        variant="subtitle2"
                        sx={{
                          display: "block",
                          mt: -0.625,
                          mb: 0.25,
                          mx: 0,
                          lineHeight: "22px"
                        }}
                      >
                        {contact.Type}
                      </Typography>
                    )}
                    {contact.OpeningHours && (
                      <Typography
                        variant="subtitle2"
                        sx={{
                          display: "block",
                          mt: -0.625,
                          mb: 0.25,
                          mx: 0,
                          lineHeight: "22px"
                        }}
                      >
                        {contact.OpeningHours}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    mt: 1
                  }}
                >
                  {phoneNo && (
                    <Button
                      sx={{
                        color: "primary.lighter",
                        p: 0,
                        textTransform: "none"
                      }}
                      href={`tel:${phoneNo}`}
                    >
                      <Typography
                        variant="body2"
                        color="primary.lighter"
                        sx={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <PhoneIcon sx={iconStyle} />
                        {phoneNo}
                      </Typography>
                    </Button>
                  )}
                  <Button
                    sx={{
                      color: "primary.lighter",
                      p: 0,
                      textTransform: "none"
                    }}
                    href={`mailto:${contact.Email}`}
                  >
                    <Typography
                      variant="body2"
                      color="primary.lighter"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        wordBreak: "break-word"
                      }}
                    >
                      <EmailIcon sx={iconStyle} />
                      {contact.Email}
                    </Typography>
                  </Button>
                  {contact.Website && (
                    <>
                      <Button
                        sx={{
                          color: "primary.lighter",
                          p: 0,
                          textTransform: "none"
                        }}
                        href={contact.Website}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Typography
                          variant="body2"
                          color="primary.lighter"
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <WebsiteIcon sx={iconStyle} />
                          Hemsida
                        </Typography>
                      </Button>
                    </>
                  )}
                </Box>
              </ListItem>
              {contacts.length > 1 && key < 1 && (
                <Divider variant="fullWidth" />
              )}
            </Box>
          );
        })}
      </List>
    </DashboardPaper>
  );
};

export default ContactUs;
