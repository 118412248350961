import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const SectionWrapperBox = props => (
  <Box
    sx={{
      // do not use shorthand on overrides, props need to match exactly!
      display: {
        lg: "flex"
      },
      margin: 0,
      paddingLeft: { xs: 1.5, md: 2.5 },
      paddingRight: { xs: 1.5, md: 2.5 },
      ...props.sx
    }}
    {...props}
  >
    {props.children}
  </Box>
);

export default SectionWrapperBox;

SectionWrapperBox.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object
};
