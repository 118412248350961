import { format } from "date-fns";

export const initialState = {
  agentName: "",
  agentLegalEntityId: "",
  principalName: "",
  principalLegalEntityId: "",
  type: null,
  dateFrom: null,
  dateTo: null,
  comment: "",
  fetching: false,
  inputErrors: null,
  serverMessage: null
};

const editPoaModalReducer = (state, action) => {
  switch (action.type) {
    case "toggle":
      return initialState;
    case "handleInput":
      return {
        ...state,
        [action.field]: action.payload,
        inputErrors: { ...state.inputErrors, [action.field]: null },
        serverMessage: null
      };
    case "handleSetAgent":
      return {
        ...state,
        agentName: action.payload.Name,
        agentLegalEntityId: action.payload.LegalEntityId,
        inputErrors: { ...state.inputErrors, agentLegalEntityId: null },
        serverMessage: null
      };
    case "handleSetCustomer":
      return {
        ...state,
        principalName: action.payload.Name,
        principalLegalEntityId: action.payload.LegalEntityId,
        inputErrors: { ...state.inputErrors, principalLegalEntityId: null },
        serverMessage: null
      };
    case "setInitialState":
      return {
        ...state,
        agentName: action.payload.AgentName,
        agentLegalEntityId: action.payload.AgentLegalEntityId,
        principalName: action.payload.PrincipalName,
        principalLegalEntityId: action.payload.PrincipalLegalEntityId,
        type: action.payload.Type,
        dateFrom: action.payload.StartDate
          ? format(new Date(action.payload.StartDate), "yyyy-MM-dd")
          : null,
        dateTo: action.payload.EndDate
          ? format(new Date(action.payload.EndDate), "yyyy-MM-dd")
          : null,
        comment: action.payload.Comment
      };
    case "resetInput":
      return {
        ...state,
        agentName: "",
        agentLegalEntityId: "",
        principalName: "",
        principalLegalEntityId: "",
        type: null,
        dateFrom: null,
        dateTo: null,
        comment: "",
        fetching: false,
        inputErrors: null,
        serverMessage: null
      };
    case "fetching":
      return { ...state, fetching: action.payload };
    case "handleInputErrors":
      return { ...state, inputErrors: action.payload };
    case "handleServerError":
      return {
        ...state,
        fetching: false,
        serverMessage: { type: "error", text: action.payload }
      };
    case "handleServerSuccess":
      return {
        ...state,
        fetching: false,
        serverMessage: { type: "success", text: action.payload }
      };
    case "resetState":
      return initialState;
    default:
      return state;
  }
};

export default editPoaModalReducer;
