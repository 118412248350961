export default function Typography(theme) {
  return {
    MuiTypography: {
      styleOverrides: {
        h3: {
          color: theme.palette.primary.light
        },
        h4: {
          color: theme.palette.primary.light
        },
        h5: {
          color: theme.palette.primary.light
        },
        h6: {
          color: theme.palette.primary.light
        },
        menuDrawer: {
          color: theme.palette.common.black
        },
        fairTableCell: {
          color: theme.palette.common.black
        },
        fairTableHeaderCell: {
          color: theme.palette.common.black
        }
        // paragraph: {
        //   marginBottom: theme.spacing(2)
        // },
        // gutterBottom: {
        //   marginBottom: theme.spacing(1)
        // }
      }
    }
  };
}
