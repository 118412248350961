import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const TableDesktop = props => {
  const { customers } = props;

  return customers.length > 0 ? (
    <TableContainer component={Box} sx={{ mb: 2 }}>
      <Box
        sx={{
          maxWidth: "100vw",
          border: 0,
          overflowX: "auto",
          overflowY: "hidden"
        }}
      >
        <Table sx={{ minWidth: 480, border: 0, tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "15%", pl: "10px", pb: "5px" }}>
                <Typography variant="fairTableCellHeader">Rådgivare</Typography>
              </TableCell>
              <TableCell sx={{ width: "30%", pl: "10px", pb: "5px" }}>
                <Typography variant="fairTableCellHeader">Kund</Typography>
              </TableCell>
              <TableCell sx={{ width: "22%", pl: "10px", pb: "5px" }}>
                <Typography variant="fairTableCellHeader">
                  Tidigare grupp
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "22%", pl: "10px", pb: "5px" }}>
                <Typography variant="fairTableCellHeader">
                  Aktuell grupp
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "10%", pl: "10px", pb: "5px" }}>
                <Typography variant="fairTableCellHeader">
                  Förändring
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.map(customer => (
              <TableRow
                key={customer.IdentityNo}
                sx={{
                  "&:last-child td": { border: 0 }
                }}
              >
                <TableCell
                  sx={{
                    fontWeight: "inherit",
                    pt: "5px",
                    pb: "5px",
                    pl: "10px",
                    pr: "10px"
                  }}
                >
                  {customer.Advisor &&
                    customer.Advisor.map(advisor => {
                      return (
                        <span
                          key={advisor}
                          style={{
                            display: "block",
                            fontSize: "12px",
                            lineHeight: "16px"
                          }}
                        >
                          {advisor}
                        </span>
                      );
                    })}
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "inherit",
                    pt: "5px",
                    pb: "5px",
                    pl: "10px",
                    pr: "10px"
                  }}
                >
                  {customer.Name}
                  <br />
                  <Typography variant="fairTableCell" color="secondary.lighter">
                    {customer.IdentityNo}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "inherit",
                    pt: "5px",
                    pb: "5px",
                    pl: "10px",
                    pr: "10px"
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      fontSize: "12px",
                      lineHeight: "14px",
                      fontWeight: "bold",
                      padding: "3px 4px",
                      borderRadius: "4px",
                      background: getSegmentColor(customer.PrevSegment),
                      color: getSegmentTextColor(customer.PrevSegment)
                    }}
                  >
                    {formatSegment(customer.PrevSegment)}
                  </span>
                  <br />
                  <span
                    style={{
                      color: "#666",
                      fontSize: "11px",
                      lineHeight: "12px"
                    }}
                  >
                    {customer.PrevNumberOfLogins} inloggningar
                  </span>
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "inherit",
                    pt: "5px",
                    pb: "5px",
                    pl: "10px",
                    pr: "10px"
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      fontSize: "12px",
                      lineHeight: "14px",
                      fontWeight: "bold",
                      padding: "3px 4px",
                      borderRadius: "4px",
                      background: getSegmentColor(customer.ActivitySegment),
                      color: getSegmentTextColor(customer.ActivitySegment)
                    }}
                  >
                    {formatSegment(customer.ActivitySegment)}
                  </span>
                  <br />
                  <span
                    style={{
                      color: "#666",
                      fontSize: "11px",
                      lineHeight: "12px"
                    }}
                  >
                    {customer.NumberOfLogins} inloggningar
                  </span>
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "inherit",
                    pt: "5px",
                    pb: "5px",
                    pl: "10px",
                    pr: "10px"
                  }}
                >
                  +
                  {Math.round(
                    (customer.NumberOfLogins / customer.PrevNumberOfLogins -
                      1) *
                      100
                  )}
                  %
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  ) : (
    <Typography variant="subtitle2" sx={{ mt: 1.25, mb: 2.5 }}>
      Data saknas.
    </Typography>
  );
};

const formatSegment = function (segmentCode) {
  if (segmentCode === "LOW") {
    return "Låg aktivitet";
  }

  if (segmentCode === "MEDIUM") {
    return "Medium aktivitet";
  }

  if (segmentCode === "HIGH") {
    return "Hög aktivitet";
  }

  if (segmentCode === "VHIGH") {
    return "Väldigt hög aktivitet";
  }

  if (segmentCode === "XHIGH") {
    return "Superanvändare";
  }

  return segmentCode;
};

const getSegmentColor = function (segmentCode) {
  if (segmentCode === "LOW") {
    return "#999";
  }

  if (segmentCode === "MEDIUM") {
    return "#f6b26b";
  }

  if (segmentCode === "HIGH") {
    return "#67d4cb";
  }

  if (segmentCode === "VHIGH") {
    return "#1f9200";
  }

  if (segmentCode === "XHIGH") {
    return "#ce0000";
  }

  return "#CCC";
};

const getSegmentTextColor = function (segmentCode) {
  if (segmentCode === "HIGH") {
    return "#000";
  }

  return "#FFF";
};

TableDesktop.propTypes = {
  customers: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default TableDesktop;
