import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";

// const MyThemeComponent = styled(Paper)(({ theme }) => ({
//   color: theme.palette.primary.contrastText,
//   backgroundColor: theme.palette.primary.main,
//   padding: theme.spacing(1),
//   borderRadius: theme.shape.borderRadius
// }));

const DashboardPaper = forwardRef((props, ref) => {
  return (
    <Paper
      {...(!props?.override && props)} // override needs string "true" or ommit (false)
      ref={ref}
      elevation={3}
      sx={{
        // do not use shorthand on overrides, props need to match exactly!
        marginTop: { xs: 2, md: 4 },
        marginBottom: { xs: 2, md: 4 },
        padding: { xs: 2, md: 2.5 },
        ...props.sx
      }}
      {...(props.override && props)}
    >
      {props.children}
    </Paper>
  );
});
DashboardPaper.displayName = "DashboardPaper";

export default DashboardPaper;

DashboardPaper.propTypes = {
  override: PropTypes.string,
  ref: PropTypes.object,
  innerRef: PropTypes.object,
  sx: PropTypes.object,
  children: PropTypes.node
};
