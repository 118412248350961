import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountIcon from "@mui/icons-material/PersonOutlined";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

const MobileMenu = props => {
  const handlers = props.handlers;

  const {
    locationPath,
    settings,
    anchorElUser,
    handleOpenNavMenu,
    handleOpenUserMenu,
    handleCloseUserMenu
  } = handlers;

  return (
    <>
      <Tooltip title="Open settings">
        <IconButton size="small" onClick={handleOpenUserMenu}>
          <AccountIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{
          "& .MuiPaper-root": { borderRadius: 1, width: 160 },
          "& .MuiPaper-root ul": { p: 0 }
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        // transformOrigin={{
        //   vertical: "top",
        //   horizontal: "center"
        // }}
        transitionDuration={0}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting, key) => (
          <MenuItem
            key={key}
            onClick={() => setting.handleClick()}
            sx={{
              p: 0,
              height: 35,
              minHeight: "unset",
              color:
                setting.navTo === locationPath
                  ? "common.white"
                  : "common.black",
              backgroundColor:
                setting.navTo === locationPath ? "primary.light" : "transparent"
            }}
          >
            <Box
              sx={{
                mt: 0.8,
                ml: setting.icon ? 1.8 : 0,
                "& .MuiSvgIcon-root": { fontSize: "1rem" }
              }}
            >
              {setting?.icon}
            </Box>
            <Typography
              sx={{
                fontSize: "0.875rem",
                px: setting.icon ? 0 : 2,
                ml: setting.icon ? 0.6 : 0
              }}
            >
              {setting.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <IconButton
        size="small"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        sx={{
          display: { xs: "inline", md: "none" },
          px: 1,
          pt: 1,
          ml: 1
        }}
      >
        <MenuIcon fontSize="large" />
      </IconButton>
    </>
  );
};

export default MobileMenu;

MobileMenu.propTypes = {
  handlers: PropTypes.object
};
