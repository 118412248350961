const editUserModalReducer = (state, action) => {
  switch (action.type) {
    case "setState":
      return {
        ...action.payload
      };
    case "handleInput":
      return {
        ...state,
        [action.field]: action.payload,
        inputErrors: { ...state.inputErrors, [action.field]: null },
        serverMessage: null
      };
    case "fetching":
      return { ...state, fetching: action.payload };
    case "handleInputErrors":
      return { ...state, inputErrors: action.payload };
    case "handleServerError":
      return {
        ...state,
        fetching: false,
        serverMessage: { type: "error", text: action.payload }
      };
    case "handleServerSuccess":
      return {
        ...state,
        fetching: false,
        serverMessage: { type: "success", text: action.payload }
      };
    default:
      return state;
  }
};

export default editUserModalReducer;
