// import logo from "./logo.svg";
import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import { CircularProgress } from "@mui/material";

import SectionHeaderPaper from "../../theme/styled/SectionHeaderPaper.js";
import SectionWrapperBox from "../../theme/styled/SectionWrapperBox.js";
import DashboardPaper from "../../theme/styled/DashboardPaper.js";

import useAdminSettings from "./useAdminSettings";
import ConfirmNoticeChangeModal from "./ConfirmNoticeChangeModal";
import ConfirmBlockChangeModal from "./ConfirmBlockChangeModal";
import ConfirmHistoricHoldingsModal from "./ConfirmHistoricHoldingsModal";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 30,
  height: 17,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)"
    }
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "primary.main"
      }
    }
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 13,
    height: 13,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200
    })
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box"
  }
}));

const AdminSettingsSection = () => {
  const {
    state,
    handleClickNoticeSwitch,
    handleClickBlockSwitch,
    // handleClickHistoricHoldingsSwitch,
    toggleNoticeConfirmationModal,
    toggleBlockConfirmationModal,
    toggleHistoricHoldingsConfirmationModal,
    handleNoticeModalChange,
    handleBlockModalChange,
    handleHistoricHoldingsModalChange
  } = useAdminSettings();

  return !state.loaded ? (
    <Box
      sx={{
        height: "100%",
        minHeight: "80vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box>
      <SectionHeaderPaper>
        <Typography variant="h3" align="left">
          Inställningar
        </Typography>
      </SectionHeaderPaper>
      <SectionWrapperBox sx={{ flexFlow: "column", mx: 2.5, mt: 5 }}>
        <DashboardPaper>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "space-between",
              width: "100%",
              height: 65,
              p: 1
            }}
          >
            Visa felmeddelande för kunder
            <AntSwitch
              checked={state.noticeModal}
              onChange={handleClickNoticeSwitch}
              sx={{ float: "right" }}
            />
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "space-between",
              width: "100%",
              height: 65,
              p: 1
            }}
          >
            Stäng ner hela portalen, blockera användare och visa felmeddelande.
            <AntSwitch
              checked={state.blockModal}
              onChange={handleClickBlockSwitch}
              sx={{ float: "right" }}
            />
          </Box>
          {/* <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "space-between",
              width: "100%",
              height: 65,
              p: 1
            }}
          >
            Växla till säkerhetskopia.
            <AntSwitch
              checked={state.historicHoldings}
              onChange={handleClickHistoricHoldingsSwitch}
              sx={{ float: "right" }}
            />
          </Box> */}
        </DashboardPaper>
      </SectionWrapperBox>{" "}
      <ConfirmNoticeChangeModal
        open={state.showNoticeModalConfirmation}
        currentStatus={state.noticeModal}
        toggleModal={toggleNoticeConfirmationModal}
        handleNoticeModalChange={handleNoticeModalChange}
      />
      <ConfirmBlockChangeModal
        open={state.showBlockModalConfirmation}
        currentStatus={state.blockModal}
        toggleModal={toggleBlockConfirmationModal}
        handleBlockModalChange={handleBlockModalChange}
      />
      <ConfirmHistoricHoldingsModal
        open={state.showHistoricHoldingsConfirmation}
        currentStatus={state.historicHoldings}
        toggleModal={toggleHistoricHoldingsConfirmationModal}
        handleHistoricHoldingsModalChange={handleHistoricHoldingsModalChange}
      />
    </Box>
  );
};
export default AdminSettingsSection;
