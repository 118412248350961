import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const SectionInnerLeftWrapBox = props => (
  <Box
    sx={{
      // do not use shorthand on overrides, props need to match exactly!
      width: { xs: "100%", lg: "65%", xl: "70%" },
      marginRight: { md: 2.5, xl: 3 },
      ...props.sx
    }}
    {...props}
  >
    {props.children}
  </Box>
);

export default SectionInnerLeftWrapBox;

SectionInnerLeftWrapBox.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object
};
