import React from "react";
import Box from "@mui/material/Box";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import DashboardPaper from "../../theme/styled/DashboardPaper";
import OwnerImg from "../../assets/img/owner.png";

const OwnerMessage = () => {
  return (
    <DashboardPaper>
      <Typography variant="h6" align="left">
        Fair Investments roller i din förvaltning
      </Typography>

      <Box sx={{ display: "flex", alignItems: "flex-end", mt: 2 }}>
        <Box sx={{ display: "flex" }}>
          <ListItemAvatar sx={{ minWidth: 120, mt: 0 }}>
            <Avatar
              variant="round"
              alt="contacts image"
              src={OwnerImg}
              sx={{
                minWidth: 120,
                minHeight: 120
              }}
            />
          </ListItemAvatar>
        </Box>
        <Box sx={{ p: 1, ml: 1 }}>
          <Typography
            variant="h7"
            sx={{ display: "block", mt: 0.38, mb: 1, mx: 0 }}
          >
            Johan Bynélius
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              display: "block",
              mt: -0.625,
              mb: 0.25,
              mx: 0,
              lineHeight: "22px"
            }}
          >
            Grundare & Ansvarig Förvaltare
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography
          variant="h7"
          sx={{ color: "primary.lighter", fontWeight: "bold" }}
        >
          Hej!
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1 }}>
          Det är jag som är Johan Bynélius och är den som förvaltar ditt
          kapital. Det gör jag genom en noggrant framtagen och bevisad metodik
          som historiskt har genererat en hög avkastning med stor och långsiktig
          säkerhet.
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1 }}>
          Vill du veta mer om min investeringsfilosofi{" "}
          <Link
            href="https://fairinvestments.se/investeringsfilosofi"
            rel="noopener noreferrer"
            target="_blank"
            sx={{ fontWeight: "bold", color: "primary.lighter" }}
          >
            klicka här
          </Link>
          .
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1 }}>
          För mer kontinuerlig information, följ mig gärna på LinkedIn{" "}
          <Link
            href="https://se.linkedin.com/in/johanbynelius"
            rel="noopener noreferrer"
            target="_blank"
            sx={{ fontWeight: "bold", color: "primary.lighter" }}
          >
            här
          </Link>{" "}
          och/eller signa upp för att motta mitt investeringsbrev{" "}
          <Link
            href="https://fairinvestments.se/nyheter"
            rel="noopener noreferrer"
            target="_blank"
            sx={{ fontWeight: "bold", color: "primary.lighter" }}
          >
            här
          </Link>
          .
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 1 }}>
          Tack för att du har gett Fair Investment ditt förtroende att hjälpa
          dig nå dina drömmar.
        </Typography>
      </Box>
    </DashboardPaper>
  );
};

export default OwnerMessage;
