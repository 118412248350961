import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

import useSettingsProvider from "../hooks/useSettingsProvider";

const SettingsCtxt = createContext(null);

const SettingsProvider = ({ children }) => {
  const settingsProv = useSettingsProvider();

  return (
    settingsProv.isLoaded && (
      <SettingsCtxt.Provider value={settingsProv}>
        {children}
      </SettingsCtxt.Provider>
    )
  );
};

export const SettingsConsumer = () => {
  return useContext(SettingsCtxt);
};

export default SettingsProvider;

SettingsProvider.propTypes = {
  children: PropTypes.node
};
