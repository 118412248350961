import React from "react";
import PropTypes from "prop-types";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Typography, CircularProgress } from "@mui/material";

const LockSpinnerModal = props => {
  return (
    <div>
      <Modal open={props.open}>
        <Box
          sx={{
            height: "100%",
            minHeight: "80vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CircularProgress />
          <br />
          <Typography color="black">Var god vänta... </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default LockSpinnerModal;

LockSpinnerModal.propTypes = {
  open: PropTypes.bool
};
