import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";

const WarningMessage = props => {
  const navigate = useNavigate();
  const { type, isAdmin } = props;

  return (
    <Box
      sx={{
        color: "#000000",
        bgcolor: "#f4dddd",
        marginTop: { xs: 1.5, md: 2.5 },
        marginLeft: { xs: 1.5, md: 2.5 },
        marginRight: { xs: 1.5, md: 2.5 },
        padding: { xs: 1.5, md: 2.5 },
        border: "1px dashed red"
      }}
    >
      {type === "blockModal" && (
        <Fragment>
          <b>OBS!</b> Kundportalen är för närvarande avstängd och ej tillgänglig
          för kunder.{" "}
          {isAdmin && (
            <span>
              För att ändra, gå till{" "}
              <Link href="#" onClick={() => navigate("/dashboard/settings")}>
                inställningar
              </Link>
              .
            </span>
          )}
        </Fragment>
      )}
      {type === "historicHoldings" && (
        <Fragment>
          <b>OBS!</b> Kundportalen är för närvarande i säkerhetskopieläge.{" "}
          {isAdmin && (
            <span>
              För att ändra, gå till{" "}
              <Link href="#" onClick={() => navigate("/dashboard/settings")}>
                inställningar
              </Link>
              .
            </span>
          )}
        </Fragment>
      )}
    </Box>
  );
};

export default WarningMessage;

WarningMessage.propTypes = {
  type: PropTypes.string,
  isAdmin: PropTypes.bool
};
