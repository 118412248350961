import React, { useState, useRef, Fragment } from "react";

import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CircularProgress } from "@mui/material";

import FairModalBox from "../../../../theme/styled/FairModalBox";
import FairCloseModalBox from "../../../../theme/styled/FairCloseModalBox";
import FairButton from "../../../../theme/styled/FairButton";

import AgentSelect from "../AgentSelect";
import CustomerSelect from "../CustomerSelect";
import useAddPoaModal from "./useAddPoaModal";

const AddPoaModal = props => {
  const {
    open,
    loaded,
    state,
    dispatch,
    toggle,
    setAgent,
    customersList,
    setCustomer,
    handleInputChange,
    handleDateChange,
    handleSubmit
  } = useAddPoaModal(props);

  const { fetching, inputErrors, serverMessage } = state;

  const [dateFromFocused, setDateFromFocused] = useState(false);
  const [dateToFocused, setDateToFocused] = useState(false);
  const dateFromRef = useRef(null);
  const dateToRef = useRef(null);

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          toggle();
        }
      }}
      sx={{
        overflow: "scroll"
      }}
    >
      <FairModalBox
        sx={{
          height: { xs: "90vh", sm: 725 },
          overflow: { xs: "auto", sm: "unset" }
        }}
      >
        {!loaded ? (
          <Box
            sx={{
              height: "100%",
              minHeight: "80vh",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Fragment>
            <Box
              sx={{
                height: 38,
                px: 4,
                pb: 5
              }}
            >
              <Typography variant="h6" color="primary.main">
                Skapa fullmakt
              </Typography>
            </Box>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{
                height: { xs: 650, sm: 600 },
                justifyContent: "center",
                alignItems: "flex-start",
                display: "flex",
                flexFlow: "column",
                px: 4
              }}
            >
              <AgentSelect
                customers={customersList}
                setAgent={setAgent}
                error={inputErrors?.agentLegalEntityId}
              />
              <CustomerSelect
                customers={customersList}
                setCustomer={setCustomer}
                error={inputErrors?.principalLegalEntityId}
              />

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start"
                }}
              >
                <Box sx={{ mb: 1 }}>
                  <InputLabel
                    htmlFor="agent-type-select"
                    id="agent-type-select-label"
                    sx={{
                      textAlign: "left",
                      fontSize: 14,
                      fontWeight: 500,
                      mx: 1,
                      color: "common.black"
                    }}
                  >
                    Typ
                  </InputLabel>
                </Box>

                <FormControl
                  sx={{ height: 40, width: { xs: "100%", sm: 200 }, mb: 1 }}
                >
                  <Select
                    size="small"
                    labelId="agent-type-select-label"
                    id="type"
                    name="type"
                    value={state.type || ""}
                    onChange={e => {
                      handleInputChange(e);
                    }}
                    sx={{ fontSize: "0.8rem", textAlign: "left" }}
                  >
                    <MenuItem value="Full" sx={{ fontSize: "0.8rem" }}>
                      Full
                    </MenuItem>
                    <MenuItem value="View" sx={{ fontSize: "0.8rem" }}>
                      Insyn
                    </MenuItem>
                  </Select>
                  <FormHelperText error>{inputErrors?.type}</FormHelperText>
                </FormControl>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <InputLabel
                  htmlFor="date-select"
                  id="date-select-label"
                  sx={{
                    textAlign: "left",
                    fontSize: 14,
                    fontWeight: 500,
                    mt: 2,
                    mb: 1,
                    color: "common.black"
                  }}
                >
                  Aktiva datum
                </InputLabel>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "flex-start"
                  }}
                >
                  <DatePicker
                    size="small"
                    ref={dateFromRef}
                    inputFormat="yyyy-MM-dd"
                    id="dateFrom"
                    name="dateFrom"
                    label="Från"
                    value={state.dateFrom}
                    minDate={new Date("2010-01-01")}
                    maxDate={
                      (state.dateTo && new Date(state.dateTo)) ||
                      new Date("2035-01-01")
                    }
                    onChange={date => {
                      handleDateChange("dateFrom", date);
                    }}
                    renderInput={({ inputProps, InputProps, ...params }) => (
                      <>
                        <TextField
                          {...params}
                          size="small"
                          onFocus={() => {
                            setDateFromFocused(true);
                          }}
                          onBlur={event => {
                            const isClearButton =
                              dateFromRef.current.contains(
                                event.relatedTarget
                              ) &&
                              event.relatedTarget?.className
                                ?.split(" ")
                                ?.includes("deleteFromIcon");
                            // Do not set focus to false if clicking on the clear button to avoid removing it before the onClick is registered
                            if (!isClearButton) {
                              setDateFromFocused(false);
                            }
                          }}
                          sx={{
                            width: { xs: "100%", sm: "50%" },
                            mr: 1,
                            "& .MuiButtonBase-root": {
                              pl: 0
                            },
                            "& .MuiFormLabel-root": { fontSize: "0.8rem" },
                            "& .MuiSvgIcon-root": { fontSize: "1.1rem" },
                            "& .deleteFromIcon": {
                              px: 0,
                              visibility: dateFromFocused ? "visible" : "hidden"
                            }
                          }}
                          InputProps={{
                            ...InputProps,
                            sx: { fontSize: "0.8rem", height: 40 },
                            endAdornment: (
                              <>
                                {state.dateFrom && (
                                  <IconButton
                                    className="deleteFromIcon"
                                    onClick={() => {
                                      dispatch({
                                        type: "handleInput",
                                        field: "dateFrom",
                                        payload: null
                                      });
                                      dateFromRef.current
                                        .querySelector("input")
                                        .focus();
                                    }}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                )}
                                {InputProps?.endAdornment}
                              </>
                            )
                          }}
                          inputProps={{
                            ...inputProps,
                            placeholder: "åååå-mm-dd"
                          }}
                          InputLabelProps={{
                            sx: { top: "2px" }
                          }}
                        />
                      </>
                    )}
                  />
                  <DatePicker
                    size="small"
                    ref={dateToRef}
                    inputFormat="yyyy-MM-dd"
                    id="dateTo"
                    name="dateTo"
                    label="Till"
                    value={state.dateTo}
                    minDate={
                      (state.dateFrom && new Date(state.dateFrom)) ||
                      new Date("2010-01-01")
                    }
                    maxDate={new Date("2035-01-01")}
                    onChange={date => {
                      handleDateChange("dateTo", date);
                    }}
                    renderInput={({ inputProps, InputProps, ...params }) => (
                      <TextField
                        {...params}
                        size="small"
                        onFocus={() => {
                          setDateToFocused(true);
                        }}
                        onBlur={event => {
                          const isClearButton =
                            dateToRef.current.contains(event.relatedTarget) &&
                            event.relatedTarget?.className
                              ?.split(" ")
                              ?.includes("deleteToIcon");
                          // Do not set focus to false if clicking on the clear button to avoid removing it before the onClick is registered
                          if (!isClearButton) {
                            setDateToFocused(false);
                          }
                        }}
                        sx={{
                          width: { xs: "100%", sm: "50%" },
                          mt: { xs: 2, sm: 0 },
                          mr: 1,
                          "& .MuiButtonBase-root": {
                            pl: 0
                          },
                          "& .MuiFormLabel-root": { fontSize: "0.8rem" },
                          "& .MuiSvgIcon-root": { fontSize: "1.1rem" },
                          "& .deleteToIcon": {
                            px: 0,
                            visibility: dateToFocused ? "visible" : "hidden"
                          }
                        }}
                        InputProps={{
                          ...InputProps,
                          sx: { fontSize: "0.8rem", height: 40 },
                          endAdornment: (
                            <>
                              {state.dateTo && (
                                <IconButton
                                  className="deleteToIcon"
                                  onClick={() => {
                                    dispatch({
                                      type: "handleInput",
                                      field: "dateTo",
                                      payload: null
                                    });
                                    dateToRef.current
                                      .querySelector("input")
                                      .focus();
                                  }}
                                >
                                  <ClearIcon />
                                </IconButton>
                              )}
                              {InputProps?.endAdornment}
                            </>
                          )
                        }}
                        inputProps={{
                          ...inputProps,
                          placeholder: "åååå-mm-dd"
                        }}
                        InputLabelProps={{
                          sx: { top: "2px" }
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <InputLabel
                  htmlFor="comments-text"
                  id="comments-text-label"
                  sx={{
                    textAlign: "left",
                    fontSize: 14,
                    fontWeight: 500,
                    mt: 2,
                    mb: 1,
                    color: "common.black"
                  }}
                >
                  Kommentarer
                </InputLabel>
                <TextField
                  // inputRef={
                  //   (isReply && (input => input && input.focus())) || null
                  // }
                  value={state.comment}
                  fullWidth
                  multiline
                  rows={2}
                  // maxRows={10}
                  id="comment"
                  name="comment"
                  onChange={e => {
                    handleInputChange(e);
                  }}
                  // helperText={inputErrors?.body}
                  sx={{
                    mb: 3
                  }}
                />
              </Box>
              <Box
                sx={{
                  height: 90,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <CircularProgress
                  color="primary"
                  sx={{
                    display: fetching ? "flex" : "none"
                  }}
                />
                <Alert
                  severity={
                    serverMessage?.type === "success" ? "success" : "error"
                  }
                  sx={{
                    display: serverMessage?.text ? "flex" : "none",
                    width: "100%"
                  }}
                >
                  {serverMessage?.text}
                </Alert>
              </Box>

              <FairButton
                type="submit"
                fullWidth
                sx={{
                  mt: 0,
                  mb: 2,
                  color: "primary.main"
                }}
              >
                Spara
              </FairButton>
            </Box>

            <FairCloseModalBox sx={{ height: 50 }}>
              <Button
                onClick={toggle}
                sx={{
                  color: "primary.lighter"
                }}
              >
                Avbryt
              </Button>
            </FairCloseModalBox>
          </Fragment>
        )}
      </FairModalBox>
    </Modal>
  );
};

export default AddPoaModal;
