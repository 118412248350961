import { isEmptyObject, isValidSwedishPhoneNumber } from "../helpers/functions";
import isEmail from "validator/es/lib/isEmail";
import isStrongPassword from "validator/es/lib/isStrongPassword";
import isMobilePhone from "validator/es/lib/isMobilePhone";
// import isNumeric from "validator/es/lib/isNumeric";

const isIdentityNo = string => {
  const re = new RegExp("^(?:[0-9]{8}|[0-9]{6})[-]{1}[0-9]{4}$"); // only allow 6 or 8 numbers + "-" + 4 numbers
  return re.test(string);
};

const validateFields = fields => {
  let errors = {};
  for (const input in fields) {
    const value = fields[input];

    switch (input) {
      // POA inputs
      case "agentLegalEntityId":
        if (!value || value.length !== 36) {
          errors = {
            ...errors,
            agentLegalEntityId: "Vänligen ange en giltig fullmaktstagare."
          };
        }
        break;
      case "principalLegalEntityId":
        if (!value || value.length !== 36) {
          errors = {
            ...errors,
            principalLegalEntityId: "Vänligen ange en giltig fullmaktsgivare."
          };
        }
        break;
      case "type":
        if (!value || (value !== "Full" && value !== "View")) {
          errors = {
            ...errors,
            type: "Vänligen ange en giltig typ."
          };
        }
        break;
      // User inputs
      case "username":
        if (!value || value.length < 3) {
          errors = {
            ...errors,
            username: "Vänligen ange ett giltigt användarnamn."
          };
        }
        break;
      case "identityNo":
        if (!value || value.length < 11 || !isIdentityNo(value)) {
          errors = {
            ...errors,
            identityNo: "Vänligen ange ett giltigt personnummer."
          };
        }
        break;
      case "firstname":
        if (!value || value.length < 1) {
          errors = {
            ...errors,
            firstname: "Vänligen ange ett giltigt förnamn."
          };
        }
        break;
      case "lastname":
        if (!value || value.length < 1) {
          errors = {
            ...errors,
            lastname: "Vänligen ange ett giltigt efternamn."
          };
        }
        break;
      case "password":
        if (!value) {
          errors = {
            ...errors,
            password: "Vänligen ange ett lösenord."
          };
        }
        break;
      case "passwordReset":
        if (!value) {
          errors = {
            ...errors,
            passwordReset: "Vänligen ange ett lösenord."
          };
        }
        if (!isStrongPassword(value, { minSymbols: 0 })) {
          errors = {
            ...errors,
            confirmPasswordReset:
              "Var vänlig ange ett lösenord som innehåller minst åtta tecken. Utav dessa ska minst ett tecken vara en siffra, en stor bokstav och en liten bokstav."
          };
        }
        break;
      case "confirmPasswordReset":
        if (!value) {
          errors = {
            ...errors,
            confirmPasswordReset: "Vänligen bekräfta ditt nya lösenord."
          };
        }
        if (value !== fields["passwordReset"]) {
          errors = {
            ...errors,
            confirmPasswordReset: "Lösenordet matchar inte."
          };
        }
        break;
      case "email":
        if (!value || !isEmail(value)) {
          errors = {
            ...errors,
            email: "Vänligen ange en giltigt mailadress."
          };
        }
        break;
      case "telephone":
        // Temp function to check is valid swedish phone number
        // https://github.com/validatorjs/validator.js/pull/2181
        if (
          !value ||
          (!isMobilePhone(value) && !isValidSwedishPhoneNumber(value))
        ) {
          errors = {
            ...errors,
            telephone: "Vänligen ange ett giltigt telefonnummer."
          };
        }
        break;
      case "telephoneAlt":
        if (
          !value ||
          (!isMobilePhone(value) && !isValidSwedishPhoneNumber(value))
        ) {
          errors = {
            ...errors,
            telephoneAlt: "Vänligen ange ett giltigt alternativt telefonnummer."
          };
        }
        break;
      case "role":
        if (!value) {
          errors = {
            ...errors,
            role: "Ange en giltig användarroll."
          };
        }
        break;
      // email inputs
      case "subject":
        if (!value || value.length < 1) {
          errors = {
            ...errors,
            subject: "Ämnet är tomt."
          };
        }
        break;
      case "toRecipients":
        if (!value || value.length < 1) {
          errors = {
            ...errors,
            toRecipients: "Ogiltig mottagare."
          };
        }
        break;
      case "body":
        if (!value || value.length < 1) {
          errors = {
            ...errors,
            body: "Meddelandet är tomt."
          };
        }
        break;
      // feedback inputs
      case "satisfaction":
        if (!value || value.length < 1) {
          errors = {
            ...errors,
            satisfaction: "Please answer."
          };
        }
        break;
      case "wouldRecommend":
        if (!value || value.length < 1) {
          errors = {
            ...errors,
            wouldRecommend: "Please answer."
          };
        }
        break;
      case "customList":
        if (!value || value.length < 1) {
          errors = {
            ...errors,
            customList: "Ladda upp minst 1 textfil med lista över mottagare."
          };
        }
        break;
      default:
        break;
    }
  }

  if (!isEmptyObject(errors)) {
    return errors;
  }

  return false;
};

export default validateFields;
