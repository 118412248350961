//////
//  !!! NOTE: YOU CAN'T USE SHORTHAND ON STYLES FOR THESE i.e. my: 1 !!!
//////

// import Card from './Card';
// import Paper from './Paper';
// import Input from './Input';
import Button from "./Button";
import IconButton from "./IconButton";
import ListItemIcon from "./ListItemIcon";
// import Tooltip from './Tooltip';
// import Backdrop from './Backdrop';
import Typography from "./Typography";
import TableCell from "./TableCell";
import CssBaseline from "./CssBaseline";
// import Autocomplete from './Autocomplete';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return Object.assign(
    // Card(theme),
    // Input(theme),
    // Paper(theme),
    Button(theme),
    IconButton(theme),
    ListItemIcon(theme),
    // Tooltip(theme),
    // Backdrop(theme),
    Typography(theme),
    TableCell(theme),
    CssBaseline(theme)
    // Autocomplete(theme)
  );
}
