import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

import FairModalBox from "../../../theme/styled/FairModalBox";
import FairButton from "../../../theme/styled/FairButton";
import useAxios from "../../../hooks/useAxios";
import useErrorHandler from "../../../hooks/useErrorHandler";

import { DashboardConsumer } from "../../../contexts/DashboardProvider";

const KYC_URL = process.env.REACT_APP_KYC_URL;

const KycModal = props => {
  const mounted = useRef(false);
  const navigate = useNavigate();
  const { initAxios } = useAxios();
  const axiosRequestController = useRef(null);
  const errorHandler = useErrorHandler();
  const dashboardCtxt = DashboardConsumer();
  const [contextQueryString, setContextQueryString] = useState(null);

  const { user, elevatedUser } = props;

  const skipKyc = () => {
    dashboardCtxt.setState(prevState => ({
      ...prevState,
      isKycRequired: false
    }));
  };

  const poaSwitchUser = async () => {
    const { axiosInstance } = initAxios("auth");
    try {
      await axiosInstance.get("auth/switch/elevated");
      return navigate("/poa");
    } catch (err) {
      errorHandler.serverError(err);
    }
  };

  useEffect(() => {
    mounted.current = true;
    const getKycCheckString = async () => {
      try {
        const { axiosInstance, axiosController } = initAxios("private");
        axiosRequestController.current = axiosController;
        const { data } = await axiosInstance.get("user/kycCheck");
        return data;
      } catch (err) {
        errorHandler.serverError(err);
      }
    };

    if (!contextQueryString) {
      getKycCheckString().then(checkString => {
        const personalNumber = user.IdentityNo.replace("-", "");
        const contextObjJasonStr = JSON.stringify({
          customer: { id: personalNumber, check: checkString }
        });
        setContextQueryString(btoa(contextObjJasonStr));
      });
    }
  }, [contextQueryString, user.IdentityNo, initAxios, errorHandler]);

  return (
    <div>
      <Modal open={true}>
        <FairModalBox sx={{ height: 430 }}>
          <Box
            sx={{
              height: 50,
              px: 4,
              pb: 5
            }}
          >
            <Typography variant="h6" color="primary.main" sx={{ mb: 1 }}>
              Kundkännedom (KYC)
            </Typography>
          </Box>
          <Box
            sx={{
              height: 210,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexFlow: "column",
              px: 4
            }}
          >
            <Typography variant="body2" color="black.common" sx={{ mb: 1 }}>
              Då det var ett tag sedan sist har det blivit dags att uppdatera
              dina kundkännedomsuppgifter. Detta är ett krav enligt svensk lag i
              syfte att bekämpa penningtvätt, bedrägerier och annan illegal
              aktivitet.
            </Typography>
            <Typography variant="body2" color="black.common" sx={{ mb: 1 }}>
              Klicka på knappen nedan, fyll i formuläret och signera med
              Bank-ID.
            </Typography>
          </Box>
          <Box
            sx={{
              height: 100,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexFlow: "column",
              px: 4
            }}
          >
            <div>
              <FairButton
                fullWidth
                // onClick={startPoolingKyc}
                href={`${KYC_URL}?context=${contextQueryString}`}
                rel="noopener noreferrer"
                target="_self"
                sx={{
                  color: "primary.main"
                }}
              >
                Öppna formulär för kundkännedom
              </FairButton>
              {elevatedUser?.IsPoaAgent && (
                <FairButton
                  fullWidth
                  onClick={poaSwitchUser}
                  sx={{
                    color: "primary.main"
                  }}
                >
                  Byt användare
                </FairButton>
              )}
              <Button
                fullWidth
                onClick={skipKyc}
                sx={{
                  color: "primary.lighter",
                  my: 1
                }}
              >
                Fyll i kundkännedom senare
              </Button>
            </div>
          </Box>
        </FairModalBox>
      </Modal>
    </div>
  );
};

export default KycModal;

KycModal.propTypes = {
  user: PropTypes.object,
  elevatedUser: PropTypes.object,
  logout: PropTypes.func
};
