import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import DashboardPaper from "../../theme/styled/DashboardPaper";
import FairButton from "../../theme/styled/FairButton";
import FairTabs from "../../theme/styled/FairTabs";

import useResponsive from "../../hooks/useResponsive";

import TableDesktop from "./TableDesktop";
import TableMobile from "./TableMobile";

const Messages = props => {
  const isMobile = useResponsive("only", "xs");
  const navigate = useNavigate();
  const {
    dashboard,
    mailbox,
    audiences,
    messages,
    handleMailboxChange,
    openMessage
  } = props;

  return (
    <DashboardPaper>
      <Box>
        {dashboard && (
          <Typography variant="h6" align="left">
            Mina meddelanden
          </Typography>
        )}
        {!dashboard && (
          <FairTabs
            value={mailbox}
            onChange={handleMailboxChange}
            aria-label="mailbox tabs"
          >
            <Tab
              disableRipple
              disableFocusRipple
              label="Inkorg"
              value="inbox"
              sx={{ alignItems: "flex-start", px: 0, mr: 0.5, minWidth: 60 }}
            />
            <Tab
              disableRipple
              disableFocusRipple
              label="Skickade"
              value="sent"
              sx={{ alignItems: "flex-start", px: 0, mr: 0.5, minWidth: 60 }}
            />
          </FairTabs>
        )}

        {isMobile ? (
          <TableMobile
            dashboard={dashboard}
            isSent={mailbox === "sent"}
            audiences={audiences}
            messages={messages}
            openMessage={openMessage || false}
          />
        ) : (
          <TableDesktop
            dashboard={dashboard}
            isSent={mailbox === "sent"}
            audiences={audiences}
            messages={messages}
            openMessage={openMessage || false}
          />
        )}
        {dashboard && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <FairButton onClick={() => navigate("/dashboard/meddelanden")}>
              Se alla meddelanden
            </FairButton>
          </Box>
        )}
      </Box>
    </DashboardPaper>
  );
};

Messages.propTypes = {
  dashboard: PropTypes.bool,
  mailbox: PropTypes.string,
  handleMailboxChange: PropTypes.func,
  audiences: PropTypes.object,
  messages: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  openMessage: PropTypes.func
};

export default Messages;
