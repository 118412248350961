import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FairButton from "../../../theme/styled/FairButton";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";

import useFeedbackForm from "./useFeedbackForm";

const FeedbackForm = props => {
  const { isMobile, handleClose, state, handleInputChange, handleSubmit } =
    useFeedbackForm(props);

  const {
    satisfaction,
    wouldRecommend,
    otherFeedback,
    submitted,
    sending,
    inputErrors,
    serverMessage
  } = state;

  return (
    <Box sx={{ width: isMobile ? "auto" : "400px", mx: 3, my: 2 }}>
      <Box
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: "15px",
          cursor: "pointer"
        }}
      >
        <CloseIcon />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h6">Feedback</Typography>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{
          width: "100%",
          minHeight: "500px",
          justifyContent: "center",
          alignItems: "flex-start",
          display: "flex",
          flexFlow: "column"
        }}
      >
        {!submitted ? (
          <Box
            sx={{
              width: "100%",
              height: "425px"
            }}
          >
            <InputLabel
              htmlFor="satisfaction"
              sx={{
                fontSize: 14,
                fontWeight: 500,
                mt: 1.5,
                mb: 0.5,
                color: "common.black"
              }}
            >
              Är du nöjd med din rådgivning?
            </InputLabel>
            <TextField
              size="small"
              value={satisfaction || ""}
              required
              fullWidth
              multiline
              rows={3}
              id="satisfaction"
              name="satisfaction"
              inputProps={{ maxLength: 1000 }}
              error={inputErrors?.satisfaction ? true : false}
              onChange={e => {
                handleInputChange(e);
              }}
              sx={{
                minHeight: "110px"
              }}
              helperText={inputErrors?.satisfaction}
            />
            <InputLabel
              htmlFor="wouldRecommend"
              sx={{
                fontSize: 14,
                fontWeight: 500,
                mt: 1.5,
                mb: 0.5,
                color: "common.black"
              }}
            >
              Skulle du rekommendera denna förvaltning?
            </InputLabel>
            <TextField
              size="small"
              value={wouldRecommend || ""}
              required
              fullWidth
              multiline
              rows={3}
              id="wouldRecommend"
              name="wouldRecommend"
              inputProps={{ maxLength: 1000 }}
              error={inputErrors?.wouldRecommend ? true : false}
              onChange={e => {
                handleInputChange(e);
              }}
              sx={{
                minHeight: "110px"
              }}
              helperText={inputErrors?.wouldRecommend}
            />
            <InputLabel
              htmlFor="otherFeedback"
              sx={{
                fontSize: 14,
                fontWeight: 500,
                mt: 1.5,
                mb: 0.5,
                color: "common.black"
              }}
            >
              Övrig feedback
            </InputLabel>
            <TextField
              value={otherFeedback || ""}
              required
              fullWidth
              multiline
              rows={3}
              // maxRows={10}
              id="otherFeedback"
              name="otherFeedback"
              inputProps={{ maxLength: 1000 }}
              error={inputErrors?.otherFeedback ? true : false}
              onChange={e => {
                handleInputChange(e);
              }}
              helperText={inputErrors?.otherFeedback}
              sx={{
                minHeight: "110px",
                mb: 3
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "425px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <CircularProgress
              color="primary"
              sx={{
                display: sending ? "flex" : "none"
              }}
            />
            <Alert
              severity={serverMessage?.type === "success" ? "success" : "error"}
              sx={{
                display: serverMessage?.text ? "flex" : "none",
                width: "100%"
              }}
            >
              {serverMessage?.text}
            </Alert>
          </Box>
        )}
        <Box
          sx={{
            width: "100%",
            mt: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {!submitted ? (
            <FairButton
              type="submit"
              fullWidth
              sx={{
                mt: 0,
                mb: 2,
                color: "primary.main"
              }}
            >
              Skicka feedback
            </FairButton>
          ) : (
            <FairButton
              fullWidth
              onClick={handleClose}
              sx={{
                mt: 0,
                mb: 2,
                color: "primary.main"
              }}
            >
              Stäng
            </FairButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

FeedbackForm.propTypes = {
  message: PropTypes.object,
  closeMessage: PropTypes.func
};

export default FeedbackForm;
