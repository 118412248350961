import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";

const Fairbutton = props => (
  <Button
    {...(!props?.override && props)} // override needs string "true" or ommit (false)
    variant="contained"
    sx={{
      // do not use shorthand on overrides, props need to match exactly!
      minWidth: 200,
      marginTop: 1,
      marginBottom: 1,
      color: "common.black",
      backgroundColor: "#FFD5A3",
      borderRadius: 0.5,
      "&:hover": {
        backgroundColor: "#FFC175"
      },
      ...props.sx
    }}
    {...(props.override && props)}
  >
    {props.children}
  </Button>
);

export default Fairbutton;

Fairbutton.propTypes = {
  override: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.node
};
