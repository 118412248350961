import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";

import { format } from "date-fns";
import {
  findInArrayByIndexValue,
  convertToCurrency,
  roundNumber
} from "../../../helpers/functions";

import useResponsive from "../../../hooks/useResponsive";
import useAxios from "../../../hooks/useAxios";
import useErrorHandler from "../../../hooks/useErrorHandler";

import { setPerfChartOptions } from "./perfChartOptions";

const PerformanceChart = props => {
  const location = useLocation();
  const isDesktop = useResponsive("up", "md");
  const isLargeDesktop = useResponsive("up", "xl");
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();

  const {
    chartData,
    benchmarkChartData,
    firstTransactionDate,
    movements,
    accountId,
    benchmarkId
  } = props;

  const [state, setState] = useState({ loaded: false });
  const accountData = useRef([]);
  let accountDataAll = useRef([]);
  const benchmarkData = useRef([]);
  let benchmarkDataAll = useRef([]);
  const selectedRange = useRef(4);
  const flagDate = useRef(null);

  const hasSpecialAccounts = chartData.hasSpecialAccounts;
  const showBenchmarkChart =
    benchmarkId && benchmarkChartData.main?.length ? true : false;
  const activeChart = useRef(showBenchmarkChart ? "benchmark" : "account");

  const mainChartHasData = chartData.main?.length > 0;
  const hasDeposits = movements && Object.keys(movements.deposits).length > 0;
  const hasWithdraws = movements && Object.keys(movements.withdraws).length > 0;

  const setSelectedRange = range => {
    // do we still need this?
    selectedRange.current = range;
  };

  const onChartLoad = useCallback(
    function () {
      const chart = this;
      if (showBenchmarkChart) {
        chart.series.forEach(serie => {
          const serieId = serie.userOptions.id;
          if (
            // Comment/Uncomment according to which chart we want to show as default
            serieId !== "account" &&
            serieId !== "marketValue" &&
            serie.userOptions.customType !== "movementFlag" &&
            // serieId !== "benchmark" &&
            // serieId !== "flag" &&
            // serieId !== "increase" &&
            serieId !== "highcharts-navigator-series"
          ) {
            // hide all series (redraw = false) execept benchmark, start date flag, increaseX flags and navigator series
            serie.setVisible(false, false);
          }
        });
        chart.series[0].setData(accountData.current, true); // fix yAxis extremes for series[0]
        chart.navigator.series[0].show(); // Not sure why we need to call show for the navigator series here (benchmark set as main chart)

        // Comment/Uncomment below code if we want to show the benchmark chart as the default chart
        // chart.navigator.series[0].setData([...benchmarkDataAll.current]);
      }
      // else {
      //   // fix navigator series data on first load
      //   chart.navigator.series[0].setData([...accountDataAll.current]);
      // }
      chart.navigator.series[0].setData([...accountDataAll.current]);
    },
    [showBenchmarkChart, accountData]
  );

  const onRender = useCallback(function () {
    var chart = this;
    chart.series.forEach(function (series) {
      // Overriding the legend symbol color (can't be done via options)
      if (series.legendLine) {
        if (series.name === "Din utveckling") {
          series.legendLine.attr({
            fill: "#4885B2",
            stroke: "#4885B2"
          });
        }
        if (series.name === "Modellportföljens utveckling") {
          series.legendLine.attr({
            fill: "#FFD5A3",
            stroke: "#FFD5A3"
          });
        }
      }
    });
  }, []);

  const afterSetExtremes = useCallback(
    function (e) {
      // console.log(`setExtremes triggered by ${e.trigger}`);
      if (e.trigger == undefined) return;
      const { chart } = e.target;
      chart.showLoading("Laddar…");

      const startDate = e.min
        ? format(new Date(e.min), "yyyy-MM-dd")
        : format(new Date("2000/01/01"), "yyyy-MM-dd");
      const endDate = e.max
        ? format(new Date(e.max), "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd");

      const requests = [];

      const { axiosInstance, axiosController } = initAxios("private");
      axiosGlobalController.current = axiosController;

      if (accountId) {
        const chartData = axiosInstance.get(
          `/performance/data/${startDate}/${endDate}/${accountId}`
        );
        requests.push(chartData);

        if (showBenchmarkChart) {
          const benchmarkChartData = axiosInstance.get(
            `/performance/data/${startDate}/${endDate}/${benchmarkId}/defaultbenchmark`
          );
          requests.push(benchmarkChartData);
        }
      } else {
        const chartData = axiosInstance.get(
          `/performance/data/${startDate}/${endDate}`
        );

        requests.push(chartData);
      }

      Promise.all(requests)
        .then(responses => {
          let mainChartData = responses[0].data.main;
          let marketValueData = responses[0].data.marketValue;
          const benchmarkChartData = responses[1]?.data.main;

          // Filter mainChartData to remove any data points with a value < firstTransactionDate CAR-100
          mainChartData = mainChartData.filter(
            dataPoint => dataPoint[0] >= firstTransactionDate
          );

          // Filter marketValueData to remove any data points with a value < firstTransactionDate CAR-100
          marketValueData = marketValueData.filter(
            dataPoint => dataPoint[0] >= firstTransactionDate
          );

          if (chart.series) {
            chart.series[0].setData(mainChartData);

            // also set the current options series (re-render fix)
            options.current.series[0].data = mainChartData;
            if (!hasSpecialAccounts)
              options.current.series[1].data = marketValueData;

            if (showBenchmarkChart) {
              chart.series[2].setData(benchmarkChartData);
              options.current.series[2].data = benchmarkChartData;

              // set increase X points flags
              const increaseXdataPoints = findIncreaseXPoints(
                benchmarkChartData,
                200
              );
              const increaseXDataSeries = [];
              increaseXdataPoints.forEach((point, index) => {
                const xIncrease = 2 * (index + 1);
                const title = `${xIncrease}x`;
                increaseXDataSeries.push({
                  x: point[0],
                  y: point[1],
                  title,
                  amount: roundNumber(point[1], 2)
                });
              });
              chart.series[4].setData(increaseXDataSeries);

              // set the flag coordinates in the benchmark chart for the start of the mainChart
              if (mainChartData?.length) {
                const flagCoords = findChartCoordsByDate(
                  benchmarkChartData,
                  flagDate.current
                );

                if (flagCoords?.length > 0) {
                  options.current.series[3].data[0].x = flagCoords[0];
                  options.current.series[3].data[0].y = flagCoords[1];
                  chart.series[3].setData(options.current.series[3].data);
                }
              }
            }

            // set coordinates for deposit flags
            let depositFlagsLength = 0;
            if (hasDeposits) {
              const seriesKey = showBenchmarkChart
                ? 5
                : !hasSpecialAccounts
                ? 2
                : 1;
              const depositsSeriesData = [];
              Object.entries(movements.deposits).forEach(deposit => {
                const date = deposit[0];
                const value = deposit[1];

                const flagCoords = findInArrayByIndexValue(
                  mainChartData,
                  0,
                  Number(date)
                );

                if (flagCoords.length > 0) {
                  depositsSeriesData.push({
                    x: flagCoords[0][0],
                    y: flagCoords[0][1],
                    amount: convertToCurrency(value),
                    title: "&nbsp;I&nbsp;"
                  });
                }
              });
              chart.series[seriesKey].setData(depositsSeriesData);
              depositFlagsLength =
                options.current.series[seriesKey].data.length;
            }

            // set coordinates for withdraws flags
            if (hasWithdraws) {
              const seriesKey = showBenchmarkChart
                ? 5 + depositFlagsLength.length
                : !hasSpecialAccounts
                ? 2 + depositFlagsLength.length
                : 1 + depositFlagsLength.length;
              const withdrawsSeriesData = [];

              Object.entries(movements.withdraws).forEach(withdraw => {
                const date = withdraw[0];
                const value = withdraw[1];
                const flagCoords = findInArrayByIndexValue(
                  mainChartData,
                  0,
                  Number(date)
                );

                if (flagCoords.length > 0) {
                  withdrawsSeriesData.push({
                    x: flagCoords[0][0],
                    y: flagCoords[0][1],
                    amount: convertToCurrency(value),
                    title: "&nbsp;U&nbsp;"
                  });
                }
              });
              chart.series[seriesKey].setData(withdrawsSeriesData);
            }
          }
          chart.hideLoading();
        })
        .catch(function (err) {
          errorHandler.serverError(err);
        });
    },
    [
      initAxios,
      accountId,
      showBenchmarkChart,
      benchmarkId,
      hasSpecialAccounts,
      hasDeposits,
      hasWithdraws,
      movements,
      firstTransactionDate,
      errorHandler
    ]
  );

  const onLegendItemClick = useCallback(
    function (e) {
      e.preventDefault();
      const thisSeries = this,
        thisSeriesId = thisSeries.userOptions.id,
        chart = this.chart;

      const isAccountButtonClick = thisSeriesId === "account";
      const isBenchmarkButtonClick = thisSeriesId === "benchmark";
      const isFlagsButtonClick =
        thisSeries.userOptions.customType === "movementFlag";

      // Flags button should toggle
      if (!isFlagsButtonClick && thisSeries.visible === true) return;
      if (!isFlagsButtonClick) {
        thisSeries.show();
      }

      // disable flags button if benchmark is visible
      if (showBenchmarkChart && isFlagsButtonClick) {
        const benchmarkSerie = chart.series.find(
          serie => serie.userOptions.id === "benchmark"
        );

        if (benchmarkSerie && benchmarkSerie.visible) return;
      }

      // Handle the visibility of the series
      chart.series.forEach(serie => {
        const serieId = serie.userOptions.id;
        const isChartStartFlag = serieId === "flag";
        const isIncreaseXFlag =
          serie.userOptions.customType === "increasePoint";
        const isMarketValue = serieId === "marketValue";
        const isMovementsFlag = serie.userOptions.customType === "movementFlag";

        if (isFlagsButtonClick && isMovementsFlag) {
          serie.visible ? serie.hide() : serie.show();
        }

        if (!isFlagsButtonClick) {
          // show marketValue + deposit/withdraws flags chart if we switch to "Utveckling" chart
          // show flag if we switch to "Modellportfölj" chart
          if (
            (isAccountButtonClick && (isMarketValue || isMovementsFlag)) ||
            (isBenchmarkButtonClick && (isChartStartFlag || isIncreaseXFlag))
          ) {
            return serie.show();
          }

          // hide all other series
          if (serie !== thisSeries) {
            serie.hide();
          }
        }
      });
      chart.navigator.series[0].show();

      if (thisSeriesId === "benchmark") {
        activeChart.current = "benchmark";
        chart.yAxis[0].update({
          height: "100%"
        });
        chart.yAxis[1].update({
          height: "0%"
        });
        chart.navigator.series[0].setData([...benchmarkDataAll.current], true); // !! needs to use reference to the original data
      }
      if (thisSeriesId === "account") {
        activeChart.current = "account";
        chart.yAxis[0].update({
          height: "75%"
        });
        chart.yAxis[1].update({
          height: "25%"
        });
        chart.navigator.series[0].setData([...accountDataAll.current], true); // !! needs to use reference to the original data
      }
      // reset the selected range to Allt
      chart.rangeSelector.clickButton(4, true);
    },
    [showBenchmarkChart]
  );

  const options = useRef(
    setPerfChartOptions({
      isLargeDesktop,
      isDesktop,
      mainChartHasData,
      showBenchmarkChart,
      hasSpecialAccounts,
      hasDeposits,
      hasWithdraws,
      setSelectedRange,
      onChartLoad,
      onRender,
      afterSetExtremes,
      onLegendItemClick
    })
  );

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      loaded: false
    }));
  }, [location.pathname]);

  useEffect(() => {
    if (!state.loaded) {
      // Filter chartData.main.current to remove any data points with a value < firstTransactionDate CAR-100
      accountData.current = chartData.main.filter(
        dataPoint => dataPoint[0] >= firstTransactionDate
      );
      accountDataAll.current = accountData.current;

      // set current chart series
      options.current.series[0].data = accountData.current;
      if (!hasSpecialAccounts)
        // Set and filter chartData.marketValue to remove any data points with a value < firstTransactionDate CAR-100
        options.current.series[1].data = chartData.marketValue.filter(
          dataPoint => dataPoint[0] >= firstTransactionDate
        );
      // set current navigator series
      if (!showBenchmarkChart)
        options.current.navigator.series.data = accountDataAll.current;

      if (showBenchmarkChart) {
        benchmarkData.current = benchmarkChartData.main;
        // set benchmark chart series
        benchmarkDataAll.current = benchmarkChartData.main;
        options.current.series[2].data = benchmarkData.current;
        // set increase X points flags
        const increaseXdataPoints = findIncreaseXPoints(
          benchmarkData.current,
          200
        );
        increaseXdataPoints.forEach((point, index) => {
          const xIncrease = 2 * (index + 1);
          const title = `${xIncrease}x`;
          options.current.series[4].data.push({
            x: point[0],
            y: point[1],
            title,
            amount: roundNumber(point[1], 2)
          });
        });

        if (accountData.current?.length) {
          // set the flag series data to the benchmarkData element where the date matches the firstTransactionDate
          const flagCoords = findInArrayByIndexValue(
            benchmarkData.current,
            0,
            firstTransactionDate
          );

          if (flagCoords.length > 0) {
            flagDate.current = flagCoords[0][0];
            options.current.series[3].data[0].x = flagCoords[0][0];
            options.current.series[3].data[0].y = flagCoords[0][1];
          }
        }
        options.current.navigator.series.data = benchmarkDataAll.current;
      }

      // set coordinates for deposit flags
      let depositFlagsLength = 0;
      if (movements && Object.keys(movements.deposits).length > 0) {
        const seriesKey = showBenchmarkChart ? 5 : !hasSpecialAccounts ? 2 : 1;
        Object.entries(movements.deposits).forEach(deposit => {
          const date = deposit[0];
          const value = deposit[1];

          const flagCoords = findInArrayByIndexValue(
            accountData.current,
            0,
            Number(date)
          );

          if (flagCoords.length > 0) {
            options.current.series[seriesKey].data.push({
              x: flagCoords[0][0],
              y: flagCoords[0][1],
              amount: convertToCurrency(value),
              title: "&nbsp;I&nbsp;"
            });
          }
        });

        depositFlagsLength = options.current.series[seriesKey].data.length;
      }

      // set coordinates for withdraws flags
      if (movements && Object.keys(movements.withdraws).length > 0) {
        const seriesKey = showBenchmarkChart
          ? 5 + depositFlagsLength
          : !hasSpecialAccounts
          ? 2 + depositFlagsLength
          : 1 + depositFlagsLength;

        Object.entries(movements.withdraws).forEach(withdraw => {
          const date = withdraw[0];
          const value = withdraw[1];
          const flagCoords = findInArrayByIndexValue(
            accountData.current,
            0,
            Number(date)
          );

          if (flagCoords.length > 0) {
            options.current.series[seriesKey].data.push({
              x: flagCoords[0][0],
              y: flagCoords[0][1],
              amount: convertToCurrency(value),
              title: "&nbsp;U&nbsp;"
            });
          }
        });
      }
      setState({ loaded: true });
    }

    return () => axiosGlobalController.current?.abort();
  }, [
    state.loaded,
    chartData,
    benchmarkChartData,
    firstTransactionDate,
    showBenchmarkChart,
    hasSpecialAccounts,
    movements
  ]);

  return !state.loaded ? (
    <Box
      sx={{
        height: "80%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <HighchartsReact
      highcharts={Highcharts}
      allowChartUpdate={false}
      constructorType={"stockChart"}
      options={options.current}
      containerProps={{
        style: {
          position: "relative",
          top: "-35px"
        }
      }}
    />
  );
};

export default PerformanceChart;

// Helper function to find the element in the data array that matches the target date
const findChartCoordsByDate = (data, targetDate) => {
  // Loop through the data array
  for (var i = 0; i < data.length; i++) {
    // Check if the x value (timestamp) matches the target date
    if (data[i][0] === targetDate) {
      // Return the matching element
      return data[i];
    }
  }
  // If no matching element is found, return null
  return null;
};

// Helper function to find the points where the y value has increased by a target amount
const findIncreaseXPoints = (dataPoints, targetIncrease) => {
  const initialValue = dataPoints[0][1]; // Get the first y value
  let currenttarget = targetIncrease;
  const results = [];

  for (let i = 1; i < dataPoints.length; i++) {
    const currentValue = dataPoints[i][1];
    const difference = currentValue - initialValue;

    // If the difference exceeds the current target, capture the point
    if (difference >= currenttarget) {
      results.push([dataPoints[i][0], currentValue]); // Push time and y value
      currenttarget += targetIncrease; // increase the target for the next threshold (multiples of targetIncrease)
    }
  }

  return results;
};

PerformanceChart.propTypes = {
  chartData: PropTypes.object,
  benchmarkChartData: PropTypes.object,
  firstTransactionDate: PropTypes.number,
  movements: PropTypes.object,
  accountId: PropTypes.string,
  benchmarkId: PropTypes.string
};
