import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import DashboardPaper from "../../theme/styled/DashboardPaper";

import TransactionsTable from "./TransactionsTable";

import useTransactions from "./useTransactions";

const Transactions = props => {
  const { state, handleTransactionClick, handleTransactionDownload } =
    useTransactions(props);

  return (
    <DashboardPaper>
      <Box>
        <Typography
          variant="h6"
          sx={{
            mb: 2
          }}
        >
          Transaktioner
        </Typography>
        {!state.loaded ? (
          <Box
            sx={{
              height: "100%",
              minHeight: "80vh",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TransactionsTable
              transactions={state.transactions}
              handleTransactionClick={handleTransactionClick}
              handleTransactionDownload={handleTransactionDownload}
            />
          </LocalizationProvider>
        )}
      </Box>
    </DashboardPaper>
  );
};

Transactions.propTypes = {
  state: PropTypes.array,
  handleTransactionClick: PropTypes.func,
  handleTransactionDownload: PropTypes.func
};

export default Transactions;
