import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AttachmentIcon from "@mui/icons-material/AttachFileOutlined";
import ReadTickedIcon from "@mui/icons-material/CheckCircleOutlined";

import { getCookie, formatSafariFix } from "../../../helpers/functions";
import CustomTableFooter from "../../CustomTableFooter";

const TableMobile = props => {
  const navigate = useNavigate();
  const { dashboard, isSent, audiences, messages, openMessage } = props;

  let messageList;

  if (dashboard) {
    messageList = messages;
  } else {
    messageList = isSent ? messages.sent : messages.received;
  }

  // check if we have any options saved..
  const dashboardOptions = JSON.parse(getCookie("fairOptions")) || null;
  // .. and set rowsPerPage setting accordingly
  const [rowsPerPage, setRowsPerPage] = useState(
    dashboardOptions?.rowsPerPage["messages"] || 10
  );

  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return messageList.length > 0 ? (
    <TableContainer component={Box}>
      <Table
        sx={{ maxWidth: 600, tableLayout: "fixed" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow sx={{ "& th": { borderBottom: 0 } }}>
            <TableCell sx={{ width: "30%", pl: 1.25, paddingLeft: 0 }}>
              <Typography variant="fairTableCellHeader">Datum</Typography>
            </TableCell>
            <TableCell sx={{ width: "58%" }}>
              <Typography variant="fairTableCellHeader">Ämne.</Typography>
            </TableCell>
            <TableCell sx={{ width: "14%", pr: 1.25 }}>
              <Typography variant="fairTableCellHeader" sx={{ float: "right" }}>
                Läst
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? messageList.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : messageList
          ).map(message => (
            <TableRow
              key={message.MessageId}
              sx={{
                fontWeight: isSent || message.IsRead === "1" ? 400 : 800,
                border: 0
              }}
              onClick={
                openMessage
                  ? openMessage(message)
                  : () =>
                      navigate("/dashboard/meddelanden", {
                        state: { openMessage: message }
                      })
              }
            >
              <TableCell
                align="left"
                sx={{ fontWeight: "inherit", textAlign: "left" }}
              >
                <Typography
                  variant="fairTableCell"
                  sx={{ fontWeight: "inherit" }}
                >
                  {formatSafariFix(message.Date, "yyyy-MM-dd")}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "inherit",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden"
                }}
              >
                {message.AttachedDocIds &&
                  JSON.parse(message?.AttachedDocIds).length > 0 && (
                    <Typography
                      variant="fairTableCell"
                      sx={{ fontWeight: "inherit" }}
                    >
                      <AttachmentIcon
                        sx={{
                          fill: "#305C9A",
                          fontSize: "1rem",
                          verticalAlign: "middle",
                          marginRight: "3px"
                        }}
                      />
                    </Typography>
                  )}
                <Typography
                  variant="fairTableCell"
                  sx={{ fontWeight: "inherit" }}
                >
                  {message.Subject}
                  <br />
                  {isSent ? (
                    <>
                      {message?.Audience
                        ? audiences[message.Audience]
                        : message?.RecipientName || "Fair Investments"}
                    </>
                  ) : (
                    <>
                      {message?.SenderRole === "Backoffice" ||
                      message?.SenderRole === "Admin"
                        ? "Fair Investments"
                        : message?.SenderName}
                    </>
                  )}
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: "inherit", pr: 1.25 }}>
                {!isSent && message.IsRead === "1" && (
                  <ReadTickedIcon
                    sx={{
                      fill: "#305C9A",
                      fontSize: "1rem",
                      verticalAlign: "middle"
                    }}
                  />
                )}
                {isSent && message.Audience && (
                  <Typography
                    variant="fairTableCell"
                    sx={{ fontWeight: "inherit" }}
                  >
                    {`${message.ReadCount}/${message.RecipientCount}`}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!dashboard && (
        <CustomTableFooter
          type="messages"
          data={messageList}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleChangePage={handleChangePage}
        />
      )}
    </TableContainer>
  ) : (
    <Typography variant="subtitle2" sx={{ mt: 1.25, mb: 2.5 }}>
      {isSent ? "Du har inte skickat några meddelanden" : "Din inkorg är tom"}
    </Typography>
  );
};

TableMobile.propTypes = {
  dashboard: PropTypes.bool,
  isSent: PropTypes.bool,
  audiences: PropTypes.object,
  messages: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  openMessage: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

export default TableMobile;
