import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const SectionInnerRightWrapBox = props => (
  <Box
    sx={{
      // do not use shorthand on overrides, props need to match exactly!
      width: { xs: "100%", lg: "35%", xl: "30%" },
      // maxWidth: 400,
      ...props.sx
    }}
    {...props}
  >
    {props.children}
  </Box>
);

export default SectionInnerRightWrapBox;

SectionInnerRightWrapBox.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object
};
