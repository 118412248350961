import { useState, useEffect, useRef } from "react";

import useAxios from "../../../../hooks/useAxios";
import useErrorHandler from "../../../../hooks/useErrorHandler";

const initialState = {
  submitted: false,
  fetching: false,
  message: ""
};

const useDeletePoaModal = props => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();
  const { agent, toggleModal, refreshTable } = props;
  const [state, setState] = useState(initialState);

  const toggle = () => {
    setState(initialState);
    return toggleModal();
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setState(prevState => ({
      ...prevState,
      submitted: true,
      fetching: true
    }));

    const axios = initAxios("private");
    axiosGlobalController.current = axios.axiosController;

    try {
      const response = await axios.axiosInstance.post("/poa/delete", {
        poaId: agent.PoaId
      });

      setState(prevState => ({
        ...prevState,
        submitted: true,
        fetching: false
      }));

      if (response.status !== 204)
        setState(prevState => ({
          ...prevState,
          message: "Something went wrong. Please contact support."
        }));

      await refreshTable();
      toggle();
    } catch (err) {
      errorHandler.serverError(err);
      const msg = err?.response?.data?.msg;
      if (msg) {
        return setState(prevState => ({
          ...prevState,
          fetching: false,
          message: `Error deleting agent: ${err?.response?.data?.msg}`
        }));
      }
    }
  };

  useEffect(() => {
    return () => axiosGlobalController.current?.abort();
  }, [axiosGlobalController]);

  return {
    agent,
    state,
    toggle,
    handleSubmit
  };
};

export default useDeletePoaModal;
