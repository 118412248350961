import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FairButton from "../../../theme/styled/FairButton";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

import DashboardPaper from "../../../theme/styled/DashboardPaper";
import RecipientSelect from "./RecipientSelect";
import AudienceSelect from "./AudienceSelect";
import AudienceRadioGroup from "./AudienceRadioGroup";
import ConfirmBulkMessageModal from "./ConfirmBulkMessageModal";
import LockSpinnerModal from "../../Modals/LockSpinnerModal/lockSpinnerModal";
import UploadDocuments from "./UploadDocuments/uploadDocuments";
import UploadCustomList from "./UploadCustomList/uploadCustomList";

import useNewMessage from "./useNewMessage";

const NewMessage = props => {
  const {
    isBackofficeUser,
    state,
    isReply,
    setToRecipients,
    audiences,
    toggleIsBulk,
    setAudience,
    addFiles,
    removeFile,
    clearFiles,
    addCustomList,
    removeCustomList,
    clearCustomList,
    handleInputChange,
    handleSubmit,
    closeMessage,
    toggleConfirmBulkMessageModal,
    handleConfirmBulkMessageChange
  } = useNewMessage(props);

  const {
    isBulk,
    audience,
    recipients,
    subject,
    body,
    files,
    customList,
    submitted,
    sending,
    inputErrors,
    serverMessage
  } = state;

  return (
    <>
      {isReply && (
        <Box sx={{ mt: 3 }}>
          <FairButton onClick={closeMessage}>Tillbaka</FairButton>
        </Box>
      )}
      <DashboardPaper>
        <Box>
          <Typography
            variant="h6"
            sx={{
              mb: 2
            }}
          >
            Skriv nytt meddelande
          </Typography>
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            height: "auto",
            justifyContent: "center",
            alignItems: "flex-start",
            display: "flex",
            flexFlow: "column"
          }}
        >
          {!submitted && (
            <>
              {!isReply && recipients && (
                <>
                  <AudienceRadioGroup
                    isBulk={isBulk}
                    toggleIsBulk={toggleIsBulk}
                    setAudience={setAudience}
                    toggleConfirmBulkMessageModal={
                      toggleConfirmBulkMessageModal
                    }
                    error={inputErrors?.audience}
                  />
                  {isBulk ? (
                    <AudienceSelect
                      audience={audience}
                      audiences={audiences}
                      setAudience={setAudience}
                      error={inputErrors?.toRecipients}
                    />
                  ) : (
                    <RecipientSelect
                      disabled={audience !== ""}
                      audience={audience}
                      audiences={audiences}
                      recipients={recipients}
                      setToRecipients={setToRecipients}
                      error={inputErrors?.toRecipients}
                    />
                  )}
                  {isBackofficeUser && audience === "customList" && (
                    <React.Fragment>
                      <UploadCustomList
                        files={customList}
                        addCustomList={addCustomList}
                        removeCustomList={removeCustomList}
                        clearCustomList={clearCustomList}
                        error={inputErrors?.customList}
                      />
                    </React.Fragment>
                  )}
                </>
              )}

              <TextField
                disabled={isReply}
                size="small"
                value={subject || ""}
                placeholder="Ämne"
                required
                fullWidth
                id="subject"
                name="subject"
                autoComplete="subject"
                error={inputErrors?.subject ? true : false}
                onChange={e => {
                  handleInputChange(e);
                }}
                helperText={inputErrors?.subject}
                sx={{
                  height: 40,
                  mb: 3
                }}
              />
              <TextField
                inputRef={
                  (isReply && (input => input && input.focus())) || null
                }
                value={body || ""}
                placeholder={
                  isReply ? "Skriv ditt svar här" : "Skriv ditt meddelande här"
                }
                required
                fullWidth
                multiline
                rows={10}
                // maxRows={10}
                id="body"
                name="body"
                autoComplete="body"
                error={inputErrors?.body ? true : false}
                onChange={e => {
                  handleInputChange(e);
                }}
                helperText={inputErrors?.body}
                sx={{
                  mb: 3
                }}
              />
              {isBackofficeUser && (
                <UploadDocuments
                  files={files}
                  addFiles={addFiles}
                  removeFile={removeFile}
                  clearFiles={clearFiles}
                />
              )}
            </>
          )}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <CircularProgress
              color="primary"
              sx={{
                display: !isBulk && sending ? "flex" : "none"
              }}
            />
            <Typography
              color="primary"
              sx={{
                display: isBulk && sending ? "flex" : "none"
              }}
            >
              Skickar...
            </Typography>
            <Alert
              severity={serverMessage?.type === "success" ? "success" : "error"}
              sx={{
                display: serverMessage?.text ? "flex" : "none",
                width: "100%"
              }}
            >
              {serverMessage?.text}
            </Alert>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {!submitted && (
              <FairButton
                type="submit"
                fullWidth
                sx={{
                  mt: 0,
                  mb: 2,
                  color: "primary.main"
                }}
              >
                Skicka meddelande
              </FairButton>
            )}
            {!sending && submitted && (
              <FairButton
                onClick={closeMessage}
                sx={{
                  mt: 2
                }}
              >
                Tillbaka
              </FairButton>
            )}
          </Box>
        </Box>
      </DashboardPaper>
      <ConfirmBulkMessageModal
        open={state.confirmBulkMessageModal}
        audience={state.audience}
        audiences={audiences}
        toggleModal={toggleConfirmBulkMessageModal}
        handleConfirmBulkMessageChange={handleConfirmBulkMessageChange}
      />
      <LockSpinnerModal open={isBulk && sending} />
    </>
  );
};

NewMessage.propTypes = {
  message: PropTypes.object,
  closeMessage: PropTypes.func
};

export default NewMessage;
