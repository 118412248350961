import { useState, useEffect, useCallback, useRef } from "react";

import { getCookie } from "../../../helpers/functions";
import useAxios from "../../../hooks/useAxios";

// import { DashboardConsumer } from "../../../contexts/DashboardProvider";
import useErrorHandler from "../../../hooks/useErrorHandler";

const usePoaTable = () => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();

  const [loaded, setLoaded] = useState(false);
  const [customersList, setCustomersList] = useState([]);
  const [poasList, setPoasList] = useState([]);
  const [copyList, setCopyList] = useState(poasList);
  const [agentData, setAgentData] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [page, setPage] = useState(0);

  // check if we have any options saved..
  const dashboardOptions = JSON.parse(getCookie("fairOptions")) || null;
  // .. and set rowsPerPage setting accordingly
  const [rowsPerPage, setRowsPerPage] = useState(
    dashboardOptions?.rowsPerPage["poaTable"] || 10
  );

  const handlePoaClick = poaId => {
    // find array index by object prop value
    const poaIndex = poasList.findIndex(object => {
      return object["PoaId"] === poaId;
    });

    let updatedData = [...poasList]; // copy prev state data array
    updatedData[poaIndex]["toggled"] = !poasList[poaIndex].toggled;
    setPoasList(updatedData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const refreshTable = useCallback(async () => {
    const axios = initAxios("private");
    axiosGlobalController.current = axios.axiosController;

    try {
      const poasList = await axios.axiosInstance.get("/poa/all");

      setCopyList(poasList.data);
      setPoasList(poasList.data);
      setLoaded(true);
    } catch (err) {
      errorHandler.serverError(err);
    }
  }, [initAxios, errorHandler]);

  const toggleAddModal = () => {
    setAddModal(prevState => !prevState);
  };

  const toggleDeleteModal = (e = false, agentData = false) => {
    if (e) e.preventDefault();
    if (agentData) setAgentData(agentData);
    setDeleteModal(prevState => !prevState);
  };

  const toggleEditModal = (e = false, agentData = false) => {
    if (e) e.preventDefault();
    if (agentData) setAgentData(agentData);
    setEditModal(prevState => !prevState);
  };

  const getData = useCallback(async () => {
    const axios = initAxios("private");
    axiosGlobalController.current = axios.axiosController;

    const requests = [];

    // gets all customers including Advisor, AccountManager, Assistant, and Poa roles
    const requestAllCustomersExended = axios.axiosInstance.get(
      "user/customers/extended"
    );
    requests.push(requestAllCustomersExended);

    // gets poa/customer relationships
    const requestAllPoas = axios.axiosInstance.get("/poa/all");
    requests.push(requestAllPoas);

    Promise.all(requests)
      .then(responses => {
        const customers = responses[0].data;
        const poas = responses[1].data;

        setCustomersList(customers);
        setCopyList(poas);
        setPoasList(poas);
        return setLoaded(true);
      })
      .catch(function (err) {
        errorHandler.serverError(err);
      });
  }, [initAxios, errorHandler]);

  useEffect(() => {
    if (!loaded) {
      getData();
    }
    return () => axiosGlobalController.current?.abort();
  }, [loaded, refreshTable, getData, axiosGlobalController]);

  return {
    loaded,
    addModal,
    toggleAddModal,
    editModal,
    toggleEditModal,
    deleteModal,
    toggleDeleteModal,
    poasList,
    customersList,
    copyList,
    setCopyList,
    agentData,
    refreshTable,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    handlePoaClick
  };
};

export default usePoaTable;
