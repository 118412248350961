import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { getCookie } from "../../../helpers/functions";
import { getComparator } from "../../../helpers/MuiTableFunctions";

import CustomTableFooter from "../../CustomTableFooter";
import CustomToolbar from "./CustomToolbar";
import EnhancedTableHead from "./EnhancedTableHead";

const AuditLogTable = props => {
  const { isMobile, logs, handleLogClick } = props;

  // check if we have any options saved..
  const dashboardOptions = JSON.parse(getCookie("fairOptions")) || null;
  // .. and set rowsPerPage setting accordingly
  const [rowsPerPage, setRowsPerPage] = useState(
    dashboardOptions?.rowsPerPage["logs"] || 50
  );

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("Created");

  const handleRequestSort = (event, property) => {
    // if (page > 0) setPage(0);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [data, setData] = useState(logs);

  return (
    <TableContainer component={Box}>
      {logs?.length > 0 && (
        <>
          <CustomToolbar
            isMobile={isMobile}
            logs={logs}
            setData={setData}
            page={page}
            setPage={setPage}
          />
          <Box
            sx={{
              maxWidth: "100vw",
              border: 0,
              overflow: "auto"
            }}
          >
            <Table sx={{ minWidth: 780, border: 0 }} aria-label="simple table">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {(rowsPerPage > 0
                  ? data
                      .sort(getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                  : data.sort(getComparator(order, orderBy))
                ).map((log, key) => (
                  <Fragment key={key}>
                    <TableRow
                      sx={{
                        cursor: "pointer",
                        "&:last-child td": { border: 0 }
                      }}
                      onClick={() => handleLogClick(log.AuditLogEntryId)}
                    >
                      <TableCell>
                        <Typography>{log?.toggled ? "-" : "+"}</Typography>
                      </TableCell>
                      <TableCell sx={{ px: 0, py: 0.5 }}>
                        <Typography variant="fairTableCell">
                          {log.Created}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ px: 0, py: 0.5 }}>
                        <Typography variant="fairTableCell">
                          {log.Name}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ px: 0, py: 0.5 }}>
                        <Typography variant="fairTableCell">
                          {log.ActingAsName !== log.Name
                            ? log.ActingAsName
                            : "-"}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ px: 0, py: 0.5 }}>
                        <Typography variant="fairTableCell">
                          {log.Action}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {log?.toggled && (
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          sx={{
                            pl: "5%",
                            ...(isMobile && {
                              py: 0
                            })
                          }}
                        >
                          <Box
                            className="info-content"
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="fairTableCell"
                              sx={{
                                my: 1
                              }}
                            >
                              <b>IP Address: </b>
                              {log.IpNumber}
                            </Typography>
                            <Typography
                              variant="fairTableCell"
                              sx={{
                                my: 1
                              }}
                            >
                              <b>UserAgent: </b>
                              {log.UserAgent}
                            </Typography>
                            <Typography
                              variant="fairTableCell"
                              sx={{
                                my: 1
                              }}
                            >
                              <b>Data: </b>
                              {log.Data ? log.Data : "-"}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </Box>
        </>
      )}
      <CustomTableFooter
        type="logs"
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        handleChangePage={handleChangePage}
      />
    </TableContainer>
  );
};

AuditLogTable.propTypes = {
  isMobile: PropTypes.bool,
  logs: PropTypes.array.isRequired,
  handleLogClick: PropTypes.func.isRequired
};

export default AuditLogTable;
