import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";

import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const AgentSelect = props => {
  const { customers, agent, setCustomer, error } = props;

  const [selectedOption, setSelectedOption] = useState(false);

  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () =>
      customers.filter(
        option =>
          option.Role !== "Poa" &&
          ((option.Name && containsText(option.Name, searchText)) ||
            (option.FirstName &&
              option.LastName &&
              containsText(
                option.FirstName + " " + option.LastName,
                searchText
              )) ||
            (option.IdentityNo && containsText(option.IdentityNo, searchText)))
      ),
    [customers, searchText]
  );

  const handleSelectChange = e => {
    e.preventDefault();
    setSelectedOption({
      identityNo: e.target.value.IdentityNo,
      name: e.target.value.Name
    });
    setCustomer(e.target.value);
  };

  useEffect(() => {
    if (agent) {
      setSelectedOption({
        identityNo: agent.PrincipalIdentityNo,
        name: agent.PrincipalName
      });
    }
  }, [agent]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: { xs: "100%", sm: 350 },
        mb: 1
      }}
    >
      <Box sx={{ mb: 1 }}>
        <InputLabel
          htmlFor="principal-legal-entity-id"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            fontSize: 14,
            fontWeight: 500,
            mt: 1,
            color: "common.black"
          }}
        >
          Fullmaktsgivare
        </InputLabel>
      </Box>
      <FormControl fullWidth>
        <Select
          size="small"
          // Disables auto focus on MenuItems and allows TextField to be in focus
          MenuProps={{
            autoFocus: false,
            style: {
              maxHeight: 300,
              width: 350
            }
          }}
          labelId="principal-legal-entity-id-label"
          id="principal-legal-entity-id"
          value={selectedOption || ""}
          // defaultValue={selectedOption.Name}
          // label="Options"
          onChange={handleSelectChange}
          onClose={() => setSearchText("")}
          // This prevents rendering empty string in Select's value
          // if search text would exclude currently selected option.
          renderValue={() =>
            selectedOption &&
            (selectedOption.identityNo + " " + selectedOption.name || "")
          }
          error={error ? true : false}
          sx={{
            width: { xs: "100%", sm: 350 },
            textAlign: "left"
          }}
        >
          {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
          <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={e => setSearchText(e.target.value)}
              onKeyDown={e => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
              sx={{ my: 1 }}
            />
          </ListSubheader>
          {displayedOptions.map((option, i) => (
            <MenuItem key={i} value={option}>
              {option.IdentityNo} {option.Name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>{error}</FormHelperText>
      </FormControl>
    </Box>
  );
};

AgentSelect.propTypes = {
  isMobile: PropTypes.bool,
  customers: PropTypes.array,
  agent: PropTypes.object,
  error: PropTypes.string,
  setCustomer: PropTypes.func
};

export default AgentSelect;
