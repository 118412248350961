import { useState, useEffect, useCallback, useRef } from "react";

import useAxios from "../../hooks/useAxios";
import useErrorHandler from "../../hooks/useErrorHandler";

const useInsights = () => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();
  const [state, setState] = useState({
    loaded: false,
    insightsTableLoaded: false,
    activityInsights: [],
    ranges: [],
    customersWithChanges: []
  });

  const switchWeekPrevious = async () => {
    const newWeek = state.selectedWeek === 1 ? 52 : state.selectedWeek - 1;
    const newYear =
      state.selectedWeek === 1 ? state.selectedYear - 1 : state.selectedYear;

    try {
      const { axiosInstance, axiosController } = initAxios("private");
      axiosGlobalController.current = axiosController;
      const requestInsightTable = await axiosInstance.get(
        `/insights/activity/table/${newYear}/${newWeek}`
      );

      setState(prevState => ({
        ...prevState,
        selectedWeek: newWeek,
        selectedYear: newYear,
        customersWithChanges: requestInsightTable.data
      }));
    } catch (err) {
      errorHandler.serverError(err);
    }
  };

  const switchWeekNext = async () => {
    const newWeek = state.selectedWeek === 52 ? 1 : state.selectedWeek + 1;
    const newYear =
      state.selectedWeek === 52 ? state.selectedYear + 1 : state.selectedYear;

    try {
      const { axiosInstance, axiosController } = initAxios("private");
      axiosGlobalController.current = axiosController;
      const requestInsightTable = await axiosInstance.get(
        `/insights/activity/table/${newYear}/${newWeek}`
      );

      setState(prevState => ({
        ...prevState,
        selectedWeek: newWeek,
        selectedYear: newYear,
        customersWithChanges: requestInsightTable.data
      }));
    } catch (err) {
      errorHandler.serverError(err);
    }
  };

  const fetchInsightsData = useCallback(async () => {
    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;

    try {
      // Account transactions data request
      const requestInsights = await axiosInstance.get(`/insights/activity`);
      const requestInsightRanges = await axiosInstance.get(
        `/insights/activity/ranges`
      );

      let customersWithActivityChanges = [];
      let selYear = null;
      let selWeek = null;
      if (requestInsightRanges.data.max !== null) {
        const requestInsightTable = await axiosInstance.get(
          `/insights/activity/table/${requestInsightRanges.data.max.year}/${requestInsightRanges.data.max.week}`
        );
        customersWithActivityChanges = requestInsightTable.data;
        selYear = requestInsightRanges.data.max.year;
        selWeek = requestInsightRanges.data.max.week;
      }

      setState(prevState => ({
        ...prevState,
        loaded: true,
        insightsTableLoaded: true,
        activityInsights: requestInsights.data,
        ranges: requestInsightRanges.data,
        selectedYear: selYear,
        selectedWeek: selWeek,
        customersWithChanges: customersWithActivityChanges
      }));
    } catch (err) {
      errorHandler.serverError(err);
    }
  }, [errorHandler, initAxios]);

  useEffect(() => {
    if (!state.loaded) {
      fetchInsightsData();
    }

    return () => axiosGlobalController.current?.abort();
  }, [fetchInsightsData, state.loaded]);

  return {
    state,
    switchWeekPrevious,
    switchWeekNext
  };
};

export default useInsights;
