import React from "react";
import PropTypes from "prop-types";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import FairModalBox from "../../../theme/styled/FairModalBox";
import FairCloseModalBox from "../../../theme/styled/FairCloseModalBox";
import FairButton from "../../../theme/styled/FairButton";

import useDeleteUserModal from "./useDeleteUserModal";

const DeleteUserModal = props => {
  const { open } = props;
  const deleteUserModal = useDeleteUserModal(props);
  const { user, state, toggle, handleSubmit } = deleteUserModal;

  const { submitted, fetching, message } = state;

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          toggle();
        }
      }}
      sx={{
        overflow: "scroll"
      }}
    >
      <FairModalBox>
        <Box
          sx={{
            height: 38,
            px: 4,
            pb: 5
          }}
        >
          <Typography variant="h6" color="primary.main">
            Ta bort användare
          </Typography>
        </Box>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            height: 320,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexFlow: "column",
            px: 4
          }}
        >
          {!submitted && (
            <>
              <Typography variant="h6" color="common.black">
                Är du säker på att du vill ta bort användaren {user?.Name}?
              </Typography>
              <FairButton
                type="submit"
                fullWidth
                sx={{
                  my: 2,
                  color: "primary.main"
                }}
              >
                Bekräfta radering
              </FairButton>
            </>
          )}
          {submitted && fetching && <CircularProgress color="primary" />}
          {submitted && !fetching && (
            <Typography variant="h6" color="common.black">
              {message}
            </Typography>
          )}
        </Box>

        <FairCloseModalBox>
          <Button
            onClick={toggle}
            sx={{
              color: "primary.lighter"
            }}
          >
            Stäng
          </Button>
        </FairCloseModalBox>
      </FairModalBox>
    </Modal>
  );
};

DeleteUserModal.propTypes = {
  open: PropTypes.bool,
  toggleModal: PropTypes.func
};

export default DeleteUserModal;
