// import logo from "./logo.svg";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import Divider from "@mui/material/Divider";
import DashboardPaper from "../../theme/styled/DashboardPaper";

import SectionHeaderPaper from "../../theme/styled/SectionHeaderPaper";
import SectionWrapperBox from "../../theme/styled/SectionWrapperBox";
import SectionInnerLeftWrapBox from "../../theme/styled/SectionInnerLeftWrapBox";
import SectionInnerRightWrapBox from "../../theme/styled/SectionInnerRightWrapBox";

import BasicInfo from "./BasicInfo";

import { AuthConsumer } from "../../contexts/AuthProvider";
import useResponsive from "../../hooks/useResponsive";

import { ContactUs } from "..";

const Profile = () => {
  const isMobile = useResponsive("only", "xs");
  const { user, elevatedUser } = AuthConsumer();

  return (
    <Box>
      <SectionHeaderPaper>
        <Typography variant="h3" align="left">
          Kundprofil: {user.FirstName} {user.LastName}
        </Typography>
        {!isMobile && (
          <Typography variant="subtitle1">
            Här kan du se och redigera dina grunduppgifter
          </Typography>
        )}
      </SectionHeaderPaper>
      <SectionWrapperBox>
        <SectionInnerLeftWrapBox>
          <DashboardPaper>
            <BasicInfo user={user} elevatedUser={elevatedUser} />
          </DashboardPaper>
        </SectionInnerLeftWrapBox>
        <SectionInnerRightWrapBox>
          <ContactUs />
        </SectionInnerRightWrapBox>
      </SectionWrapperBox>
    </Box>
  );
};
export default Profile;
