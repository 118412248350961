import React from "react";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";

const EnhancedTableHead = props => {
  const { isAccount, order, orderBy, onRequestSort } = props;

  const headCells = [
    {
      id: "Toggled",
      disablePadding: true,
      label: "",
      width: "2.5%"
    },
    {
      id: "TransactionDate",
      disablePadding: true,
      label: "Datum",
      width: "10%"
    },
    ...(!isAccount
      ? [
          {
            id: "AccountNo",
            disablePadding: true,
            label: "Konto",
            width: "10%"
          }
        ]
      : []),
    {
      id: "TTypeName",
      disablePadding: true,
      label: "Typ",
      width: "20%"
    },
    {
      id: "HoldingName",
      disablePadding: true,
      label: "Instrument",
      width: !isAccount ? "22.5%" : "30%"
    },
    // {
    //   id: "CurrencyCode",
    //   disablePadding: true,
    //   label: "Valuta"
    // },
    {
      id: "Count",
      disablePadding: true,
      label: "Antal",
      width: "10%"
    },
    {
      id: "Price",
      disablePadding: true,
      label: "Kurs",
      width: "10%"
    },
    {
      id: "TotalAmount",
      disablePadding: true,
      label: "Belopp",
      width: "10%"
    },
    {
      id: "nota",
      disablePadding: true,
      label: "Nota",
      width: !isAccount ? "7.5%" : "10%"
    }
  ];

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.width,
              px: 0,
              py: 1,
              textAlign: index >= headCells.length - 1 ? "right" : "left"
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon
              sx={{
                // flexDirection: "row-reverse",
                "& .MuiSvgIcon-root": {
                  ml: 0.2,
                  mr: 0,
                  fontSize: "12px"
                }
              }}
            >
              <Typography variant="fairTableCellHeader">
                {headCell.label}
              </Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  isAccount: PropTypes.bool.isRequired
};

export default EnhancedTableHead;
