export default function ListItemIcon(theme) {
  return {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
          minWidth: 30
        },
        sizeSmall: {
          height: 40
        }
      },
      variants: [
        {
          props: { variant: "active" },
          style: {
            color: "#FFF"
          }
        }
      ]
    }
  };
}
