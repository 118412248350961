import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";

const SectionHeaderPaper = props => (
  <Paper
    square
    sx={{
      // do not use shorthand on overrides, props need to match exactly!
      height: { xs: 75, md: 130 },
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      paddingTop: { xs: 0, md: 5 },
      paddingRight: { xs: 2, md: 5 },
      paddingBottom: { xs: 0, md: 5 },
      paddingLeft: { xs: 2, md: 5 },
      ...props.sx
    }}
    {...props}
  >
    {props.children}
  </Paper>
);

export default SectionHeaderPaper;

SectionHeaderPaper.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object
};
