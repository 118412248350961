import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const FairModalBox = forwardRef((props, ref) => {
  return (
    <Box
      {...(!props?.override && props)} // override needs string "true" or ommit (false)
      ref={ref}
      sx={{
        // do not use shorthand on overrides, props need to match exactly!
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: { xs: "90vw", sm: 450 },
        height: 444,
        bgcolor: "background.paper",
        borderRadius: 0.5,
        border: 0,
        boxShadow: 0,
        paddingTop: 4,
        paddingBottom: 2,
        textAlign: "center",
        outline: "none",
        ...props.sx
      }}
      // {...(props.override && props)}
    >
      {props.children}
    </Box>
  );
});
FairModalBox.displayName = "FairModalBox";

export default FairModalBox;

FairModalBox.propTypes = {
  override: PropTypes.string,
  ref: PropTypes.object,
  innerRef: PropTypes.object,
  sx: PropTypes.object,
  children: PropTypes.node
};
