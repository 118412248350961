const useConfirmBulkMessageModal = props => {
  const {
    open,
    audience,
    audiences,
    toggleModal,
    handleConfirmBulkMessageChange
  } = props;

  const close = () => {
    return toggleModal(audience);
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleConfirmBulkMessageChange(audience);
  };

  return {
    open,
    close,
    audience,
    audiences,
    handleSubmit
  };
};

export default useConfirmBulkMessageModal;
