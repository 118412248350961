import React from "react";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";

const EnhancedTableHead = props => {
  const { order, orderBy, onRequestSort } = props;

  const headCells = [
    {
      id: "RecipientName",
      disablePadding: true,
      label: "Namn",
      width: "33%"
    },
    {
      id: "RecipientEmail",
      disablePadding: true,
      label: "E-post",
      width: "33%"
    },
    {
      id: "UpdatedDate",
      disablePadding: true,
      label: "Läst datum",
      width: "33%"
    }
  ];

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.width,
              px: index >= headCells.length - 1 ? 0.3 : 0,
              py: 1,
              textAlign: index >= headCells.length - 1 ? "right" : "left"
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon
              sx={{
                // flexDirection: "row-reverse",
                "& .MuiSvgIcon-root": {
                  ml: 0.2,
                  mr: 0,
                  fontSize: "12px"
                }
              }}
            >
              <Typography variant="fairTableCellHeader">
                {headCell.label}
              </Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

export default EnhancedTableHead;
