import React from "react";
import PropTypes from "prop-types";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import FairModalBox from "../../../theme/styled/FairModalBox";
import FairCloseModalBox from "../../../theme/styled/FairCloseModalBox";
import FairButton from "../../../theme/styled/FairButton";

import useConfirmHistoricHoldingsModal from "./useConfirmHistoricHoldingsModal";

const ConfirmHistoricHoldingsModal = props => {
  const { open, currentStatus } = props;
  const confirmHistoricHoldingsModal = useConfirmHistoricHoldingsModal(props);
  const { toggle, handleSubmit } = confirmHistoricHoldingsModal;

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          toggle();
        }
      }}
      sx={{
        overflow: "scroll"
      }}
    >
      <FairModalBox>
        <Box
          sx={{
            height: 38,
            px: 4,
            pb: 5
          }}
        >
          <Typography variant="h6" color="primary.main">
            Bekräfta ändring
          </Typography>
        </Box>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            height: 320,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexFlow: "column",
            px: 4
          }}
        >
          <Typography variant="h6" color="common.black">
            Är du säker på att du vill{" "}
            <b>
              {currentStatus
                ? "stänga av säkerhetskopieläge"
                : "aktivera växling till säkerhetskopia"}
            </b>
            ?
          </Typography>
          <FairButton
            type="submit"
            fullWidth
            sx={{
              my: 2,
              color: "primary.main"
            }}
          >
            Bekräfta ändringen
          </FairButton>
        </Box>

        <FairCloseModalBox>
          <Button
            onClick={toggle}
            sx={{
              color: "primary.lighter"
            }}
          >
            Stäng
          </Button>
        </FairCloseModalBox>
      </FairModalBox>
    </Modal>
  );
};

ConfirmHistoricHoldingsModal.propTypes = {
  open: PropTypes.bool,
  currentStatus: PropTypes.bool,
  toggleModal: PropTypes.func
};

export default ConfirmHistoricHoldingsModal;
