//TODO: remove/optimise initialState
export const initialState = {
  loaded: false,
  satisfaction: "",
  wouldRecommend: "",
  otherFeedback: "",
  submitted: false,
  sending: false,
  inputErrors: null,
  serverMessage: null
};

const feedbackFormReducer = (state, action) => {
  switch (action.type) {
    case "validReset":
      return {
        ...state,
        loaded: true,
        validReset: true
      };
    case "invalidReset":
      return {
        ...state,
        validReset: false,
        loaded: true,
        serverMessage: { type: "error", text: action.payload }
      };
    case "handleClose":
      return initialState;
    case "handleInput":
      return {
        ...state,
        [action.field]: action.payload,
        inputErrors: { ...state.inputErrors, [action.field]: null },
        serverMessage: null
      };
    case "setToRecipients":
      return {
        ...state,
        toRecipients: action.payload,
        inputErrors: { ...state.inputErrors, toRecipients: null }
      };
    case "sending":
      return { ...state, sending: action.payload, submitted: true };
    case "handleInputErrors":
      return { ...state, inputErrors: action.payload };
    case "handleServerError":
      return {
        ...state,
        sending: false,
        serverMessage: { type: "error", text: action.payload }
      };
    case "handleServerSuccess":
      return {
        ...state,
        sending: false,
        serverMessage: { type: "success", text: action.payload }
      };
    case "resetState":
      return initialState;
    default:
      return state;
  }
};

export default feedbackFormReducer;
