import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";

const InsightsChart = props => {
  const location = useLocation();

  const { chartData } = props;
  const [state, setState] = useState({ loaded: false });

  const options = useRef({
    credits: {
      enabled: false
    },
    chart: {
      height: 375
    },

    title: {
      text: ""
    },

    rangeSelector: {
      enabled: false
    },

    xAxis: {
      type: "linear",
      categories: chartData.WEEKS
    },

    yAxis: [
      {
        title: {
          text: "Antal kunder"
        }
      },
      {
        title: {
          text: ""
        },
        labels: {
          enabled: false
        }
      }
    ],

    series: [
      {
        // lineWidth: 3,
        id: "low",
        name: "Låg",
        color: "#999999",
        legendIndex: 1,
        showInLegend: true,
        data: []
      },
      {
        id: "medium",
        name: "Medium",
        color: "#f6b26b",
        legendIndex: 2,
        showInLegend: true,
        data: []
      },
      {
        id: "high",
        name: "Hög",
        color: "#67d4cb",
        legendIndex: 3,
        showInLegend: true,
        data: []
      },
      {
        id: "vhigh",
        name: "Väldigt hög",
        color: "#1f9200",
        legendIndex: 4,
        showInLegend: true,
        data: []
      },
      {
        id: "xhigh",
        name: "Superanvändare",
        color: "#ce0000",
        legendIndex: 5,
        showInLegend: true,
        data: []
      }
    ],

    navigator: {
      enabled: false
    },

    legend: {
      enabled: true,
      borderRadius: 0,
      borderWidth: 0,
      floating: false,
      align: "right",
      verticalAlign: "top",
      layout: "horizontal",
      itemStyle: {
        color: "#262626"
      },
      itemHoverStyle: {
        color: "#808080"
      },
      itemHiddenStyle: {
        color: "#cccccc"
      }
    },

    plotOptions: {
      line: {
        marker: {
          enabled: false
        }
      },
      series: {
        pointPlacement: "on"
      }
    }
  });

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      loaded: false
    }));
  }, [location.pathname]);

  useEffect(() => {
    if (!state.loaded) {
      options.current.series[0].data = chartData.LOW;
      options.current.series[1].data = chartData.MEDIUM;
      options.current.series[2].data = chartData.HIGH;
      options.current.series[3].data = chartData.VHIGH;
      options.current.series[4].data = chartData.XHIGH;

      setState({ loaded: true });
    }
  }, [state.loaded, chartData]);

  return !state.loaded ? (
    <CircularProgress />
  ) : (
    <HighchartsReact
      highcharts={Highcharts}
      allowChartUpdate={false}
      constructorType={"chart"}
      options={options.current}
      containerProps={{
        style: {
          position: "relative",
          top: "-35px"
        }
      }}
    />
  );
};

export default InsightsChart;

InsightsChart.propTypes = {
  chartData: PropTypes.object,
  benchmarkChartData: PropTypes.object,
  accountId: PropTypes.string,
  benchmarkId: PropTypes.string
};
