import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";

import DashboardPaper from "../../theme/styled/DashboardPaper";

import AccountsTable from "./AccountsTable";
import useAccounts from "./useAccounts";

const Accounts = () => {
  const accs = useAccounts();

  const { isMobile, loaded, accounts, handleAccountClick } = accs;

  return (
    <DashboardPaper>
      <Box>
        <Typography variant="h6" align="left">
          Depåer
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          {!loaded ? (
            <CircularProgress />
          ) : (
            <AccountsTable
              isMobile={isMobile}
              accounts={accounts}
              handleAccountClick={handleAccountClick}
            />
          )}
        </Box>
      </Box>
    </DashboardPaper>
  );
};

export default Accounts;
