import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  FormControl,
  FormHelperText,
  Autocomplete,
  TextField
} from "@mui/material";

const RecipientSelect = props => {
  const { disabled, audience, audiences, recipients, setToRecipients, error } =
    props;

  const handleSelectChange = (e, value) => {
    e.preventDefault();
    setToRecipients(value);
  };

  return (
    <Box sx={{ width: "100%", my: 1 }}>
      <FormControl fullWidth>
        <Autocomplete
          key={disabled} // This is a hack to force a re-render when the disabled prop changes (Clear input values): https://stackoverflow.com/questions/59790956/material-ui-autocomplete-clear-value
          disabled={disabled}
          id="multi-recipient-select"
          name="toRecipients"
          multiple
          options={recipients}
          getOptionLabel={option => option.Username + " " + option.Name}
          onChange={(e, v) => {
            handleSelectChange(e, v);
          }}
          sx={{
            mb: 1
          }}
          renderInput={params => (
            <TextField
              {...params}
              size="small"
              name="multiple"
              label={audience ? audiences[audience] : "Till..."}
              variant="outlined"
              fullWidth
              sx={{ cursor: audience ? "not-allowed" : "auto" }}
              inputProps={{
                ...params.inputProps,
                sx: {
                  ...params.inputProps.sx,
                  cursor: audience ? "not-allowed" : "auto"
                }
              }}
            />
          )}
        />
        <FormHelperText sx={{ color: "error.main" }}>{error}</FormHelperText>
      </FormControl>
    </Box>
  );
};

RecipientSelect.propTypes = {
  disabled: PropTypes.bool,
  audience: PropTypes.string,
  audiences: PropTypes.object,
  recipients: PropTypes.array,
  recipientId: PropTypes.string,
  error: PropTypes.string,
  setToRecipients: PropTypes.func
};

export default RecipientSelect;
