import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import InputAdornment from "@mui/material/InputAdornment";

import searchIcon from "../../assets/search_icon.svg";
import DashboardPaper from "../../theme/styled/DashboardPaper";

import useBackofficeTable from "./useBackofficeTable";
import AddUserModal from "./AddUserModal";
import EditUserModal from "./EditUserModal";
import DeleteUserModal from "./DeleteUserModal";

import CustomTableFooter from "../CustomTableFooter";

const BackofficeTable = () => {
  const {
    addModal,
    toggleAddModal,
    editModal,
    toggleEditModal,
    deleteModal,
    toggleDeleteModal,
    userData,
    searchInput,
    copyList,
    refreshTable,
    page,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    requestSearch
  } = useBackofficeTable();

  return (
    <DashboardPaper>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50%" }}>
          <Typography variant="h6" align="left">
            Backoffice-/manuella användare
          </Typography>
        </Box>
        <Box
          sx={{
            width: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
          }}
        >
          <Link sx={{ cursor: "pointer" }} onClick={toggleAddModal}>
            <Typography variant="fairTableCell" color="primary.light">
              Skapa ny
            </Typography>
          </Link>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <TextField
          size="small"
          fullWidth
          placeholder="Sök på namn eller personnummer"
          type="search"
          value={searchInput}
          onInput={e => requestSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  component="img"
                  sx={{
                    height: 20,
                    width: 20,
                    paddingBottom: 0.25
                  }}
                  alt="logo"
                  src={searchIcon}
                />
              </InputAdornment>
            ),
            sx: {
              borderRadius: "20px"
            }
          }}
        />
        <Box
          sx={{
            maxWidth: "100vw",
            border: 0,
            overflow: "auto"
          }}
        >
          <Table aria-label="custom pagination table" sx={{ mt: 1, border: 0 }}>
            <TableBody>
              {(rowsPerPage > 0
                ? copyList.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : copyList
              )
                // (copyList.length > 0 ? copyList : rows)
                .map((user, key) => (
                  <TableRow key={key}>
                    <TableCell
                      align="left"
                      sx={{
                        width: "25%",
                        px: 1.2,
                        py: 1,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }}
                    >
                      <Typography
                        variant="fairTableCell"
                        color="secondary.lighter"
                      >
                        {user.IdentityNo || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "35%",
                        px: 1.2,
                        py: 1,
                        color: "secondary.lighter",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }}
                    >
                      <Typography variant="fairTableCell">
                        {user.LastName}, {user.FirstName}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "15%",
                        px: 1.2,
                        py: 1,
                        textAlign: "center",
                        color: "secondary.lighter",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }}
                    >
                      <Typography variant="fairTableCell">
                        {user.Role}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        width: "25%",
                        px: 1.2,
                        py: 1,
                        color: "secondary.lighter"
                      }}
                    >
                      <Link
                        sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
                        onClick={e => toggleEditModal(e, user)}
                      >
                        <Typography
                          variant="fairTableCell"
                          color="primary.light"
                        >
                          Ändra
                        </Typography>
                      </Link>
                      {" / "}
                      <Link
                        sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
                        onClick={e => toggleDeleteModal(e, user)}
                      >
                        <Typography
                          variant="fairTableCell"
                          color="primary.light"
                        >
                          Ta bort
                        </Typography>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              {copyList.length < 1 && (
                <TableRow>
                  <TableCell sx={{ border: 0 }}>Inga resultat.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
        <CustomTableFooter
          type="backofficeusers"
          data={copyList}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </Box>
      <AddUserModal
        open={addModal}
        toggleModal={toggleAddModal}
        refreshTable={refreshTable}
      />
      <EditUserModal
        open={editModal}
        toggleModal={toggleEditModal}
        user={userData}
        refreshTable={refreshTable}
      />
      <DeleteUserModal
        open={deleteModal}
        toggleModal={toggleDeleteModal}
        user={userData}
        refreshTable={refreshTable}
      />
    </DashboardPaper>
  );
};

export default BackofficeTable;
