import { useState, useEffect, useRef } from "react";

import { getCookie } from "../../helpers/functions";
import useAxios from "../../hooks/useAxios";

import { DashboardConsumer } from "../../contexts/DashboardProvider";
import useErrorHandler from "../../hooks/useErrorHandler";

const useBackofficeTable = () => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();
  const dashboardCtxt = DashboardConsumer();

  const backofficeUsers = dashboardCtxt.state.backofficeUsers;
  const [searchInput, setSearchInput] = useState("");
  const [copyList, setCopyList] = useState(backofficeUsers);
  const [userData, setUserData] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [page, setPage] = useState(0);

  // check if we have any options saved..
  const dashboardOptions = JSON.parse(getCookie("fairOptions")) || null;
  // .. and set rowsPerPage setting accordingly
  const [rowsPerPage, setRowsPerPage] = useState(
    dashboardOptions?.rowsPerPage["backofficeusers"] || 10
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const requestSearch = searched => {
    if (page > 0) setPage(0);
    setSearchInput(searched);

    const searchString = searched.toLowerCase();
    setCopyList(
      backofficeUsers.filter(
        user =>
          (user.FirstName + " " + user.LastName)
            .toLowerCase()
            .includes(searchString) ||
          user.IdentityNo?.toLowerCase().includes(searchString) ||
          user.Name?.toLowerCase().includes(searchString)
      )
    );
  };

  const refreshTable = async () => {
    const axios = initAxios("private");
    axiosGlobalController.current = axios.axiosController;

    try {
      const backofficeUsers = await axios.axiosInstance.get(
        "/user/backoffice/list"
      );

      setSearchInput("");
      dashboardCtxt.setState(prevState => ({
        ...prevState,
        backofficeUsers: backofficeUsers.data
      }));
      setCopyList(backofficeUsers.data);
    } catch (err) {
      errorHandler.serverError(err);
    }
  };

  const toggleAddModal = () => {
    setAddModal(prevState => !prevState);
  };

  const toggleDeleteModal = (e = false, userData = false) => {
    if (e) e.preventDefault();
    if (userData) setUserData(userData);
    setDeleteModal(prevState => !prevState);
  };

  const toggleEditModal = (e = false, userData = false) => {
    if (e) e.preventDefault();
    if (userData) setUserData(userData);
    setEditModal(prevState => !prevState);
  };

  useEffect(() => {
    return () => axiosGlobalController.current?.abort();
  }, [axiosGlobalController]);

  return {
    addModal,
    toggleAddModal,
    editModal,
    toggleEditModal,
    deleteModal,
    toggleDeleteModal,
    userData,
    searchInput,
    copyList,
    refreshTable,
    page,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    requestSearch
  };
};

export default useBackofficeTable;
