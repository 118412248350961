import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

import useHomeProvider from "../hooks/useHomeProvider";

const HomeCtxt = createContext(null);

const HomeProvider = ({ children }) => {
  const homeProv = useHomeProvider();

  return <HomeCtxt.Provider value={homeProv}>{children}</HomeCtxt.Provider>;
};

export const HomeConsumer = () => {
  return useContext(HomeCtxt);
};

export default HomeProvider;

HomeProvider.propTypes = {
  children: PropTypes.node
};
