import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import logo from "../../assets/logo.svg";

import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";
import NotificationsPopover from "./NotificationsPopover";

import useHeader from "./useHeader";

const Header = () => {
  const navBar = useHeader();
  const { isLaptopOrDesktop } = navBar;

  return (
    <AppBar
      position="fixed"
      color="secondary"
      sx={{
        justifyContent: "center",
        height: { xs: "75px", md: "100px" },
        borderBottom: 4,
        borderBottomColor: "primary.main",
        zIndex: 3
      }}
    >
      <Container
        maxWidth="xxl"
        sx={{
          px: { xs: 1.5, md: 1.5 },
          py: { xs: 0, md: 1.5 }
        }}
      >
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 50,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-start",
              alignItems: "center"
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer"
              }}
              onClick={e => navBar.handleLogoClick(e)}
            >
              <Box
                component="img"
                sx={{
                  display: { xs: "none", md: "flex" },
                  height: 75,
                  width: 75
                }}
                alt="logo"
                src={logo}
              />
              <Typography
                variant="h5"
                color="primary.light"
                href="/"
                sx={{
                  mr: 2,
                  ml: 1,
                  display: { xs: "none", md: "flex" },
                  textDecoration: "none",
                  lineHeight: 1,
                  textAlign: { md: "left" }
                }}
              >
                FAIR
                <br />
                INVESTMENTS
              </Typography>
            </Box>
          </Box>
          {/* mobile view */}
          <Box
            component="img"
            sx={{
              display: { xs: "flex", md: "none" },
              height: 45,
              width: 45
            }}
            alt="Fair Investments logo"
            src={logo}
            onClick={e => navBar.handleLogoClick(e)}
          />
          <Typography
            variant="caption"
            color="primary.main"
            href=""
            sx={{
              display: { xs: "flex", md: "none" },
              flex: 1,
              ml: 1,
              lineHeight: 1
            }}
            onClick={e => navBar.handleLogoClick(e)}
          >
            FAIR
            <br />
            INVESTMENTS
          </Typography>
          <Box sx={{ display: "inline-flex", flexGrow: 0 }}>
            {isLaptopOrDesktop ? (
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  top: 12,
                  right: 5,
                  width: "auto"
                }}
              >
                <NotificationsPopover />
                <DesktopMenu handlers={navBar} />
              </Box>
            ) : (
              <>
                <NotificationsPopover />
                <MobileMenu handlers={navBar} />
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
