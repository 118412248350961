const { format, isBefore, subYears } = require("date-fns");

export const delay = t => new Promise(resolve => setTimeout(resolve, t));

export const isEmptyObject = obj => {
  return Object.keys(obj).length === 0;
};

export const stripHtmlTags = string => {
  return string.replace(/<\/?[^>]+(>|$)/g, "");
};

export const stripHtmlPTags = string => {
  return string.replace(/<p.*?>|<\/p>/gi, "");
};

export const getObjectKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

export const deepCopyArray = array => {
  return array.map(object => ({ ...object }));
};

export const findInArrayByIndexValue = (array, valueIndex, value) => {
  return array.filter(({ [valueIndex]: n }) => n === value);
};

export const findArrayKeyByPropertyValue = (
  array,
  propertyName,
  propertyValue
) => {
  return array.findIndex(element => element[propertyName] === propertyValue);
};

// Removes elements from an array of objects in JavaScript ES6 that contain specific duplicate object property values
export const filterArrayDupsByObjectPropertyValue = (array, property) => {
  return array.filter(item => {
    if (array.indexOf(item[property]) === -1) {
      array.push(item[property]);
      return true;
    }
    return false;
  });
};

// Remove duplicates from array by specific element index (will keep the last duplicate element)
export const filterArrayDupsByUniqueElementValue = (array, index) => {
  // index can be a number or string (if associative array)
  return [...new Map(array.map(item => [item[index], item])).values()];
};

export const sortArrayByPropertyValuesOrder = (
  array,
  propKey,
  propValuesOrderArray
) => {
  return [...array].sort((a, b) => {
    const order = propValuesOrderArray;

    const indexA = order.indexOf(a[propKey]);
    const indexB = order.indexOf(b[propKey]);

    // If both prop values are in the order array, compare their positions
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    // If only one of them is in the order array, move it to the beginning
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    // For other items, maintain their order
    return 0;
  });
};

export const setCookie = (name, value, minutes) => {
  const domain = "; domain=fairinvestments.se"; //TODO: add domain to .env
  var expires = "";
  if (minutes) {
    var date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + domain + expires + "; path=/";
  // // For mobile debugging
  // document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = name => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const eraseCookie = name => {
  const domain = "; domain=fairinvestments.se"; //TODO: add domain to .env
  document.cookie =
    name + "=" + domain + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";

  // // For mobile debugging
  // document.cookie =
  //   name + "=" + "; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const roundNumber = (number, decimals) => {
  return Number(Math.round(number + "e" + decimals) + "e-" + decimals);
};

export const convertAmount = number => {
  let ammount = parseInt(number).toLocaleString();
  return ammount.replace(",", " ");
};

export const convertCount = number => {
  // number can be float with 2, 1 or 0 decimal plates based on the instrument type so we need to handle either case
  let count = parseFloat(number).toLocaleString("sv-SE", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: true
  });

  // If there is only 1 decimal point, add a trailling 0
  if (count.split(",")[1] && count.split(",")[1].length === 1) {
    count = count + "0";
  }

  return count;
};

export const convertShareAmount = number => {
  let ammount = parseFloat(number).toLocaleString(undefined, {
    maximumFractionDigits: 5
  });
  return ammount.replace(".", ",");
};

export const convertTransactionAmount = (number, type) => {
  const decimals = type === "FundTransaction" ? 4 : 2;
  let ammount = number.toFixed(decimals);
  ammount = ammount.replace(/\d(?=(\d{3})+\.)/g, "$& ");
  return ammount.replace(".", ",");
};

export const convertToCurrency = number => {
  let num = number;
  if (typeof num === "number") {
    num = num.toFixed(2); // convert to string with 2 decimal places
  }
  const currency = num.replace(/\d(?=(\d{3})+\.)/g, "$& ");
  return currency.replace(".", ",");
};

// export const convertToCurrency = number => {
//   if (typeof number !== "string") return number;
//   const currency = number.replace(/\d(?=(\d{3})+\.)/g, "$& ");
//   return parseFloat(currency.replace(".", ","));
// };

// TODO: Check if this can be done from backend query
export const filterLastThreads = messages => {
  const lastThreads = [
    ...new Map(messages.map(item => [item["MessageThreadId"], item])).values()
  ];

  // sort Desc
  const sortedDesc = lastThreads.sort(
    (objA, objB) => Number(new Date(objB.Date)) - Number(new Date(objA.Date))
  );

  return sortedDesc;
};

export const createDateObjectFromString = dateString => {
  let [year, month, day, hour, minute, second] = dateString.split(/[- :]/);
  return new Date(year, month - 1, day, hour, minute, second);
};

export const convertStringToLink = str => {
  const urlRegex = /((https?|ftp):\/\/[^\s/$.?#].[^\s]*)/gi;
  return str.replace(urlRegex, function (url) {
    return (
      "<a href='" +
      url +
      "' rel='noopener noreferrer' target='_blank' style='color: #2e457e;'>" +
      url +
      "</a>"
    );
  });
};

export const isDateBeforeYearsAgo = (date, years) => {
  return isBefore(new Date(date), subYears(new Date(), years));
};

// Temp function to check is valid swedish phone number
// https://github.com/validatorjs/validator.js/pull/2181
export const isValidSwedishPhoneNumber = phoneNumber => {
  // Remove any non-digit characters except for spaces
  phoneNumber = phoneNumber.replace(/[^\d\s]/g, "");

  // Check if the phone number starts with the country code (+46) or (0)
  if (phoneNumber.startsWith("46")) {
    // Remove the country code (+46) if present
    phoneNumber = phoneNumber.slice(2);
  } else if (phoneNumber.startsWith("0")) {
    // Remove the leading 0 if present
    phoneNumber = phoneNumber.slice(1);
  } else {
    // Invalid format, doesn't start with either country code or 0
    return false;
  }

  // Remove any leading or trailing whitespace
  phoneNumber = phoneNumber.trim();

  // Remove any remaining spaces within the digits
  phoneNumber = phoneNumber.replace(/\s/g, "");

  // Check if the remaining number is 9 digits long
  if (phoneNumber.length !== 9) {
    return false;
  }

  // Check if the remaining number consists only of digits
  if (!/^\d+$/.test(phoneNumber)) {
    return false;
  }

  // Valid Swedish phone number
  return true;
};

// Safari 15.6 date fix https://github.com/date-fns/date-fns/issues/2130
// Use when date is in format "1970-01-01 00:00:00"
export const formatSafariFix = (date, formatString) => {
  if (!formatString) throw new Error("Date formatString is required.");

  const fixedDate = date ? date.replace(" ", "T") : null;
  return format(new Date(fixedDate), formatString);
};

export const lazyRetry = function (componentImport, name) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || "false"
    );

    // try to import the component
    componentImport()
      .then(component => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, "false"); // success so reset the refresh
        resolve(component);
      })
      .catch(error => {
        if (!hasRefreshed) {
          console.log("lazyRetry:: refreshing the page to recover from error");
          window.sessionStorage.setItem(`retry-${name}-refreshed`, "true");
          return window.location.reload(); // refresh the page
        }
        reject(error);
      });
  });
};

export const checkIsMobileOrTablet = () => {
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  )
    return true;
  return false;
};
