import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

import useTimeOutProvider from "../hooks/useTimeOutProvider";

const TimeOutCtxt = createContext(null);

const TimeOutProvider = ({ children }) => {
  const timeOutProv = useTimeOutProvider();

  return (
    <TimeOutCtxt.Provider value={timeOutProv}>{children}</TimeOutCtxt.Provider>
  );
};

export const TimeOutConsumer = () => {
  return useContext(TimeOutCtxt);
};

export default TimeOutProvider;

TimeOutProvider.propTypes = {
  children: PropTypes.node
};
