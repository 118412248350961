export default function IconButton(theme) {
  return {
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true
      },
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
          "&:hover": {
            boxShadow: "none"
          }
        },
        sizeSmall: {
          height: 50
        },
        sizeLarge: {
          height: 70
        }
      }
    }
  };
}
