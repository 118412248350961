import { useState, useEffect, useRef } from "react";
import { format, differenceInDays } from "date-fns";

import { getCookie } from "../../helpers/functions";
import useAxios from "../../hooks/useAxios";

import { AuthConsumer } from "../../contexts/AuthProvider";
import { DashboardConsumer } from "../../contexts/DashboardProvider";
import useErrorHandler from "../../hooks/useErrorHandler";
import useResponsive from "../../hooks/useResponsive";

const useCustomersTable = () => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();
  const isMobile = useResponsive("only", "xs");
  const authCtxt = AuthConsumer();
  const dashboardCtxt = DashboardConsumer();
  const { elevatedUser } = authCtxt;
  const customers = dashboardCtxt.state.customers;
  const filters = useRef({
    search: "",
    watchlist: false,
    activitySegment: ""
  });

  // sort alphabetically
  customers.sort((a, b) =>
    a.Name.localeCompare(b.Name, "sv", { sensitivity: "base" })
  );

  //map activity segments colours
  const activitySegments = {
    NULL: { color: "#e6e6e6", label: "Ingen aktivitet" },
    LOW: { color: "#999999", label: "Låg aktivitet" },
    MEDIUM: { color: "#f6b26b", label: "Medium aktivitet" },
    HIGH: { color: "#67d4cb", label: "Hög aktivitet" },
    VHIGH: { color: "#1f9200", label: "Väldigt hög aktivitet" },
    XHIGH: { color: "#ce0000", label: "Superanvändare" }
  };

  // TODO: Move below to useEffect
  //
  // Parsing LastLoginDate/DaysSinceLastLogin and owned Accounts logic
  customers.forEach(account => {
    if (account.AccountNumbers && typeof account.AccountNumbers === "string") {
      account.AccountNumbers = account.AccountNumbers.split(",");
    }

    // Safari 15.6 date fix https://github.com/date-fns/date-fns/issues/2130
    let lastLoginDate = account.LastLoginDate
      ? account.LastLoginDate.replace(" ", "T")
      : null;

    if (lastLoginDate) {
      lastLoginDate = format(new Date(lastLoginDate), "yyyy-MM-dd");

      const daysSinceLastLogin = differenceInDays(
        new Date(),
        new Date(lastLoginDate)
      );

      switch (daysSinceLastLogin) {
        case 0:
          account.DaysSinceLastLogin = "idag";
          break;
        case 1:
          account.DaysSinceLastLogin = "igår";
          break;
        default:
          account.DaysSinceLastLogin = `${daysSinceLastLogin} dagar sedan`;
          break;
      }
    }
  });

  // check if we have any options saved..
  const dashboardOptions = JSON.parse(getCookie("fairOptions")) || null;
  // .. and set rowsPerPage setting accordingly
  const [rowsPerPage, setRowsPerPage] = useState(
    dashboardOptions?.rowsPerPage["customers"] || 10
  );

  const [isFiltered, setIsFiltered] = useState(false);
  const customersList = customers;
  const [copyList, setCopyList] = useState(customers);
  const [searchString, setSearchString] = useState("");
  const [page, setPage] = useState(0);
  const [filterWatchlist, setFilterWatchlist] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const toggleWatchlist = e => {
    performWatchlistToggle(e.target.checked, false);
  };

  const performWatchlistToggle = (toggled, clear) => {
    if (page > 0) setPage(0);
    setFilterWatchlist(toggled);

    if (clear) {
      customers.forEach(account => {
        account.Watchlist = false;
      });
      setSearchString("");
    }

    filters.current = clear
      ? {
          search: "",
          watchlist: false,
          activitySegment: ""
        }
      : {
          ...filters.current,
          watchlist: toggled
        };

    applyFilters();
  };

  const applyFilters = () => {
    let filteredData = customersList;
    let filtered = false;

    if (filters.current.watchlist) {
      filtered = true;
      filteredData = filteredData.filter(customer => customer.Watchlist);
    }

    if (filters.current.activitySegment) {
      filtered = true;
      filteredData = filteredData.filter(
        customer =>
          filters.current.activitySegment === customer.activitySegment ||
          (filters.current.activitySegment === "NULL" &&
            !customer.activitySegment)
      );
    }

    if (filters.current.search) {
      filtered = true;
      filteredData = filteredData.filter(
        customer =>
          (customer.FirstName + " " + customer.LastName)
            .toLowerCase()
            .includes(filters.current.search) ||
          customer.IdentityNo?.toLowerCase().includes(filters.current.search) ||
          customer.Name?.toLowerCase().includes(filters.current.search) ||
          customer.AccountNumbers?.find(accountNo =>
            accountNo.includes(filters.current.search)
          )
      );
    }

    if (page > 0) setPage(0);
    setIsFiltered(filtered);
    setCopyList(filteredData);
  };

  const requestSearch = searched => {
    setSearchString(searched); // set searchString as typed for display
    const searchString = searched.toLowerCase();
    filters.current = {
      ...filters.current,
      search: searchString
    };
    applyFilters();
  };

  const handleActivitySegmentSelect = (event, index) => {
    const segment = index.props.name;
    filters.current = {
      ...filters.current,
      activitySegment: segment !== "Alla" ? segment : ""
    };

    applyFilters();
  };

  const handleNotificationClick = legalEntityId => async () => {
    let addToWatchlist = true;
    customers.forEach(account => {
      if (legalEntityId === account.LegalEntityId) {
        addToWatchlist = !account.Watchlist;
        account.Watchlist = !account.Watchlist;
      }
    });
    console.log(
      addToWatchlist ? "Add to watchlist" : "Remove from watchlist",
      legalEntityId
    );

    dashboardCtxt.setState({ ...dashboardCtxt.state, customers });

    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;

    try {
      await axiosInstance.post(
        `user/watchlist/${addToWatchlist ? "add" : "remove"}`,
        {
          client: legalEntityId
        }
      );
    } catch (err) {
      errorHandler.serverError(err);
    }
  };

  const handleAccountClick = legalEntityId => async e => {
    e.preventDefault();
    const { axiosInstance, axiosController } = initAxios("auth");
    axiosGlobalController.current = axiosController;

    try {
      // making the switch request with axios userSwitch to use fairtokenA
      const response = await axiosInstance.post("auth/switch", {
        legalEntityId
      });
      const { user } = response.data;
      authCtxt.login(user, elevatedUser);
      authCtxt.setIsLoaded(false);
    } catch (err) {
      errorHandler.serverError(err);
    }
  };

  useEffect(() => {
    return () => axiosGlobalController.current?.abort();
  }, [axiosGlobalController]);

  return {
    isMobile,
    isFiltered,
    activitySegments,
    copyList,
    searchString,
    filters,
    page,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    requestSearch,
    handleActivitySegmentSelect,
    toggleWatchlist,
    handleAccountClick,
    handleNotificationClick,
    filterWatchlist,
    performWatchlistToggle
  };
};

export default useCustomersTable;
