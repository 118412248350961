import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";

import useResponsive from "../../../hooks/useResponsive";

const TransactionsChart = props => {
  const isDesktop = useResponsive("up", "md");
  const isLargeDesktop = useResponsive("up", "xl");
  const [state, setState] = useState({ loaded: false });
  const { chartData } = props;

  const options = useRef({
    credits: {
      enabled: false
    },
    chart: {
      height: 350,
      // width: 550
      position: {
        y: -15
      }
      // animation: false
    },

    // title: {
    //   text: "My chart"
    // },
    // subtitle: {
    //   text: "Click small/large buttons or change window size to test responsiveness"
    // },

    rangeSelector: {
      dropdown: isLargeDesktop ? "responsive" : "always",
      labelStyle: {
        display: "none"
      },
      buttonPosition: {
        align: "right",
        x: 0,
        y: 0
      },
      buttonTheme: {
        // styles for the buttons
        fill: "none",
        stroke: "none",
        "stroke-width": 1,
        width: 40,
        r: 1, // button border radius
        style: {
          color: "#4885B2",
          borderRadius: 0
        },
        states: {
          hover: {
            fill: "none",
            style: {
              fontWeight: "bold"
            }
          },
          select: {
            fill: "none",
            style: {
              color: "#2E457E",
              fontWeight: "bold"
            }
          }
          // disabled: { ... }
        }
      },
      buttons: [
        {
          type: "month",
          count: 1,
          text: "1 mån",
          title: "1 mån"
        },
        {
          type: "month",
          count: 3,
          text: "3 mån",
          title: "3 mån"
        },
        {
          type: "month",
          count: 6,
          text: "6 mån",
          title: "6 mån"
        },
        {
          type: "year",
          count: 1,
          text: "1 år",
          title: "1 år"
        },
        {
          type: "all",
          text: "Allt",
          title: "Allt"
        },
        {
          type: "ytd",
          text: "YTD",
          title: "YTD"
        }
      ],
      inputEnabled: false, // it supports only days
      selected: 4 // index of which button will be selected at start i.e. YTD
    },

    xAxis: {
      type: "datetime",
      // tickInterval: 24 * 3600 * 1000, // one day
      ordinal: false,
      minRange: 3600 * 1000 * 24 * 7 // (one week) minRange affects rangeSelector buttons, per example month will be disabled if minRange > 1 month, see https://api.highcharts.com/highstock/xAxis.minRange
      // labels: {
      //   format: "{value:%d-%m-%Y}"
      // },
    },

    yAxis: {
      opposite: false // Highstock sets the Y axis placement to opposite:true by default !!
      // title: {
      //   text: "Avkastning  (%)"
      // }
    },

    series: [
      {
        // lineWidth: 3,
        id: "account",
        name: "Avkastning",
        color: "#4885B2",
        legendIndex: 1,
        data: chartData,
        dataGrouping: {
          enabled: false
        },
        tooltip: {
          valueDecimals: 2
        },
        step: "left" // step up/down instead of gradual diagonal lines between values
      }
    ],

    tooltip: {
      pointFormat: `<span style="color:{series.color}">{series.name}</span>: {point.y} SEK`,
      xDateFormat: "%Y-%m-%d"
    },

    navigator: {
      adaptToUpdatedData: false, // stops looping and disabling range selector buttons
      handles: { enabled: isDesktop },
      height: isDesktop ? 40 : 0,
      margin: isDesktop ? 25 : 0,
      maskInside: isDesktop,
      outlineWidth: isDesktop ? 1 : 0,
      series: {
        data: chartData,
        color: "#4885B2",
        fillOpacity: 0.3,
        type: "line",
        step: "left"
      },
      xAxis: {
        visible: isDesktop
      },
      yAxis: {
        visible: isDesktop,
        plotBands: [
          {
            color: "lightgrey",
            from: -100000000,
            to: 100000000
          }
        ]
      }
    },

    scrollbar: {
      enabled: false
    }
  });

  useEffect(() => {
    if (!state.loaded) {
      setState({ loaded: true });
    }
  }, [state.loaded, chartData]);

  return !state.loaded ? (
    <CircularProgress />
  ) : (
    <HighchartsReact
      highcharts={Highcharts}
      allowChartUpdate={false}
      constructorType={"stockChart"}
      options={options.current}
      containerProps={{
        style: {
          position: "relative",
          top: "-35px"
        }
      }}
    />
  );
};

export default TransactionsChart;

TransactionsChart.propTypes = {
  chartData: PropTypes.array,
  benchmarkChartData: PropTypes.array,
  accountId: PropTypes.string,
  benchmarkId: PropTypes.string
};
