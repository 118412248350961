import React, { useRef } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
// import Link from "@mui/material/Link";
// import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";

import searchIcon from "../../../../../assets/search_icon.svg";
import useResponsive from "../../../../../hooks/useResponsive";

const CustomToolbar = props => {
  const isMobile = useResponsive("only", "xs");
  const { recipients, setData, page, setPage } = props;
  const filters = useRef({
    dateFrom: null,
    dateTo: null,
    account: "",
    type: "",
    search: ""
  });

  // const [isFiltered, setIsFiltered] = useState(false);
  const searchInput = useRef(null);

  // const resetFilters = () => {
  //   filters.current = {
  //     search: ""
  //   };
  //   searchInput.current.value = "";
  //   applyFilters();
  // };

  const requestSearch = searched => {
    const searchString = searched.toLowerCase();
    filters.current = {
      ...filters.current,
      search: searchString
    };
    applyFilters();
  };

  const applyFilters = () => {
    let filteredData = recipients;
    // let filtered = false;

    if (filters.current.search) {
      // filtered = true;
      filteredData = filteredData.filter(
        item =>
          item.RecipientEmail?.toLowerCase().includes(filters.current.search) ||
          item.RecipientName?.toLowerCase().includes(filters.current.search) ||
          item.ReadDate?.toLowerCase().includes(filters.current.search)
      );
    }

    if (page > 0) setPage(0);
    // setIsFiltered(filtered);
    setData(filteredData);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          width: "100%",
          my: 1
        }}
      >
        <Box
          sx={{
            width: isMobile ? "100%" : "auto",
            display: "flex",
            flexGrow: 1,
            justifyContent: "right",
            mt: isMobile ? 1.5 : 0
          }}
        >
          <TextField
            size="small"
            placeholder="Sök, fritext"
            type="search"
            onInput={e => requestSearch(e.target.value)}
            sx={{
              ...(isMobile && { flexGrow: 2 })
            }}
            InputProps={{
              inputRef: searchInput,
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      paddingBottom: 0.25
                    }}
                    alt="logo"
                    src={searchIcon}
                  />
                </InputAdornment>
              ),
              sx: {
                borderRadius: "20px",
                fontSize: "0.8rem",
                height: 40
              }
            }}
          />
        </Box>
      </Box>
    </>
  );
};

CustomToolbar.propTypes = {
  recipients: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
  page: PropTypes.number,
  setPage: PropTypes.func.isRequired
};

export default CustomToolbar;
