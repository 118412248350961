import React from "react";

import { useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";

import searchIcon from "../../assets/search_icon.svg";
import watchlistIcon from "../../assets/icons/ic_notification.svg";
import watchlistIconActive from "../../assets/icons/ic_notification_active.svg";
import DashboardPaper from "../../theme/styled/DashboardPaper";
import CustomTableFooter from "../CustomTableFooter";

import useCustomersTable from "./useCustomersTable";
import useAxios from "../../hooks/useAxios";

const CustomersTable = () => {
  const {
    isMobile,
    activitySegments,
    copyList,
    searchString,
    filters,
    page,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    requestSearch,
    handleActivitySegmentSelect,
    handleAccountClick,
    handleNotificationClick,
    toggleWatchlist,
    filterWatchlist,
    performWatchlistToggle
  } = useCustomersTable();

  const axiosGlobalController = useRef(null);
  const { initAxios } = useAxios();

  return (
    <DashboardPaper>
      <Typography variant="h6" align="left">
        Snabbsök kunder
      </Typography>
      <Box sx={{ mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" }
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: { xs: "100%", lg: "50%" }
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <FormControl
                sx={{ width: isMobile ? "100%" : 200, mr: isMobile ? 0 : 1 }}
              >
                <InputLabel
                  id="agent-name-select-label"
                  size="small"
                  sx={{ fontSize: "0.8rem" }}
                >
                  Aktivitetssegment
                </InputLabel>
                <Select
                  size="small"
                  labelId="agent-name-select-label"
                  id="agent-name-select"
                  value={
                    filters.current.activitySegment
                      ? activitySegments[filters.current.activitySegment].label
                      : "Alla"
                  }
                  label="Aktivitetssegment"
                  onChange={handleActivitySegmentSelect}
                  sx={{ fontSize: "0.8rem", height: 40 }}
                >
                  <MenuItem value={"Alla"} sx={{ fontSize: "0.8rem" }}>
                    Alla
                  </MenuItem>
                  {Object.entries(activitySegments).map(([key, value]) => (
                    <MenuItem
                      name={key}
                      key={key}
                      value={value.label}
                      sx={{ fontSize: "0.8rem" }}
                    >
                      {value.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <Switch
                checked={filterWatchlist}
                onChange={toggleWatchlist}
                id={"watchlist-switch"}
              />
              <label
                htmlFor={"watchlist-switch"}
                style={{
                  cursor: "pointer",
                  fontSize: "12px",
                  color: "#AAA"
                }}
              >
                Bevakningslista
              </label>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: { xs: "100%", lg: "50%" }
            }}
          >
            <TextField
              size="small"
              fullWidth={isMobile}
              placeholder="Sök på namn eller personnummer"
              type="search"
              value={searchString}
              onInput={e => requestSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      component="img"
                      sx={{
                        height: 20,
                        width: 20,
                        paddingBottom: 0.25
                      }}
                      alt="logo"
                      src={searchIcon}
                    />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: "20px"
                }
              }}
              sx={{ width: "65%" }}
            />
          </Box>
        </Box>
        <Table aria-label="custom pagination table" sx={{ mt: 1, border: 0 }}>
          <TableBody>
            {(rowsPerPage > 0
              ? copyList.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : copyList
            )
              // (copyList.length > 0 ? copyList : rows)
              .map((account, key) => (
                <TableRow key={key}>
                  <TableCell
                    align="left"
                    sx={{
                      width: "4%",
                      px: 1.2,
                      py: 1,
                      pr: 0,
                      pl: 0,
                      color: "inherit"
                    }}
                  >
                    {account.Watchlist ? (
                      <img
                        width={18}
                        height={18}
                        alt={"Bevakning aktiv"}
                        title="Bevakning aktiv"
                        src={watchlistIconActive}
                        onClick={handleNotificationClick(account.LegalEntityId)}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <img
                        width={18}
                        height={18}
                        alt={"Bevakning ej aktiv"}
                        title="Klicka här för att bevaka kund"
                        src={watchlistIcon}
                        onClick={handleNotificationClick(account.LegalEntityId)}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ width: "18%", px: 1.2, py: 1, color: "inherit" }}
                  >
                    <Typography
                      variant="fairTableCell"
                      color="secondary.lighter"
                    >
                      {account.IdentityNo}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "53%",
                      px: 1.2,
                      py: 1,
                      color: "secondary.lighter"
                    }}
                  >
                    <Typography variant="fairTableCell">
                      {account.Name}
                    </Typography>
                    <Typography
                      variant="fairTableCell"
                      sx={{
                        display: "block",
                        fontSize: "11px",
                        lineHeight: "11px",
                        color: "#BBB"
                      }}
                    >
                      <Tooltip
                        title={
                          activitySegments[account.activitySegment || "NULL"]
                            .label
                        }
                      >
                        <CircleIcon
                          fontSize="8"
                          sx={{
                            color:
                              activitySegments[
                                account.activitySegment || "NULL"
                              ].color,
                            mb: -0.25,
                            mr: 0.5
                          }}
                        />
                      </Tooltip>
                      Senast inloggad:{" "}
                      {account.LastLoginDate
                        ? `${account.LastLoginDate} (${account.DaysSinceLastLogin})`
                        : "-"}
                    </Typography>
                    {searchString.length > 1 &&
                      account.AccountNumbers?.find(accountNo =>
                        accountNo.includes(searchString)
                      ) && (
                        <Typography
                          variant="fairTableCell"
                          sx={{
                            mt: 0.25,
                            display: "block",
                            fontSize: "11px",
                            lineHeight: "11px",
                            color: "primary.lighter"
                          }}
                        >
                          Depå:{" "}
                          {account.AccountNumbers?.find(accountNo =>
                            accountNo.includes(searchString)
                          )}
                        </Typography>
                      )}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ width: "25%", px: 1.2, py: 1 }}
                  >
                    <Link
                      href="#"
                      onClick={handleAccountClick(account.LegalEntityId)}
                    >
                      <Typography
                        variant="fairTableCell"
                        color="primary.lighter"
                      >
                        Logga in som kund
                      </Typography>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            {copyList.length < 1 && (
              <TableRow>
                <TableCell sx={{ border: 0 }}>Inga resultat.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <CustomTableFooter
          type="customers"
          data={copyList}
          page={page}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          handleChangePage={handleChangePage}
        />

        {filterWatchlist && (
          <span
            style={{
              fontSize: "12px",
              color: "#4885B2",
              textDecoration: "underline",
              cursor: "pointer"
            }}
            onClick={async () => {
              if (
                !confirm(
                  "Bekräfta att du vill rensa din bevakninslista genom att trycka på OK"
                )
              ) {
                return;
              }
              const { axiosInstance, axiosController } = initAxios("private");
              axiosGlobalController.current = axiosController;
              await axiosInstance.post("user/watchlist/clear");
              performWatchlistToggle(false, true);
              alert("Bevakningslistan har nu rensats");
            }}
          >
            Rensa bevakningslistan
          </span>
        )}
      </Box>
    </DashboardPaper>
  );
};

export default CustomersTable;
