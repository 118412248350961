import { useState } from "react";

import useErrorHandler from "../../../../hooks/useErrorHandler";

const initialState = {
  submitted: false,
  fetching: false,
  message: ""
};

const useDeleteReportModal = props => {
  const errorHandler = useErrorHandler();
  const { toggleModal, document, handleDocumentDelete } = props;
  const [state, setState] = useState(initialState);
  const filename = document?.FileName;

  const toggle = () => {
    setState(initialState);
    return toggleModal();
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setState(prevState => ({
      ...prevState,
      submitted: true,
      fetching: true
    }));

    try {
      await handleDocumentDelete(document);
      toggle();
    } catch (err) {
      errorHandler.serverError(err);
      const msg = err?.response?.data?.msg;
      if (msg) {
        return setState(prevState => ({
          ...prevState,
          fetching: false,
          message: `Fel vid borttagning av dokument: ${err?.response?.data?.msg}` // Error deleting document
        }));
      }
    }
  };

  return {
    filename,
    state,
    toggle,
    handleSubmit
  };
};

export default useDeleteReportModal;
