const useConfirmBlockChangeModal = props => {
  const { toggleModal, handleBlockModalChange } = props;

  const toggle = () => {
    return toggleModal();
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleBlockModalChange();
    toggle();
  };

  return {
    toggle,
    handleSubmit
  };
};

export default useConfirmBlockChangeModal;
