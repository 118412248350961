import React, { Fragment } from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import useMenuDrawer from "./useMenuDrawer";

import { FeedbackPopover } from "../../components";

const MenuDrawer = () => {
  const {
    isMobile,
    isCustomer,
    open,
    height,
    nestedItems,
    customProps,
    selectedIndex,
    selectedChild,
    handleItemClick,
    handleChildClick,
    menu,
    toggleDrawer
  } = useMenuDrawer();

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9"
    }
  }));

  const list = () => (
    <Box role="presentation">
      <List sx={{ p: 0 }}>
        {menu.map((item, index) => (
          <Fragment key={item.label}>
            <ListItem disablePadding>
              <ListItemButton
                disableRipple
                sx={[
                  {
                    borderBottom: 1,
                    borderColor: "grey.400",
                    color: selectedIndex === index ? "#FFF" : "unset",
                    backgroundColor:
                      selectedIndex === index ? "primary.main" : "unset"
                  },
                  {
                    "&:hover":
                      selectedIndex === index
                        ? {
                            color: "#FFF",
                            backgroundColor: "primary.main"
                          }
                        : { backgroundColor: "secondary.main" }
                  }
                ]}
                onClick={handleItemClick(item, index)}
              >
                <ListItemIcon
                  variant={selectedIndex === index ? "active" : ""}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: "1.4rem" } }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    variant: "menuDrawer",
                    ...(selectedIndex === index && { sx: { color: "inherit" } })
                  }}
                />
                {item?.children &&
                  item?.children.length > 0 &&
                  (nestedItems[item.name] ? (
                    <ArrowDropUp />
                  ) : (
                    <ArrowDropDown />
                  ))}
              </ListItemButton>
            </ListItem>
            {item?.children && item?.children.length > 0 && (
              <Collapse
                in={nestedItems[item.name]}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {item?.children.map((child, index) => (
                    <ListItemButton
                      disableRipple
                      sx={[
                        {
                          pl: 4,
                          borderBottom: 1,
                          borderColor: "grey.400",
                          backgroundColor:
                            selectedChild.name === child.name &&
                            selectedChild.index === index
                              ? "#919eab14"
                              : "secondary.main"
                        },
                        {
                          "&:hover": {
                            backgroundColor: "#fff6eb"
                          }
                        }
                      ]}
                      key={index}
                      onClick={handleChildClick(child, index)}
                    >
                      {/* if it's accounts drawer show children tooltip on hover */}
                      {item.name === "depaer" ? (
                        <HtmlTooltip
                          disableFocusListener
                          placement="right"
                          title={
                            <React.Fragment>
                              <Typography color="inherit" variant="body2">
                                {child.label}
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <ListItemText
                            primary={child.label}
                            primaryTypographyProps={{
                              variant: "menuDrawer",
                              sx: {
                                ...(selectedChild.name === child.name &&
                                  selectedChild.index === index && {
                                    color: "inherit",
                                    fontWeight: 800
                                  }),
                                fontSize: "0.7em !important",
                                ml: 1.8,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              }
                            }}
                          />
                        </HtmlTooltip>
                      ) : (
                        <ListItemText
                          primary={child.label}
                          primaryTypographyProps={{
                            variant: "menuDrawer",
                            sx: {
                              ...(selectedChild.name === child.name &&
                                selectedChild.index === index && {
                                  color: "inherit",
                                  fontWeight: 800
                                }),
                              fontSize: "0.7em !important",
                              ml: 1.8,
                              whiteSpace: "nowrap",
                              maxWidth: 150,
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }
                          }}
                        />
                      )}
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <SwipeableDrawer
      PaperProps={{
        sx: {
          position: { md: "relative" },
          width: { xs: "65%", md: "100%" },
          height: { md: height },
          overflow: "auto",
          zIndex: { md: 2 },
          borderRightColor: "#CECECE"
        }
      }}
      anchor="left"
      open={open}
      onClose={toggleDrawer()}
      onOpen={toggleDrawer()}
      sx={{
        position: "fixed",
        width: { md: "18%", lg: "15%" }
      }}
      {...customProps}
    >
      {list()}
      {isMobile && isCustomer && <FeedbackPopover isMobile={isMobile} />}
    </SwipeableDrawer>
  );
};

export default MenuDrawer;
