const useConfirmHistoricHoldingsModal = props => {
  const { toggleModal, handleHistoricHoldingsModalChange } = props;

  const toggle = () => {
    return toggleModal();
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleHistoricHoldingsModalChange();
    toggle();
  };

  return {
    toggle,
    handleSubmit
  };
};

export default useConfirmHistoricHoldingsModal;
