import { useReducer, useEffect, useRef } from "react";

import useAxios from "../../../hooks/useAxios";
import feedbackFormReducer from "../../../reducers/feedbackFormReducer";
import validateFields from "../../../helpers/formValidator";

const useFeedbackForm = props => {
  const { initAxios } = useAxios();
  // const { user } = AuthConsumer();
  const axiosGlobalController = useRef(null);
  const { isMobile, handleClose } = props;

  const initialState = {
    loaded: false,
    satisfaction: "",
    wouldRecommend: "",
    otherFeedback: "",
    submitted: false,
    sending: false,
    inputErrors: null,
    serverMessage: null
  };

  const [state, dispatch] = useReducer(feedbackFormReducer, initialState);

  const handleInputChange = e => {
    e.preventDefault();

    return dispatch({
      type: "handleInput",
      field: e.target.name,
      payload: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;

    // dispatch({
    //   type: "handleServerError",
    //   payload: null
    // });

    // TODO filter to Recipients so we don't send too much data. Maybe even do it in recipientSelect??

    let { satisfaction, wouldRecommend, otherFeedback } = state;

    const inputErrors = validateFields({
      satisfaction,
      wouldRecommend
    });
    if (inputErrors)
      return dispatch({
        type: "handleInputErrors",
        payload: inputErrors
      });

    dispatch({
      type: "sending",
      payload: true
    });

    const feedback = `<b>Är du nöjd med din rådgivning?</b>\n${satisfaction}\n\n\n<b>Skulle du rekommendera denna förvaltning?</b>\n${wouldRecommend}\n\n\n<b>Övrig feedback:</b>\n${otherFeedback}`;

    try {
      const response = await axiosInstance.post("/message/feedback", {
        feedback
      });

      if (response.status !== 204)
        return dispatch({
          type: "handleServerError",
          payload:
            "Det gick inte att skicka meddelande. Vänligen försök igen senare."
        });

      return dispatch({
        type: "handleServerSuccess",
        payload: "Tack för din feedback!"
      });
    } catch (err) {
      console.log(err);
      return dispatch({
        type: "handleServerError",
        payload: err?.response?.data.msg || err
      });
    }
  };

  useEffect(() => {
    return () => axiosGlobalController.current?.abort();
  }, [axiosGlobalController]);

  return {
    isMobile,
    handleClose,
    state,
    handleInputChange,
    handleSubmit
  };
};

export default useFeedbackForm;
