import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel
} from "@mui/material";

const AudienceRadioGroup = props => {
  const { isBulk, toggleIsBulk, setAudience } = props;

  const handleRadioOptionChange = e => {
    e.preventDefault();
    setAudience("");
    toggleIsBulk();
  };

  return (
    <Box sx={{ width: "100%", my: 1 }}>
      <FormControl>
        <RadioGroup
          value={isBulk ? "bulk" : ""}
          name="message-audience-radio-group"
          onChange={handleRadioOptionChange}
          sx={{
            flexDirection: { xs: "column", md: "row" },
            alignItems: "baseline"
          }}
        >
          <FormControlLabel
            value=""
            control={<Radio />}
            label="Enstaka mottagare"
          />
          <FormControlLabel
            value="bulk"
            control={<Radio />}
            label="Massutskick"
            sx={{ ml: { xs: 0, md: "20px" } }}
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

AudienceRadioGroup.propTypes = {
  isBulk: PropTypes.bool,
  toggleIsBulk: PropTypes.func,
  setAudience: PropTypes.func
};

export default AudienceRadioGroup;
