import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";

import useAuthProvider from "../hooks/useAuthProvider";

const AuthCtxt = createContext(null);

const AuthProvider = ({ children }) => {
  const authProv = useAuthProvider();

  return (
    authProv.isLoaded && (
      <AuthCtxt.Provider value={authProv}>{children}</AuthCtxt.Provider>
    )
  );
};

export const AuthConsumer = () => {
  return useContext(AuthCtxt);
};

export default AuthProvider;

AuthProvider.propTypes = {
  children: PropTypes.node
};
