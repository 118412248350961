import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

import BankIdLogo from "../../../assets/bankid_logo.svg";
import FairModalBox from "../../../theme/styled/FairModalBox";
import FairCloseModalBox from "../../../theme/styled/FairCloseModalBox";
import FairButton from "../../../theme/styled/FairButton";

import { HomeConsumer } from "../../../contexts/HomeProvider";

const HomeModal = () => {
  const navigate = useNavigate();
  const homeCtxt = HomeConsumer();
  const [searchParams] = useSearchParams();
  const requiresLogin =
    searchParams.get("login") === "true" || searchParams.get("login") === "1";

  useEffect(() => {
    if (requiresLogin && !homeCtxt.state.homeModal) {
      homeCtxt.setState(prevState => ({
        ...prevState,
        homeModal: true
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiresLogin]);

  const handleClose = () => {
    homeCtxt.setState(prevState => ({
      ...prevState,
      homeModal: false
    }));
    navigate("/");
  };

  const openBankIdModal = () => {
    return homeCtxt.setState(prevState => ({
      ...prevState,
      bankIdModal: true
    }));
  };

  const openLoginModal = () => {
    return homeCtxt.setState(prevState => ({
      ...prevState,
      loginModal: true
    }));
  };

  return (
    <div>
      <Modal
        open={homeCtxt.state.homeModal}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
      >
        <FairModalBox>
          <Box
            sx={{
              height: 110,
              px: 4,
              pb: 5,
              borderBottom: 1,
              borderColor: "grey.300"
            }}
          >
            <Typography variant="h6" color="primary.main" sx={{ mb: 1 }}>
              Välkommen till Fair Investments
            </Typography>
            <p id="parent-modal-description"></p>
            <Typography variant="subtitle_form">
              För åtkomst av kundportalen, var god logga in med BankID nedan.
            </Typography>
          </Box>
          <Box
            sx={{
              height: 250,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexFlow: "column",
              px: 4
            }}
          >
            <Box
              component="img"
              sx={{
                width: 55,
                my: 2
              }}
              alt="logo"
              src={BankIdLogo}
            />
            <FairButton
              onClick={openBankIdModal}
              sx={{
                color: "primary.main"
              }}
            >
              Logga in med BankID
            </FairButton>
            <Button
              onClick={openLoginModal}
              sx={{
                color: "primary.lighter",
                my: 1
              }}
            >
              Logga in med användarnamn
            </Button>
          </Box>
          <FairCloseModalBox>
            <Button
              onClick={handleClose}
              sx={{
                color: "primary.lighter"
              }}
            >
              Avbryt
            </Button>
          </FairCloseModalBox>
        </FairModalBox>
      </Modal>
    </div>
  );
};

export default HomeModal;

HomeModal.propTypes = {
  open: PropTypes.bool
};
