import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import useResponsive from "../../hooks/useResponsive";

import { DashboardConsumer } from "../../contexts/DashboardProvider";

const useAccounts = () => {
  const isMobile = useResponsive("only", "xs");
  const navigate = useNavigate();
  const dashboardCtxt = DashboardConsumer();
  const [loaded, setLoaded] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const handleAccountClick = account => async e => {
    e.preventDefault;
    return navigate(`/dashboard/depaer/${account.AccountId}`, {
      state: { account }
    });
  };

  useEffect(() => {
    if (!loaded) {
      if (!loaded && dashboardCtxt.state?.accountsSummary) {
        setAccounts(dashboardCtxt.state?.accountsSummary);
        setLoaded(true);
      }
    }
  }, [dashboardCtxt.state.accountsSummary, loaded]);

  return {
    isMobile,
    loaded,
    accounts,
    handleAccountClick
  };
};

export default useAccounts;
