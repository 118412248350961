import React from "react";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";

const EnhancedTableHead = props => {
  const { order, orderBy, onRequestSort, isMobileOrTablet } = props;

  const headCells = [
    {
      id: "Name",
      disablePadding: true,
      label: "Värdepapper",
      width: isMobileOrTablet ? "20%" : "25%"
    },
    {
      id: "Count",
      disablePadding: true,
      label: "Antal",
      width: "10%"
    },
    {
      id: "LastPrice",
      disablePadding: true,
      label: "Kurs",
      width: "10%"
    },
    {
      id: "CurrencyCode",
      disablePadding: true,
      label: "Valuta",
      width: "10%"
    },
    {
      id: "LastPriceDate",
      disablePadding: true,
      label: "Kursdatum",
      width: "10%"
    },
    {
      id: "AvgAmountDirtyBase",
      disablePadding: true,
      label: "Anskaff.värde SEK",
      width: isMobileOrTablet ? "15%" : "12.5%"
    },
    {
      id: "MarketValueBase",
      disablePadding: true,
      label: "Markn.värde SEK",
      width: isMobileOrTablet ? "15%" : "12.5%"
    },
    {
      id: "UnrealisedProfit",
      disablePadding: true,
      label: "Orel. resultat",
      width: "10%"
    }
  ];

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              width: headCell.width,
              px: 0,
              py: 1,
              textAlign: index >= headCells.length - 1 ? "right" : "left"
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon
              sx={{
                // flexDirection: "row-reverse",
                "& .MuiSvgIcon-root": {
                  ml: 0.2,
                  mr: 0,
                  fontSize: "12px"
                }
              }}
            >
              <Typography variant="fairTableCellHeader">
                {headCell.label}
              </Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  isMobileOrTablet: PropTypes.bool.isRequired
};

export default EnhancedTableHead;
