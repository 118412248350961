import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { format, differenceInDays } from "date-fns";

import useAxios from "../../../hooks/useAxios";

import { filterArrayDupsByObjectPropertyValue } from "../../../helpers/functions";
import { AuthConsumer } from "../../../contexts/AuthProvider";
import useErrorHandler from "../../../hooks/useErrorHandler";

const usePoaModal = () => {
  const navigate = useNavigate();
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();
  const authCtxt = AuthConsumer();
  const { user, elevatedUser } = authCtxt;
  const [isOpen, setIsOpen] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [poaUsers, setPoaUsers] = useState([]);
  const [copyList, setCopyList] = useState([]);
  const [searchString, setSearchString] = useState("");

  const rowsPerPage = 8;
  const [page, setPage] = useState(0);

  // setting main user (in case Admin user is acting as poa user)
  const mainUser = elevatedUser.IsPoaAgent ? elevatedUser : user;

  // Avoid a layout jump when reaching the last page with empty rows.
  //  const emptyRows =
  //    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const requestSearch = searched => {
    if (page > 0) setPage(0);
    const searchString = searched.toLowerCase();
    setSearchString(searchString);
    setCopyList(
      poaUsers.filter(
        customer =>
          (customer.FirstName + " " + customer.LastName)
            .toLowerCase()
            .includes(searchString) ||
          customer.IdentityNo?.toLowerCase().includes(searchString) ||
          customer.Name?.toLowerCase().includes(searchString) ||
          customer.AccountNumbers?.find(accountNo =>
            accountNo.includes(searchString)
          )
      )
    );
  };

  useEffect(() => {
    if (!isLoaded) {
      const getPoaUsers = async () => {
        try {
          const { axiosInstance, axiosController } = initAxios("private");
          axiosGlobalController.current = axiosController;

          const requests = [];
          // 1. poa agent account numbers data request (for searching)
          const poaAgentAccountsRequest = axiosInstance.get(
            "/account/all/poa/owner"
          );
          requests.push(poaAgentAccountsRequest);

          // 2. poa users data request
          const poaUsersRequest = axiosInstance.get("/user/poa/list");
          requests.push(poaUsersRequest);

          Promise.all(requests)
            .then(responses => {
              const poaAgentAccounts = responses[0].data.AccountNumbers;
              let poaUsers = responses[1].data;

              // Adding the POA user account to the top of the list with fetched AccountNumbers if Role is not Poa
              if (mainUser.Role !== "Poa" && mainUser.Role !== "Assistant") {
                poaUsers.splice(0, 0, {
                  IdentityNo: mainUser.IdentityNo,
                  Name: mainUser.Name,
                  FirstName: mainUser.FirstName,
                  LastName: mainUser.LastName,
                  PrincipalLegalEntityId: mainUser.LegalEntityId,
                  AccountNumbers: poaAgentAccounts
                });
              }

              // Parsing LastLoginDate/DaysSinceLastLogin and owned Accounts logic
              poaUsers.forEach(account => {
                if (account.AccountNumbers) {
                  account.AccountNumbers = account.AccountNumbers.split(",");
                }

                // Safari 15.6 date fix https://github.com/date-fns/date-fns/issues/2130
                let lastLoginDate = account.LastLoginDate
                  ? account.LastLoginDate.replace(" ", "T")
                  : null;

                // only parse LastLoginDate/DaysSinceLastLogin if it's not a Customer role
                if (elevatedUser.Role !== "Customer" && lastLoginDate) {
                  lastLoginDate = format(new Date(lastLoginDate), "yyyy-MM-dd");

                  const daysSinceLastLogin = differenceInDays(
                    new Date(),
                    new Date(lastLoginDate)
                  );

                  switch (daysSinceLastLogin) {
                    case 0:
                      account.DaysSinceLastLogin = "idag";
                      break;
                    case 1:
                      account.DaysSinceLastLogin = "igår";
                      break;
                    default:
                      account.DaysSinceLastLogin = `${daysSinceLastLogin} dagar sedan`;
                      break;
                  }
                }
              });

              poaUsers = filterArrayDupsByObjectPropertyValue(
                poaUsers,
                "PrincipalLegalEntityId"
              );

              setPoaUsers(poaUsers);
              setCopyList(poaUsers);
              setIsLoaded(true);
            })
            .catch(function (err) {
              errorHandler.serverError(err);
            });
        } catch (err) {
          errorHandler.serverError(err);
        }
      };

      getPoaUsers();
    }
  }, [errorHandler, isLoaded, user, elevatedUser, initAxios, mainUser]);

  const handleAccountClick = legalEntityId => async e => {
    e.preventDefault();

    const { axiosInstance, axiosController } = initAxios("auth");
    axiosGlobalController.current = axiosController;

    try {
      if (legalEntityId === elevatedUser.LegalEntityId) {
        // switching to POA
        await axiosInstance.get("auth/switch/elevated");
        authCtxt.login(elevatedUser);
        authCtxt.setIsLoaded(false);
      } else {
        // switching to Customer
        const response = await axiosInstance.post("auth/switch", {
          legalEntityId
        });
        const { user } = response.data;
        authCtxt.login(user, elevatedUser);
        authCtxt.setIsLoaded(false);
      }
    } catch (err) {
      errorHandler.serverError(err);
    }

    setIsOpen(false);
    navigate("/dashboard");
  };

  const returnToBackoffice = async () => {
    try {
      const { axiosInstance, axiosController } = initAxios("private");
      axiosGlobalController.current = axiosController;
      await axiosInstance.get("auth/switch/elevated");
      authCtxt.login(elevatedUser);
      authCtxt.setIsLoaded(false);
      return navigate("/dashboard");
    } catch (err) {
      errorHandler.serverError(err);
    }
  };

  const handleClose = async () => {
    if (elevatedUser.Role === "Admin" || elevatedUser.Role === "Backoffice") {
      return returnToBackoffice();
    }
    if (mainUser.Role === "Poa" || mainUser.Role === "Assistant") {
      return authCtxt.logout(false);
    }
    setIsOpen(false);
    axiosGlobalController.current?.abort();
    navigate("/dashboard");
  };

  useEffect(() => {
    return () => axiosGlobalController.current?.abort();
  }, [axiosGlobalController]);

  return {
    isOpen,
    isLoaded,
    elevatedUser,
    copyList,
    searchString,
    page,
    rowsPerPage,
    handleChangePage,
    requestSearch,
    handleAccountClick,
    handleClose
  };
};

export default usePoaModal;
