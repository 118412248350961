import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";
import MailIcon from "@mui/icons-material/Mail";
import AccountBalanceIcon from "@mui/icons-material/AccountBalanceWallet";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FeedIcon from "@mui/icons-material/FeedOutlined";
import QueryStats from "@mui/icons-material/QueryStats";
import SettingsIcon from "@mui/icons-material/Settings";

import useResponsive from "../../hooks/useResponsive";
import { getObjectKeyByValue } from "../../helpers/functions";

import { DashboardConsumer } from "../../contexts/DashboardProvider";
import { MenuDrawerConsumer } from "../../contexts/MenuDrawerProvider";

const useMenuDrawer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useResponsive("only", "xs");
  const isLaptopOrDesktop = useResponsive("up", "md");
  const dashboardCtxt = DashboardConsumer();
  const menuDrawerCtxt = MenuDrawerConsumer();
  const open = isLaptopOrDesktop ? true : menuDrawerCtxt.state.open;
  const [height, setHeight] = useState(0);

  const { isAdminUser, isBackofficeUser, isCustomer } = dashboardCtxt.state;

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedChild, setSelectedChild] = useState({});
  const [nestedItems, setNestedItems] = useState({});
  const customProps = {
    variant: isLaptopOrDesktop ? "permanent" : "temporary"
  };

  const toggleDrawer = () => e => {
    if (
      isLaptopOrDesktop ||
      (e && e.type === "keydown" && (e.key === "Tab" || e.key === "Shift"))
    ) {
      return;
    }
    return menuDrawerCtxt.toggleDrawer();
  };

  // Handle parent menu item click
  const handleItemClick = item => e => {
    e.preventDefault;
    if (item.name !== getObjectKeyByValue(nestedItems, true)) {
      // if it's not a currently opened nested item
      setNestedItems({});
      item.handleNavigation();
      if (item.children)
        setNestedItems(prevState => ({
          ...prevState,
          [item.name]: !prevState[item.name]
        }));
    }
    if (isLaptopOrDesktop) return;
    return menuDrawerCtxt.toggleDrawer();
  };

  // Handle nested children click
  const handleChildClick = child => e => {
    e.preventDefault;
    child.handleNavigation();
    if (isLaptopOrDesktop) return;
    return menuDrawerCtxt.toggleDrawer();
  };

  let accountsDrawer = [];

  const accounts = dashboardCtxt.state?.accountsSummary;
  if (isCustomer) {
    accounts.forEach((account, index) => {
      const newAccount = {
        name: `accounts_child_${index}`,
        label: account.AccountName,
        icon: <AccountBalanceIcon />,
        navTo: `/dashboard/depaer/${account.AccountId}`,
        handleNavigation: () => {
          return navigate(`/dashboard/depaer/${account.AccountId}`);
        }
      };
      accountsDrawer.push(newAccount);
    });
  }

  const reportsDrawer = [
    {
      name: "annual_report",
      label: "Årsrapporter",
      // icon: <StarBorder />,
      navTo: "/dashboard/rapporter/arsrapporter",
      handleNavigation: () => {
        return navigate("/dashboard/rapporter/arsrapporter");
      }
    },
    {
      name: "portfolio_report",
      label: "Portföljrapporter",
      // icon: <StarBorder />,
      navTo: "/dashboard/rapporter/portfoljrapporter",
      handleNavigation: () => {
        return navigate("/dashboard/rapporter/portfoljrapporter");
      }
    },
    {
      name: "advisory_document",
      label: "Rådgivningsdokument",
      // icon: <StarBorder />,
      navTo: "/dashboard/rapporter/radgivningsdokument",
      handleNavigation: () => {
        return navigate("/dashboard/rapporter/radgivningsdokument");
      }
    },
    {
      name: "costs_fees",
      label: "Kostnader och avgifter",
      // icon: <StarBorder />,
      navTo: "/dashboard/rapporter/kostnaderAvgifter",
      handleNavigation: () => {
        return navigate("/dashboard/rapporter/kostnaderAvgifter");
      }
    }
  ];

  const menu = [
    {
      name: "home",
      label: "Hem",
      icon: <HomeIcon />,
      navTo: "/dashboard",
      handleNavigation: () => {
        return navigate("/dashboard");
      }
    },
    {
      name: "messages",
      label: "Meddelanden",
      icon: <MailIcon />,
      navTo: "/dashboard/meddelanden",
      handleNavigation: () => {
        navigate("/dashboard/meddelanden");
      }
    },
    ...(isCustomer && accountsDrawer.length > 0
      ? [
          {
            name: "depaer",
            label: "Depåer",
            icon: <AccountBalanceIcon />,
            navTo: accountsDrawer[0].navTo,
            children: accountsDrawer,
            handleNavigation: () => {
              navigate(accountsDrawer[0].navTo);
            }
          }
        ]
      : []),
    {
      name: "rapporter",
      label: "Rapporter",
      icon: <ListAltIcon />,
      navTo: reportsDrawer[0].navTo,
      children: reportsDrawer,
      handleNavigation: () => {
        navigate(reportsDrawer[0].navTo);
      }
    },
    ...(isCustomer
      ? [
          {
            name: "transactions",
            label: "Transaktioner",
            icon: <FeedIcon />,
            navTo: "/dashboard/transaktioner",
            handleNavigation: () => {
              return navigate("/dashboard/transaktioner");
            }
          }
        ]
      : []),
    ...(isAdminUser || isBackofficeUser
      ? [
          {
            name: "poaHandling",
            label: "Fullmakter",
            icon: <FeedIcon />,
            navTo: "/dashboard/fullmakter",
            handleNavigation: () => {
              return navigate("/dashboard/fullmakter");
            }
          }
        ]
      : []),
    ...(isAdminUser || isBackofficeUser
      ? [
          {
            name: "insights",
            label: "Kundinsikt",
            icon: <QueryStats />,
            navTo: "/dashboard/insikt",
            handleNavigation: () => {
              return navigate("/dashboard/insikt");
            }
          }
        ]
      : []),
    ...(isAdminUser
      ? [
          {
            name: "auditLogs",
            label: "Audit Logs",
            icon: <FeedIcon />,
            navTo: "/dashboard/logs",
            handleNavigation: () => {
              return navigate("/dashboard/logs");
            }
          },
          {
            name: "adminSettings",
            label: "Inställningar",
            icon: <SettingsIcon />,
            navTo: "/dashboard/settings",
            handleNavigation: () => {
              return navigate("/dashboard/settings");
            }
          }
        ]
      : [])
  ];

  // checks if current location matches a menu or sub-menu location
  const locationMatches = item => {
    if (
      item.navTo === location.pathname ||
      (item.navTo.includes("/depaer/") &&
        location.pathname.includes("/depaer/")) ||
      (item.navTo.includes("/rapporter/") &&
        location.pathname.includes("/rapporter/"))
    ) {
      return true;
    }

    return false;
  };

  // MUI drawer height fix
  useEffect(() => {
    if (isLaptopOrDesktop && ResizeObserver) {
      const dashboardContentEle = document.getElementsByClassName(
        "fair-dashboard-content"
      )[0];

      new ResizeObserver(() =>
        setHeight(dashboardContentEle.offsetHeight + 100)
      ).observe(dashboardContentEle);
    }
  }, [isLaptopOrDesktop]);

  // On location change set current active menu item (if any)
  useEffect(() => {
    setNestedItems({});
    setSelectedIndex(null);
    setSelectedChild({});

    menu.forEach((item, index) => {
      if (locationMatches(item)) {
        setSelectedChild({});
        setSelectedIndex(index);
      }
    });

    accountsDrawer.forEach((account, index) => {
      if (account?.navTo === location.pathname) {
        setSelectedChild({ name: account.name, index: index });
        setNestedItems({ depaer: true });
      }
    });

    reportsDrawer.forEach((report, index) => {
      if (report?.navTo === location.pathname) {
        setSelectedChild({ name: report.name, index: index });
        setNestedItems({ rapporter: true });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return {
    isMobile,
    isCustomer,
    open,
    height,
    customProps,
    nestedItems,
    selectedIndex,
    selectedChild,
    handleItemClick,
    handleChildClick,
    menu,
    toggleDrawer
  };
};

export default useMenuDrawer;
