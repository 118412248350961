import React, { useRef } from "react";
import PropTypes from "prop-types";

import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";

import FeedbackForm from "./FeedbackForm";

const FeedbackPopover = props => {
  const { isMobile } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const btnRef = useRef(null);

  const handleClick = event => {
    // console.log(btnRef, "btnRef");
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "feedback" : undefined;

  return (
    <>
      <Button
        ref={btnRef.current}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        sx={{
          position: "fixed",
          bottom: "15px",
          ...(isMobile && { left: "15px" }),
          ...(!isMobile && { right: "15px" })
        }}
      >
        Feedback
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        PaperProps={{
          style: {
            ...(isMobile && { width: "100%", height: "100%" }),
            boxShadow:
              "0px 0px 0px -3px rgba(145, 158, 171, 0.5),0px 0px 10px 1px rgba(145, 158, 171, 0.5),0px 0px 14px 2px rgba(145, 158, 171, 0.5)"
          }
        }}
      >
        <FeedbackForm isMobile={isMobile} handleClose={handleClose} />
      </Popover>
    </>
  );
};

FeedbackPopover.propTypes = {
  isMobile: PropTypes.bool.isRequired
};

export default FeedbackPopover;
