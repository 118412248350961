import { useState, useEffect, useRef, useCallback } from "react";

import useAxios from "../hooks/useAxios";
import useErrorHandler from "./useErrorHandler";

const useSettingsProvider = () => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();

  const [isLoaded, setIsLoaded] = useState(false);
  const [blockModal, setBlockModal] = useState(null);
  const [noticeModal, setNoticeModal] = useState(null);
  const [historicHoldings, setHistoricHoldings] = useState(null);

  const getSettings = useCallback(async () => {
    const { axiosInstance, axiosController } = initAxios("default");
    axiosGlobalController.current = axiosController;

    try {
      const { data } = await axiosInstance.get(`/settings`);

      const blockModal = data.find(obj => obj["name"] === "BLOCK_MODAL");
      const noticeModal = data.find(obj => obj["name"] === "NOTICE_MODAL");
      const historicHoldings = data.find(
        obj => obj["name"] === "SHOW_HISTORIC_HOLDINGS"
      );

      setBlockModal(blockModal);
      setNoticeModal(noticeModal);
      setHistoricHoldings(historicHoldings);
      return setIsLoaded(true);
    } catch (err) {
      errorHandler.serverError(err);
    }
  }, [errorHandler, initAxios]);

  const handleNoticeChange = useCallback(async () => {
    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;

    try {
      await axiosInstance.post("/settings/notice", {
        status: noticeModal?.value === "1" ? false : true
      });

      getSettings();
      return true;
    } catch (err) {
      errorHandler.serverError(err);
    }
  }, [noticeModal?.value, errorHandler, getSettings, initAxios]);

  const handleBlockChange = useCallback(async () => {
    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;

    try {
      await axiosInstance.post("/settings/block", {
        status: blockModal?.value === "1" ? false : true
      });

      getSettings();
      return true;
    } catch (err) {
      errorHandler.serverError(err);
    }
  }, [blockModal?.value, errorHandler, getSettings, initAxios]);

  const handleHistoricHoldingsChange = useCallback(async () => {
    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;

    try {
      await axiosInstance.post("/settings/historic", {
        status: historicHoldings?.value === "1" ? false : true
      });

      getSettings();
      return true;
    } catch (err) {
      errorHandler.serverError(err);
    }
  }, [historicHoldings?.value, errorHandler, getSettings, initAxios]);

  useEffect(() => {
    if (!isLoaded) {
      getSettings();
    }

    return () => axiosGlobalController.current?.abort();
  }, [errorHandler, getSettings, initAxios, isLoaded]);

  return {
    isLoaded,
    blockModal,
    noticeModal,
    historicHoldings,
    handleNoticeChange,
    handleBlockChange,
    handleHistoricHoldingsChange
  };
};

export default useSettingsProvider;
