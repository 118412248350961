import { useState, useCallback } from "react";

import { SettingsConsumer } from "../../contexts/SettingsProvider";

const useAdminSettings = () => {
  const {
    noticeModal,
    blockModal,
    historicHoldings,
    handleNoticeChange,
    handleBlockChange,
    handleHistoricHoldingsChange
  } = SettingsConsumer();

  const [state, setState] = useState({
    loaded: true,
    noticeModal: noticeModal.value === "1" ? true : false,
    blockModal: blockModal.value === "1" ? true : false,
    historicHoldings: historicHoldings.value === "1" ? true : false,
    showNoticeModalConfirmation: false,
    showBlockModalConfirmation: false,
    showHistoricHoldingsConfirmation: false
  });

  const toggleNoticeConfirmationModal = () => {
    setState(prevState => ({
      ...prevState,
      showNoticeModalConfirmation: !prevState.showNoticeModalConfirmation
    }));
  };

  const toggleBlockConfirmationModal = () => {
    setState(prevState => ({
      ...prevState,
      showBlockModalConfirmation: !prevState.showBlockModalConfirmation
    }));
  };

  const toggleHistoricHoldingsConfirmationModal = () => {
    setState(prevState => ({
      ...prevState,
      showHistoricHoldingsConfirmation:
        !prevState.showHistoricHoldingsConfirmation
    }));
  };

  const handleNoticeModalChange = useCallback(async () => {
    const changeSuccessful = await handleNoticeChange();
    if (changeSuccessful) {
      setState(prevState => ({
        ...prevState,
        noticeModal: !prevState.noticeModal
      }));
    }
  }, [handleNoticeChange]);

  const handleBlockModalChange = useCallback(async () => {
    const changeSuccessful = await handleBlockChange();
    if (changeSuccessful) {
      setState(prevState => ({
        ...prevState,
        blockModal: !prevState.blockModal
      }));
    }
  }, [handleBlockChange]);

  const handleHistoricHoldingsModalChange = useCallback(async () => {
    const changeSuccessful = await handleHistoricHoldingsChange();
    if (changeSuccessful) {
      setState(prevState => ({
        ...prevState,
        historicHoldings: !prevState.historicHoldings
      }));
    }
  }, [handleHistoricHoldingsChange]);

  const handleClickNoticeSwitch = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      showNoticeModalConfirmation: true
    }));
  }, []);

  const handleClickBlockSwitch = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      showBlockModalConfirmation: true
    }));
  }, []);

  const handleClickHistoricHoldingsSwitch = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      showHistoricHoldingsConfirmation: true
    }));
  }, []);

  return {
    state,
    handleClickNoticeSwitch,
    handleClickBlockSwitch,
    handleClickHistoricHoldingsSwitch,
    toggleNoticeConfirmationModal,
    toggleBlockConfirmationModal,
    toggleHistoricHoldingsConfirmationModal,
    handleNoticeModalChange,
    handleBlockModalChange,
    handleHistoricHoldingsModalChange
  };
};

export default useAdminSettings;
