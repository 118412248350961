import React from "react";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import UnreadDotIcon from "@mui/icons-material/Circle";

import useNotificationsPopover from "./useNotificationsPopover";

const NotificationsPopover = () => {
  const navBar = useNotificationsPopover();
  const {
    anchorEl,
    newCount,
    handleClickNotifications,
    handleClickNotification,
    handleCloseNotifications,
    isOpen,
    notifications,
    clearNotifications
  } = navBar;

  return (
    <>
      <IconButton
        size="small"
        aria-label="show 1 new notification"
        onClick={handleClickNotifications}
        sx={{ display: "inline-block", pt: 1, mr: 3 }}
      >
        <Badge fontSize="small" badgeContent={newCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id="notifications-popover"
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleCloseNotifications}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <List
          sx={{
            // width: "100%",
            width: 280,
            // maxWidth: 450,
            maxHeight: 400,
            py: 0
          }}
          component="nav"
        >
          {notifications.map((notification, key) => (
            <ListItemButton
              key={key}
              disableRipple
              alignItems="flex-start"
              onClick={handleClickNotification(notification)}
              sx={{
                height: 60,
                p: 0,
                mx: 0,
                alignItems: "center"
              }}
            >
              <ListItemText
                primary={
                  <Box sx={{ width: "100%", display: "flex" }}>
                    <Box
                      sx={{
                        display: "flex",
                        width: "90%",
                        alignItems: "center",
                        justifyContent: "flex-start"
                      }}
                    >
                      {notification.Content}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "10%",
                        alignItems: "center",
                        justifyContent: "flex-end"
                      }}
                    >
                      {notification.IsRead === "0" && (
                        <UnreadDotIcon
                          sx={{ fill: "#FF6060", fontSize: "0.6rem", ml: 1 }}
                        />
                      )}
                    </Box>
                  </Box>
                }
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: "0.9rem",
                    ml: 0,
                    px: 2
                  }
                }}
              />
            </ListItemButton>
          ))}
          <Box
            sx={{
              width: "100%",
              display: "inline-flex",
              justifyContent: "center",
              mt: 1,
              mb: notifications.length > 0 ? 2 : 1
            }}
          >
            {notifications.length > 0 ? (
              <Link sx={{ cursor: "pointer" }} onClick={clearNotifications}>
                <Typography variant="modalCell">Rensa listan</Typography>
              </Link>
            ) : (
              <Typography variant="modalCell">
                Du har inga olästa notifikationer just nu.
              </Typography>
            )}
          </Box>
        </List>
      </Popover>
    </>
  );
};

export default NotificationsPopover;
