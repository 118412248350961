// import logo from "./logo.svg";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";

import SectionHeaderPaper from "../../theme/styled/SectionHeaderPaper";
import SectionWrapperBox from "../../theme/styled/SectionWrapperBox";
import SectionInnerLeftWrapBox from "../../theme/styled/SectionInnerLeftWrapBox";
import SectionInnerRightWrapBox from "../../theme/styled/SectionInnerRightWrapBox";

import { ContactUs } from "..";

import Reports from "./Reports";
import useReports from "./useReports";

const ReportsSection = () => {
  const {
    isMobile,
    auth,
    state,
    isCustomer,
    isImpersonatedBy,
    fetchDocuments,
    handleTabChange,
    requestSearch,
    handleDocumentDownload,
    handleDocumentDelete
  } = useReports();

  const { loaded, filteredDocuments, currentTab, searchInput } = state;

  return !loaded ? (
    <Box
      sx={{
        height: "100%",
        minHeight: "80vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box>
      <SectionHeaderPaper>
        <Typography variant="h3" align="left">
          Rapporter
        </Typography>
        <Typography variant="subtitle1">
          {!isMobile &&
            "Här kan du se dina årsrapporter, portföljrapporter och rådgivningsdokument"}
        </Typography>
      </SectionHeaderPaper>
      <SectionWrapperBox>
        <SectionInnerLeftWrapBox>
          {isCustomer && (
            <Reports
              auth={auth}
              filteredDocuments={filteredDocuments}
              currentTab={currentTab}
              searchInput={searchInput}
              requestSearch={requestSearch}
              fetchDocuments={fetchDocuments}
              handleTabChange={handleTabChange}
              handleDocumentDownload={handleDocumentDownload}
              handleDocumentDelete={handleDocumentDelete}
              isImpersonatedBy={isImpersonatedBy}
            />
          )}
        </SectionInnerLeftWrapBox>
        <SectionInnerRightWrapBox>
          <ContactUs />
        </SectionInnerRightWrapBox>
      </SectionWrapperBox>
    </Box>
  );
};

export default ReportsSection;
