import React, { useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import DashboardPaper from "../../../theme/styled/DashboardPaper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";

import FairTabs from "../../../theme/styled/FairTabs";
import searchIcon from "../../../assets/search_icon.svg";

import { createDateObjectFromString } from "../../../helpers/functions";
import CustomTableFooter from "../../CustomTableFooter";
import UpLoadReports from "./UploadReports";
import DeleteReportModal from "./DeleteReportModal";

const Reports = props => {
  const {
    auth,
    filteredDocuments,
    currentTab,
    searchInput,
    requestSearch,
    fetchDocuments,
    handleTabChange,
    handleDocumentDownload,
    handleDocumentDelete,
    isImpersonatedBy
  } = props;

  const rowsPerPage = 10;
  const [page, setPage] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [document, setDocument] = useState({});
  const hasUploadPermission =
    isImpersonatedBy === "Admin" || isImpersonatedBy === "Backoffice";

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const toggleDeleteModal = (e = false, document = false) => {
    if (e) e.preventDefault();
    setDocument(document ? document : {});

    return setDeleteModal(prevState => !prevState);
  };

  return (
    <DashboardPaper>
      <Box sx={{ display: "flex", width: "100%", mt: 0, mb: 2 }}>
        <Box
          sx={{
            width: "80%",
            display: "flex",
            justifyContent: "left"
          }}
        >
          <FairTabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="report tabs"
          >
            <Tab
              disableRipple
              disableFocusRipple
              label="Årsrapporter"
              value="AnnualStatements"
              sx={{ alignItems: "flex-start", px: 0, mr: 0.5, minWidth: 60 }}
            />
            <Tab
              disableRipple
              disableFocusRipple
              label="Portföljrapporter"
              value="PortfolioStatements"
              sx={{ alignItems: "flex-start", px: 0, mr: 0.5, minWidth: 60 }}
            />
            <Tab
              disableRipple
              disableFocusRipple
              label="Rådgivningar"
              value="AdviseDocuments"
              sx={{ alignItems: "flex-start", px: 0, mr: 0.5, minWidth: 60 }}
            />
            <Tab
              disableRipple
              disableFocusRipple
              label="Kostnader och avgifter"
              value="CostAndCharges"
              sx={{ alignItems: "flex-start", px: 0, mr: 0.5, minWidth: 60 }}
            />
          </FairTabs>
        </Box>
        <Box
          sx={{
            width: "20%",
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <TextField
            size="small"
            placeholder="Sök"
            type="search"
            value={searchInput}
            onInput={e => requestSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      paddingBottom: 0.25
                    }}
                    alt="logo"
                    src={searchIcon}
                  />
                </InputAdornment>
              ),
              sx: {
                borderRadius: "20px"
              }
            }}
          />
        </Box>
      </Box>
      {hasUploadPermission && (
        <UpLoadReports
          auth={auth}
          type={currentTab}
          fetchDocuments={fetchDocuments}
        />
      )}
      <TableContainer component={Box} sx={{ mb: 2 }}>
        <Table sx={{ border: 0 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: "20vw", py: 1 }}>
                <Typography variant="fairTableCellHeader" color="common.black">
                  Dokument
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  variant="fairTableCellHeader"
                  color="common.black"
                  sx={{ py: 1, float: "right" }}
                >
                  Datum
                </Typography>
              </TableCell>
              <TableCell
                sx={{ width: auth.elevatedUser ? 60 : 40 }}
              ></TableCell>
              {auth.elevatedUser && <TableCell sx={{ width: 35 }}></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredDocuments.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredDocuments
            ).map(document => (
              <TableRow
                key={document.DocumentId}
                sx={{
                  "&:last-child td": { border: 0 },
                  transition: "color 0.3s, background-color 0.3s",
                  "&:hover": {
                    color: "common.white",
                    backgroundColor: "secondary.main"
                  }
                }}
              >
                <TableCell sx={{ py: 1 }}>
                  <Typography
                    title={`Ladda ner ${document.FileName}`}
                    variant="fairTableCell"
                    onClick={handleDocumentDownload(document)}
                    sx={{ cursor: "pointer" }}
                  >
                    {document.FileName}
                  </Typography>
                </TableCell>

                <TableCell align="right" sx={{ py: 1 }}>
                  <Typography variant="fairTableCell">
                    {document.Created &&
                    document.Created !== "0000-00-00 00:00:00"
                      ? format(
                          createDateObjectFromString(document.Created),
                          "yyyy-MM-dd"
                        )
                      : "-"}
                  </Typography>
                </TableCell>
                <TableCell sx={{ px: 0, py: 1 }} align="right">
                  <IconButton
                    title={`Ladda ner ${document.FileName}`}
                    sx={{ cursor: "pointer" }}
                    onClick={handleDocumentDownload(document)}
                  >
                    {document.Downloaded ? (
                      <DownloadDoneIcon fontSize="small" />
                    ) : document.Downloading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <DownloadIcon fontSize="small" />
                    )}
                  </IconButton>
                </TableCell>

                {auth.elevatedUser && (
                  <TableCell sx={{ px: 0, py: 1 }} align="right">
                    {document.CreatorLegalEntityId && (
                      <IconButton
                        title={`Ta bort ${document.FileName}`}
                        sx={{ cursor: "pointer" }}
                        onClick={e => toggleDeleteModal(e, document)}
                      >
                        {document.Deleting ? (
                          <CircularProgress size={20} />
                        ) : (
                          <DeleteIcon fontSize="small" />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {filteredDocuments.length >= 1 ? (
          <CustomTableFooter
            type="reports"
            data={filteredDocuments}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
          />
        ) : (
          <Typography variant="subtitle2" sx={{ mt: 1.25, mb: 2.5 }}>
            Just nu finns det inga rapporter här.
          </Typography>
        )}
      </TableContainer>{" "}
      <DeleteReportModal
        open={deleteModal}
        toggleModal={toggleDeleteModal}
        document={document}
        handleDocumentDelete={handleDocumentDelete}
      />
    </DashboardPaper>
  );
};

Reports.propTypes = {
  auth: PropTypes.object,
  filteredDocuments: PropTypes.array,
  currentTab: PropTypes.string,
  searchInput: PropTypes.string,
  requestSearch: PropTypes.func,
  fetchDocuments: PropTypes.func,
  handleTabChange: PropTypes.func,
  handleDocumentDownload: PropTypes.func,
  handleDocumentDelete: PropTypes.func,
  isImpersonatedBy: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default Reports;
