import { useRef, useEffect, useReducer, useMemo } from "react";

import useAxios from "../../../hooks/useAxios";
import editUserModalReducer from "../../../reducers/editUserModalReducer";
import validateFields from "../../../helpers/formValidator";
import trim from "validator/lib/trim";

import useErrorHandler from "../../../hooks/useErrorHandler";

const useAddUserModal = props => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();
  const { user, toggleModal, refreshTable } = props;

  const initialState = useMemo(() => {
    return {
      identityNo: user?.IdentityNo,
      firstname: user?.FirstName,
      lastname: user?.LastName,
      email: user?.Email,
      telephone: user?.Phone,
      role: user?.Role,
      fetching: false,
      inputErrors: null,
      serverMessage: null
    };
  }, [user]);

  const [state, dispatch] = useReducer(editUserModalReducer, initialState);

  useEffect(() => {
    dispatch({
      type: "setState",
      payload: initialState
    });
  }, [initialState]);

  const closeModal = () => {
    // on close Modal put state back as it was
    dispatch({
      type: "setState",
      payload: initialState
    });
    return toggleModal();
  };

  const handleInputChange = e => {
    e.preventDefault();

    return dispatch({
      type: "handleInput",
      field: e.target.name,
      payload:
        e.target.value.length < 1 ? initialState[e.target.name] : e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    dispatch({
      type: "handleServerError",
      payload: null
    });

    let { identityNo, firstname, lastname, email, telephone, role } = state;
    email = trim(email);

    if (
      identityNo === user.IdentityNo &&
      firstname === user.FirstName &&
      lastname === user.LastName &&
      email === user.Email &&
      telephone === user.Phone &&
      role === user.Role
    ) {
      return dispatch({
        type: "handleServerError",
        payload: "Inga ändringar har gjorts." // No changes made
      });
    }

    const formData = {
      identityNo,
      firstname,
      lastname,
      email,
      telephone,
      role
    };

    const inputErrors = validateFields(formData);
    if (inputErrors)
      return dispatch({
        type: "handleInputErrors",
        payload: inputErrors
      });

    dispatch({
      type: "fetching",
      payload: true
    });

    const axios = initAxios("private");
    axiosGlobalController.current = axios.axiosController;

    try {
      const response = await axios.axiosInstance.post("/user/backoffice/edit", {
        ...formData,
        legalEntityId: user.LegalEntityId
      });

      if (response.status !== 204)
        return dispatch({
          type: "handleServerError",
          payload: "Something went wrong. Please contact support."
        });

      // Update state with new data
      dispatch({
        type: "setState",
        payload: {
          formData
        }
      });
      await refreshTable();
      // close modal via prop function (no further state changes)
      toggleModal();
    } catch (err) {
      const msg = err?.response?.data?.msg;

      if (msg) {
        return dispatch({
          type: "handleServerError",
          payload: msg
        });
      }

      errorHandler.serverError(err);
      return dispatch({
        type: "handleServerError",
        payload: `Error: ${err?.msg || "Unknown error."}`
      });
    }
  };

  useEffect(() => {
    return () => axiosGlobalController.current?.abort();
  }, [axiosGlobalController]);

  return {
    user,
    state,
    closeModal,
    handleInputChange,
    handleSubmit
  };
};

export default useAddUserModal;
