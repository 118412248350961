// import logo from "./logo.svg";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import SectionHeaderPaper from "../../theme/styled/SectionHeaderPaper";
import SectionWrapperBox from "../../theme/styled/SectionWrapperBox";

import PoaTable from "./PoaTable";

// import useResponsive from "../../hooks/useResponsive";

const PoaHandlingSection = () => {
  // const isMobile = useResponsive("only", "xs");

  return (
    <Box>
      <SectionHeaderPaper>
        <Typography variant="h3" align="left">
          Fullmakter
        </Typography>
        {/* {!isMobile && (
          <Typography variant="subtitle1">
            Här kan du se dina transaktioner
          </Typography>
        )} */}
      </SectionHeaderPaper>
      <SectionWrapperBox sx={{ flexFlow: "column", mx: 2.5, mt: 5 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <PoaTable />
        </LocalizationProvider>
      </SectionWrapperBox>
    </Box>
  );
};
export default PoaHandlingSection;
