import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import ExitIcon from "@mui/icons-material/ExitToAppOutlined";

import useResponsive from "../../hooks/useResponsive";

import useAxios from "../../hooks/useAxios";
import useErrorHandler from "../../hooks/useErrorHandler";

import { AuthConsumer } from "../../contexts/AuthProvider";
import { MenuDrawerConsumer } from "../../contexts/MenuDrawerProvider";

const useHeader = () => {
  const location = useLocation();
  const isLaptopOrDesktop = useResponsive("up", "md");
  const authCtxt = AuthConsumer();
  const menuDrawerCtxt = MenuDrawerConsumer();
  const locationPath = location.pathname;

  const { initAxios } = useAxios();
  const errorHandler = useErrorHandler();

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const navigate = useNavigate();

  const { user, elevatedUser } = authCtxt;

  const isPoaAgent = user?.IsPoaAgent ? true : false;
  const isImpersonatedUser =
    user?.Role === "Customer" && elevatedUser ? true : false;
  // check if above can/should be changed as per below
  // const isImpersonatedUser =
  //   user?.Role === "Customer" && elevatedUser?.Role !== "Customer";

  const handleLogoClick = () => {
    setAnchorElNav(null);
    setAnchorElUser(null);
    return navigate("/dashboard");
  };

  const handleOpenNavMenu = () => {
    return menuDrawerCtxt.toggleDrawer();
  };

  const handleOpenUserMenu = event => {
    setMenuOpen(true);
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setMenuOpen(false);
    setAnchorElUser(null);
  };

  const toggleUserMenu = () => {
    setMenuOpen(prevState => !prevState);
  };

  const poaSwitchUser = async () => {
    const { axiosInstance } = initAxios("auth");
    setAnchorElNav(null);
    setAnchorElUser(null);
    try {
      await axiosInstance.get("auth/switch/elevated");
      return navigate("/poa");
    } catch (err) {
      errorHandler.serverError(err);
    }
  };

  const backofficeSwitchUser = async () => {
    const { axiosInstance } = initAxios("auth");
    setAnchorElNav(null);
    setAnchorElUser(null);
    try {
      await axiosInstance.get("auth/switch/elevated");
      authCtxt.login(elevatedUser);
      authCtxt.setIsLoaded(false);
      return navigate("/dashboard");
    } catch (err) {
      errorHandler.serverError(err);
    }
  };

  const settings = [
    // Only show profile option to customers, impersonated customers and poa agents
    ...(isImpersonatedUser || !elevatedUser || isPoaAgent
      ? [
          {
            label: "Kundprofil",
            navTo: "/dashboard/profile",
            handleClick: () => {
              setAnchorElNav(null);
              setAnchorElUser(null);
              return navigate("/dashboard/profile");
            }
          }
        ]
      : []),
    // if customer is being impersonated by a non poa elevated user, show exit icon to backoffice
    ...(isImpersonatedUser && elevatedUser && !elevatedUser.IsPoaAgent
      ? [
          {
            icon: <ExitIcon fontSize="small" />,
            label: `${elevatedUser.FirstName} ${elevatedUser.LastName}`,
            navTo: null,
            handleClick: backofficeSwitchUser
          }
        ]
      : []),
    // if elevated user is poa show switch account option
    ...(elevatedUser?.IsPoaAgent
      ? [
          {
            label: "Byt användare",
            navTo: null,
            handleClick: poaSwitchUser
          }
        ]
      : []),
    // if user is a non impersonated customer or the elevated user is poa show the log out option
    ...(!isImpersonatedUser || elevatedUser?.IsPoaAgent
      ? [
          {
            icon: <ExitIcon fontSize="small" />,
            label: <span style={{ verticalAlign: "top" }}>Logga ut</span>,
            handleClick: () => {
              setAnchorElNav(null);
              setAnchorElUser(null);
              return authCtxt.logout();
            }
          }
        ]
      : [])
  ];

  return {
    user,
    isLaptopOrDesktop,
    locationPath,
    settings,
    anchorElNav,
    anchorElUser,
    menuOpen,
    handleLogoClick,
    handleOpenNavMenu,
    handleOpenUserMenu,
    toggleUserMenu,
    handleCloseUserMenu
  };
};

export default useHeader;
