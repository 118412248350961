import React from "react";
import PropTypes from "prop-types";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

import FairModalBox from "../../../theme/styled/FairModalBox";
import FairCloseModalBox from "../../../theme/styled/FairCloseModalBox";
import FairButton from "../../../theme/styled/FairButton";

import useAddUserModal from "./useEditUserModal";

const AddUserModal = props => {
  const { open } = props;
  const addUserModal = useAddUserModal(props);
  const { user, state, closeModal, handleInputChange, handleSubmit } =
    addUserModal;

  const { fetching, inputErrors, serverMessage } = state;

  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          closeModal();
        }
      }}
      sx={{
        overflow: "scroll"
      }}
    >
      <FairModalBox
        sx={{
          height: { xs: "90vh", sm: 775 },
          overflow: { xs: "auto", sm: "unset" }
        }}
      >
        <Box
          sx={{
            height: 38,
            px: 4,
            pb: 5
          }}
        >
          <Typography variant="h6" color="primary.main">
            Ändra backoffice-/manuella användare
          </Typography>
        </Box>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            height: 650,
            justifyContent: "center",
            alignItems: "flex-start",
            display: "flex",
            flexFlow: "column",
            px: 4
          }}
        >
          <InputLabel
            htmlFor="identityNo"
            sx={{ fontSize: 14, fontWeight: 500, mb: 1, color: "common.black" }}
          >
            Personnummer
          </InputLabel>
          <TextField
            size="small"
            placeholder={user?.IdentityNo}
            required
            fullWidth
            id="identityNo"
            name="identityNo"
            autoComplete="identityNo"
            error={inputErrors?.identityNo ? true : false}
            onChange={e => {
              handleInputChange(e);
            }}
            helperText={inputErrors?.identityNo}
            sx={{
              height: 40,
              mb: 3
            }}
          />
          <InputLabel
            htmlFor="firstname"
            sx={{ fontSize: 14, fontWeight: 500, mb: 1, color: "common.black" }}
          >
            Förnamn
          </InputLabel>
          <TextField
            size="small"
            placeholder={user?.FirstName}
            required
            fullWidth
            id="firstname"
            name="firstname"
            autoComplete="firstname"
            error={inputErrors?.firstname ? true : false}
            onChange={e => {
              handleInputChange(e);
            }}
            helperText={inputErrors?.firstname}
            sx={{
              height: 40,
              mb: 3
            }}
          />
          <InputLabel
            htmlFor="lastname"
            sx={{ fontSize: 14, fontWeight: 500, mb: 1, color: "common.black" }}
          >
            Efternamn
          </InputLabel>
          <TextField
            size="small"
            placeholder={user?.LastName}
            required
            fullWidth
            id="lastname"
            name="lastname"
            autoComplete="lastname"
            error={inputErrors?.lastname ? true : false}
            onChange={e => {
              handleInputChange(e);
            }}
            helperText={inputErrors?.lastname}
            sx={{
              height: 40,
              mb: 3
            }}
          />
          <InputLabel
            htmlFor="email"
            sx={{ fontSize: 14, fontWeight: 500, mb: 1, color: "common.black" }}
          >
            E-postadress
          </InputLabel>
          <TextField
            size="small"
            placeholder={user?.Email}
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            error={inputErrors?.email ? true : false}
            onChange={e => {
              handleInputChange(e);
            }}
            helperText={inputErrors?.email}
            sx={{
              height: 40,
              mb: 3
            }}
          />
          <InputLabel
            htmlFor="telephone"
            sx={{ fontSize: 14, fontWeight: 500, mb: 1, color: "common.black" }}
          >
            Telefonnummer
          </InputLabel>
          <TextField
            size="small"
            placeholder={user?.Phone}
            required
            fullWidth
            id="telephone"
            name="telephone"
            autoComplete="telephone"
            error={inputErrors?.telephone ? true : false}
            onChange={e => {
              handleInputChange(e);
            }}
            helperText={inputErrors?.telephone}
            sx={{
              height: 40,
              mb: 3
            }}
          />
          <InputLabel
            htmlFor="role"
            sx={{
              fontSize: 14,
              fontWeight: 500,
              mb: 1,
              color: "common.black"
            }}
          >
            Användartyp
          </InputLabel>
          <FormControl
            sx={{ height: 40, width: { xs: "100%", sm: 200 }, mb: 1 }}
          >
            <Select
              size="small"
              labelId="role-select-label"
              id="role"
              name="role"
              value={state.role || ""}
              onChange={e => {
                handleInputChange(e);
              }}
              sx={{ fontSize: "0.8rem", textAlign: "left" }}
            >
              <MenuItem value="Backoffice" sx={{ fontSize: "0.8rem" }}>
                Backoffice
              </MenuItem>
              <MenuItem value="Poa" sx={{ fontSize: "0.8rem" }}>
                Poa
              </MenuItem>
            </Select>
            <FormHelperText error>{inputErrors?.type}</FormHelperText>
          </FormControl>
          <Box
            sx={{
              height: 90,
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <CircularProgress
              color="primary"
              sx={{
                display: fetching ? "flex" : "none"
              }}
            />
            <Alert
              severity={serverMessage?.type === "success" ? "success" : "error"}
              sx={{
                display: serverMessage?.text ? "flex" : "none",
                width: "100%"
              }}
            >
              {serverMessage?.text}
            </Alert>
          </Box>

          <FairButton
            type="submit"
            fullWidth
            sx={{
              mt: 0,
              mb: 2,
              color: "primary.main"
            }}
          >
            Spara
          </FairButton>
        </Box>

        <FairCloseModalBox sx={{ height: 50 }}>
          <Button
            onClick={closeModal}
            sx={{
              color: "primary.lighter"
            }}
          >
            Avbryt
          </Button>
        </FairCloseModalBox>
      </FairModalBox>
    </Modal>
  );
};

AddUserModal.propTypes = {
  open: PropTypes.bool
};

export default AddUserModal;
