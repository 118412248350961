import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// import useResponsive from "../../hooks/useResponsive";
import useAxios from "../../../hooks/useAxios";
import useErrorHandler from "../../../hooks/useErrorHandler";
import { AuthConsumer } from "../../../contexts/AuthProvider";
import { DashboardConsumer } from "../../../contexts/DashboardProvider";

const useNotificationsPopover = () => {
  const navigate = useNavigate();
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const authCtxt = AuthConsumer();
  const dashboardCtx = DashboardConsumer();
  const errorHandler = useErrorHandler();

  const { user, elevatedUser } = authCtxt;
  const cannotSendMessages =
    user?.Role === "Customer" &&
    elevatedUser &&
    elevatedUser.Role !== "Customer" &&
    !elevatedUser.Role !== "Poa" &&
    !elevatedUser.IsPoaAgent;

  const newNotificationsCount = dashboardCtx.state.notifications.filter(
    item => item.IsRead === "0"
  ).length;

  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState(
    dashboardCtx.state.notifications
  );
  const [newCount, setNewCount] = useState(newNotificationsCount);

  const handleClickNotifications = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickNotification = notification => e => {
    e.preventDefault();
    if (!cannotSendMessages && notification.IsRead === "0")
      setNotificationRead(notification.NotificationId);

    switch (notification.Type) {
      case "Message":
        navigate("/dashboard/meddelanden");
        break;
      default:
        break;
    }
    handleCloseNotifications();
  };

  const handleCloseNotifications = () => {
    setAnchorEl(null);
  };

  const setNotificationRead = async notificationId => {
    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;

    axiosInstance
      .get(`notification/read/${notificationId}`)
      .then(response => {
        if (response.status === 204) {
          // set this specific notification as read
          const updatedNotifications = notifications.map(notification => {
            if (notification.NotificationId === notificationId) {
              return { ...notification, IsRead: "1" };
            }
            return notification;
          });
          setNotifications(updatedNotifications);
          // subtract it from newNotificationsCount
          setNewCount(prevState => prevState - 1);
        }
      })
      .catch(err => {
        errorHandler.serverError(err);
      });
  };

  const clearNotifications = () => {
    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;
    handleCloseNotifications();

    axiosInstance
      .get("notifications/clear")
      .then(response => {
        if (response.status === 204) {
          setNotifications([]);
          setNewCount(0);
        }
      })
      .catch(err => {
        errorHandler.serverError(err);
      });
  };

  useEffect(() => {
    return () => axiosGlobalController.current?.abort();
  }, [axiosGlobalController]);

  const isOpen = Boolean(anchorEl);

  return {
    anchorEl,
    newCount,
    handleClickNotifications,
    handleClickNotification,
    handleCloseNotifications,
    isOpen,
    notifications,
    clearNotifications
  };
};

export default useNotificationsPopover;
