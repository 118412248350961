import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const FairCloseModalBox = props => (
  <Box
    {...(!props?.override && props)} // override needs string "true" or ommit (false)
    sx={{
      // do not use shorthand on overrides, props need to match exactly!
      height: 50,
      justifyContent: "center",
      alignItems: "flex-end",
      display: "flex",
      flexFlow: "column",
      paddingLeft: 4,
      paddingRight: 4,
      borderTop: 1,
      borderColor: "grey.300",
      ...props.sx
    }}
    // {...(props.override && props)}
  >
    {props.children}
  </Box>
);

export default FairCloseModalBox;

FairCloseModalBox.propTypes = {
  override: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.node
};
