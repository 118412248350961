import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";

const FairTabs = props => (
  <Tabs
    {...(!props?.override && props)} // override needs string "true" or ommit (false)
    variant="scrollable"
    allowScrollButtonsMobile
    sx={{
      minHeight: "unset",
      "& .MuiButtonBase-root": {
        fontFamily: "Roboto",
        fontSize: "0.8rem",
        minHeight: "unset",
        py: 0.625,
        px: 1.25,
        color: "#2e457e",
        backgroundColor: "#FFF",
        borderRadius: 2.5
      },
      "& .MuiTabs-scrollButtons.Mui-disabled": {
        opacity: 0.3
      },
      "& .Mui-selected": {
        color: "#FFF !important",
        backgroundColor: "#2e457e",
        border: 0
      },
      "& .MuiTabs-indicator": {
        display: "none"
      },
      "& .MuiTabs-scroller": {
        alignSelf: "center"
      },
      ...props.sx
    }}
    {...(props.override && props)}
  >
    {props.children}
  </Tabs>
);

export default FairTabs;

FairTabs.propTypes = {
  override: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.node
};
