import { useReducer, useEffect, useRef } from "react";

import useAxios from "../../../hooks/useAxios";

import addUserModalReducer, {
  initialState
} from "../../../reducers/addUserModalReducer";
import validateFields from "../../../helpers/formValidator";
import trim from "validator/lib/trim";

import useErrorHandler from "../../../hooks/useErrorHandler";

const useAddUserModal = props => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();
  const { toggleModal, refreshTable } = props;
  const [state, dispatch] = useReducer(addUserModalReducer, initialState);

  const toggle = () => {
    dispatch({
      type: "toggle"
    });
    return toggleModal();
  };

  const handleInputChange = e => {
    e.preventDefault();

    return dispatch({
      type: "handleInput",
      field: e.target.name,
      payload: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    dispatch({
      type: "handleServerError",
      payload: null
    });

    let { identityNo, firstname, lastname, email, telephone, role } = state;
    email = trim(email);

    let username;
    switch (role) {
      case "Backoffice":
        username = trim(firstname) + trim(lastname);
        username = username.toLowerCase();
        break;
      case "Poa":
        username = identityNo;
        break;
    }

    const formData = {
      username,
      identityNo,
      firstname,
      lastname,
      email,
      telephone,
      role
    };

    const inputErrors = validateFields(formData);
    if (inputErrors)
      return dispatch({
        type: "handleInputErrors",
        payload: inputErrors
      });

    dispatch({
      type: "fetching",
      payload: true
    });

    const axios = initAxios("private");
    axiosGlobalController.current = axios.axiosController;

    try {
      const response = await axios.axiosInstance.post(
        "/user/backoffice/add",
        formData
      );

      if (response.status !== 204)
        return dispatch({
          type: "handleServerError",
          payload: "Something went wrong. Please contact support."
        });

      await refreshTable();
      toggle();
    } catch (err) {
      errorHandler.serverError(err);
      const msg = err?.response?.data?.msg;

      if (msg) {
        return dispatch({
          type: "handleServerError",
          payload: msg
        });
      }

      return dispatch({
        type: "handleServerError",
        payload: `Error: ${err?.msg || "Unknown error."}`
      });
    }
  };

  useEffect(() => {
    return () => axiosGlobalController.current?.abort();
  }, [axiosGlobalController]);

  return {
    state,
    toggle,
    handleInputChange,
    handleSubmit
  };
};

export default useAddUserModal;
