import React from "react";
import PropTypes from "prop-types";

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import searchIcon from "../../../assets/search_icon.svg";

import FairModalBox from "../../../theme/styled/FairModalBox";
import FairCloseModalBox from "../../../theme/styled/FairCloseModalBox";

import usePoaModal from "./usePoaModal";

import { useTheme } from "@mui/material/styles";

const TablePaginationActions = props => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const PoaModal = () => {
  const {
    isOpen,
    isLoaded,
    elevatedUser,
    copyList,
    searchString,
    page,
    rowsPerPage,
    handleChangePage,
    requestSearch,
    handleAccountClick,
    handleClose
  } = usePoaModal();

  return (
    <Modal
      open={isOpen}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <FairModalBox
        sx={{
          height: 630
        }}
      >
        <Box
          sx={{
            height: 545,
            px: { xs: 2, md: 4 },
            pb: 5
          }}
        >
          <Typography variant="h6" color="primary.main">
            Välj användare
          </Typography>
          {!isLoaded ? (
            <Box
              sx={{
                height: 330,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ mt: 2 }}>
              <TextField
                size="small"
                fullWidth
                placeholder="Sök på namn eller personnummer"
                type="search"
                onInput={e => requestSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box
                        component="img"
                        sx={{
                          height: 20,
                          width: 20,
                          paddingBottom: 0.25
                        }}
                        alt="logo"
                        src={searchIcon}
                      />
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: "20px"
                  }
                }}
              />
              <Table
                aria-label="custom pagination table"
                sx={{ mt: 1, border: 0, tableLayout: "fixed" }}
              >
                <TableBody>
                  {(rowsPerPage > 0
                    ? copyList.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : copyList
                  )
                    // (copyList.length > 0 ? copyList : rows)
                    .map((account, key) => (
                      <TableRow
                        key={key}
                        sx={{
                          height: 50,
                          transition: "color 0.3s, background-color 0.3s",
                          color: "common.black",
                          "&:hover": {
                            backgroundColor: "secondary.main",
                            cursor: "pointer"
                          }
                        }}
                        onClick={handleAccountClick(
                          account.PrincipalLegalEntityId
                        )}
                      >
                        <TableCell
                          align="left"
                          sx={{
                            width: "60%",
                            px: 1.2,
                            py: 0.6,
                            lineHeight: 1,
                            color: "inherit",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden"
                          }}
                        >
                          <Typography
                            variant="modalCell"
                            sx={{
                              fontSize: { xs: "12px", md: "13px" },
                              lineHeight: 1
                            }}
                          >
                            {account.Name}
                          </Typography>
                          {elevatedUser.Role !== "Customer" &&
                            account.PrincipalLegalEntityId && (
                              <Typography
                                variant="modalCell"
                                sx={{
                                  display: "block",
                                  fontSize: "11px",
                                  lineHeight: "1",
                                  color: "#BBB"
                                }}
                              >
                                Senast inloggad:{" "}
                                {account.LastLoginDate
                                  ? `${account.LastLoginDate} (${account.DaysSinceLastLogin})`
                                  : "-"}
                              </Typography>
                            )}
                          {searchString.length > 1 &&
                            account.AccountNumbers?.find(accountNo =>
                              accountNo.includes(searchString)
                            ) && (
                              <Typography
                                variant="modalCell"
                                sx={{
                                  display: "block",
                                  fontSize: "11px",
                                  lineHeight: "1",
                                  color: "primary.lighter"
                                }}
                              >
                                Depå:{" "}
                                {account.AccountNumbers?.find(accountNo =>
                                  accountNo.includes(searchString)
                                )}
                              </Typography>
                            )}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            width: "40%",
                            px: 1.2,
                            py: 0.6,
                            color: "secondary.lighter"
                          }}
                        >
                          <Typography
                            variant="modalCell"
                            sx={{ fontSize: { xs: "12px", md: "inherit" } }}
                          >
                            {account.IdentityNo}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  {copyList.length < 1 && (
                    <TableRow>
                      <TableCell sx={{ border: 0 }}>Inga resultat.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
                {copyList.length > rowsPerPage && (
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        colSpan={2}
                        count={copyList.length}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}
                        page={page}
                        onPageChange={handleChangePage}
                        ActionsComponent={TablePaginationActions}
                        sx={{ border: 0 }}
                      />
                    </TableRow>
                  </TableFooter>
                )}
              </Table>
            </Box>
          )}
        </Box>
        <FairCloseModalBox>
          <Button
            onClick={handleClose}
            sx={{
              color: "primary.lighter"
            }}
          >
            Avbryt
          </Button>
        </FairCloseModalBox>
      </FairModalBox>
    </Modal>
  );
};

export default PoaModal;
