import React from "react";
import PropTypes from "prop-types";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import FairModalBox from "../../theme/styled/FairModalBox";
import FairCloseModalBox from "../../theme/styled/FairCloseModalBox";
import FairButton from "../../theme/styled/FairButton";

import { TimeOutConsumer } from "../../contexts/TimeOutProvider";

const TimeOutModal = () => {
  const timeOut = TimeOutConsumer();
  const { timeOutModal, resetTimeOut, handleClose, countdown } = timeOut;

  return (
    <div>
      <Modal
        open={timeOutModal.expired || timeOutModal.notice}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
      >
        <FairModalBox>
          {timeOutModal.notice ? (
            <>
              <Box
                sx={{
                  height: 40
                }}
              >
                <Typography variant="h6" color="primary.main">
                  Information
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 320
                }}
              >
                <Typography variant="subtitle2" color="common.black">
                  <span>Du kommer att loggas ut automatiskt om </span>
                  <span>{countdown}</span>
                  {countdown <= 1 ? (
                    <span> sekund</span>
                  ) : (
                    <span> sekunder</span>
                  )}
                </Typography>
                <FairButton
                  sx={{
                    mt: 2
                  }}
                  onClick={resetTimeOut}
                >
                  Håll mig inloggad
                </FairButton>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  height: 40
                }}
              >
                <Typography variant="h6" color="primary.main">
                  Information
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 320
                }}
              >
                <Typography variant="subtitle2" color="common.black">
                  Du har loggats ut pga att du varit inaktiv för länge.
                </Typography>
              </Box>
            </>
          )}
          {timeOutModal.expired && (
            <FairCloseModalBox>
              <Button
                onClick={handleClose}
                sx={{
                  color: "primary.lighter"
                }}
              >
                Stäng
              </Button>
            </FairCloseModalBox>
          )}
        </FairModalBox>
      </Modal>
    </div>
  );
};

export default TimeOutModal;

TimeOutModal.propTypes = {
  open: PropTypes.bool
};
