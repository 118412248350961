import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FairButton from "../../theme/styled/FairButton";
import Typography from "@mui/material/Typography";

import HomePaper from "../../theme/styled/HomePaper";

import { HomeConsumer } from "../../contexts/HomeProvider";

const Welcome = () => {
  const homeCtxt = HomeConsumer();

  const openBankIdModal = () => {
    return homeCtxt.setState(prevState => ({
      ...prevState,
      bankIdModal: true
    }));
  };

  const openLoginModal = () => {
    return homeCtxt.setState(prevState => ({
      ...prevState,
      loginModal: true
    }));
  };

  return (
    <HomePaper>
      <Typography variant="h3" align="center">
        Välkommen till Fair Investments
      </Typography>
      <Typography paragraph align="center" sx={{ pt: 2 }}>
        För åtkomst av kundportalen, var god logga in med BankID nedan.
      </Typography>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexFlow: { xs: "column wrap", md: "nowrap" },
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <FairButton
          onClick={openBankIdModal}
          data-testid="openBankIdModal-button"
          // sx={{ mx: 4, my: 1, display: "block" }}
          sx={{
            my: 1,
            mx: 2,
            color: "primary.main"
          }}
        >
          Logga in med BankID
        </FairButton>
        <Button
          onClick={openLoginModal}
          data-testid="openLoginModal-button"
          sx={{
            my: 1,
            mx: 2,
            color: "primary.lighter"
          }}
          // sx={{ mx: 4, my: 1, display: "block" }}
        >
          Logga in med användarnamn
        </Button>
      </Box>
    </HomePaper>
  );
};

export default Welcome;
