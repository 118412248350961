import { useState, useEffect, useCallback, useRef } from "react";

import useAxios from "../../../hooks/useAxios";
import useErrorHandler from "../../../hooks/useErrorHandler";

import { findArrayKeyByPropertyValue } from "../../../helpers/functions";

const useMessage = props => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();
  const {
    cannotSendMessages,
    mailbox,
    message,
    isReply,
    fetchMessages,
    handleReply,
    closeMessage
  } = props;

  const initialState = {
    loaded: false,
    messageThread: []
  };

  const [state, setState] = useState(initialState);

  const getMessageThread = useCallback(async () => {
    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;

    const requests = [];
    const requiresSetRead =
      mailbox === "inbox" && message.IsRead === "0" && !cannotSendMessages
        ? true
        : false;
    const attachedDocIds = message.AttachedDocIds
      ? JSON.parse(message.AttachedDocIds)
      : [];

    const requestMessageThread = axiosInstance.post("/message/thread", {
      messageThreadId: message.MessageThreadId,
      audience: message.Audience
    });

    requests.push(requestMessageThread);

    if (attachedDocIds?.length > 0) {
      const requestMessageDocuments = axiosInstance.post("/documents/message", {
        attachedDocIds
      });

      requests.push(requestMessageDocuments);
    }

    if (requiresSetRead) {
      const requestSetMessageRead = axiosInstance.get(
        `/message/read/${message.MessageId}`
      );
      requests.push(requestSetMessageRead);
    }

    Promise.all(requests)
      .then(responses => {
        setState(prevState => ({
          ...prevState,
          loaded: true,
          messageThread: responses[0].data,
          attachedDocuments: responses[1]?.data
        }));

        if (
          requiresSetRead &&
          responses[attachedDocIds?.length > 0 ? 2 : 1].status === 204
        )
          fetchMessages();
      })
      .catch(function (err) {
        errorHandler.serverError(err);
      });
  }, [
    initAxios,
    mailbox,
    message.IsRead,
    message.AttachedDocIds,
    message.MessageThreadId,
    message.Audience,
    message.MessageId,
    cannotSendMessages,
    fetchMessages,
    errorHandler
  ]);

  const handleDocumentDownload = doc => async e => {
    e.preventDefault();

    // get document key in state.documents array
    const index = findArrayKeyByPropertyValue(
      state.attachedDocuments,
      "DocumentId",
      doc.DocumentId
    );

    // set new document states
    state.attachedDocuments[index].Downloaded = false;
    state.attachedDocuments[index].Downloading = true;
    setState(prevState => ({
      ...prevState,
      attachedDocuments: state.attachedDocuments
    }));

    const { axiosInstance } = initAxios("private");

    try {
      // get document
      const response = await axiosInstance.post("/documents/download", doc, {
        responseType: "arraybuffer"
      });

      // trigger document file download
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = doc.FileName;
      link.click();

      // update document states
      state.attachedDocuments[index].Downloading = false;
      state.attachedDocuments[index].Downloaded = true;
      setState(prevState => ({
        ...prevState,
        attachedDocuments: state.attachedDocuments
      }));
    } catch (err) {
      if (err?.response?.status === 404)
        return console.log("Document not found.");
      errorHandler.serverError(err);
    }
  };

  useEffect(() => {
    if (!state.loaded) {
      getMessageThread();
    }

    return () => axiosGlobalController.current?.abort();
  }, [getMessageThread, state.loaded]);

  return {
    cannotSendMessages,
    state,
    isReply,
    handleReply,
    handleDocumentDownload,
    closeMessage
  };
};

export default useMessage;
