import { useState, useRef, useEffect, useReducer } from "react";
import { isValid, format } from "date-fns";

import useAxios from "../../../../hooks/useAxios";
import addPoaModalReducer, {
  initialState
} from "../../../../reducers/addPoaModalReducer";
import validateFields from "../../../../helpers/formValidator";

import useErrorHandler from "../../../../hooks/useErrorHandler";

const useAddPoaModal = props => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();
  const { open, toggleModal, customersList, refreshTable } = props;

  const [loaded, setLoaded] = useState(true);
  const [state, dispatch] = useReducer(addPoaModalReducer, initialState);

  useEffect(() => {
    dispatch({
      type: "resetInput"
    });
  }, [open]);

  const toggle = () => {
    dispatch({
      type: "toggle"
    });
    return toggleModal();
  };

  const handleInputChange = e => {
    if (e) e.preventDefault();

    return dispatch({
      type: "handleInput",
      field: e.target.name,
      payload: e.target.value
    });
  };

  const setAgent = data => {
    dispatch({
      type: "handleSetAgent",
      payload: data
    });
  };

  const setCustomer = data => {
    dispatch({
      type: "handleSetCustomer",
      payload: data
    });
  };

  const handleDateChange = (field, date) => {
    const isValidDate = isValid(date);
    if (!isValidDate) return;

    return dispatch({
      type: "handleInput",
      field,
      payload: format(new Date(date), "yyyy-MM-dd")
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    dispatch({
      type: "handleServerError",
      payload: null
    });

    let {
      agentLegalEntityId,
      principalLegalEntityId,
      type,
      dateFrom,
      dateTo,
      comment
    } = state;

    const formData = {
      agentLegalEntityId,
      principalLegalEntityId,
      type,
      dateFrom,
      dateTo,
      comment
    };

    const inputErrors = validateFields(formData);
    if (inputErrors)
      return dispatch({
        type: "handleInputErrors",
        payload: inputErrors
      });

    dispatch({
      type: "fetching",
      payload: true
    });

    const axios = initAxios("private");
    axiosGlobalController.current = axios.axiosController;

    try {
      const response = await axios.axiosInstance.post("/poa/add", {
        ...formData
      });

      if (response.status !== 204)
        return dispatch({
          type: "handleServerError",
          payload: "Something went wrong. Please contact support."
        });

      await refreshTable();
      toggle();
    } catch (err) {
      console.log(err);
      const msg = err?.response?.data?.msg;

      if (msg) {
        return dispatch({
          type: "handleServerError",
          payload: msg
        });
      }

      errorHandler.serverError(err);
      return dispatch({
        type: "handleServerError",
        payload: `Error: ${err?.msg || "Unknown error."}`
      });
    }
  };

  return {
    open,
    loaded,
    setLoaded,
    state,
    dispatch,
    toggle,
    setAgent,
    customersList,
    setCustomer,
    handleInputChange,
    handleDateChange,
    handleSubmit
  };
};

export default useAddPoaModal;
