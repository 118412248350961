import React from "react";
import PropTypes from "prop-types";

import {
  Box,
  FormControl,
  FormHelperText,
  Typography,
  Select,
  MenuItem
} from "@mui/material";

const AudienceSelect = props => {
  const { audience, audiences, setAudience, error } = props;

  const handleSelectChange = (e, v) => {
    e.preventDefault();
    setAudience(v.props.value);
  };

  return (
    <Box sx={{ width: "100%", my: 1 }}>
      <FormControl fullWidth>
        <Select
          size="small"
          placeholder="Ämne"
          labelId="audience-select-label"
          id="audience"
          name="audience-select"
          value={audience}
          onChange={(e, v) => {
            handleSelectChange(e, v);
          }}
          displayEmpty
          renderValue={
            audience
              ? undefined
              : () => (
                  <Typography color="#637381" sx={{ height: 20, mt: "1px" }}>
                    Till...
                  </Typography>
                )
          }
          sx={{
            mb: 1
          }}
        >
          {Object.keys(audiences).map((option, i) => (
            <MenuItem key={i} value={option}>
              {audiences[option]}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText sx={{ color: "error.main" }}>{error}</FormHelperText>
      </FormControl>
    </Box>
  );
};

AudienceSelect.propTypes = {
  disabled: PropTypes.bool,
  audiences: PropTypes.object,
  audience: PropTypes.string,
  setAudience: PropTypes.func,
  recipientId: PropTypes.string,
  error: PropTypes.string
};

export default AudienceSelect;
