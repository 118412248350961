import Bugsnag from "@bugsnag/js";
import { useNavigate } from "react-router-dom";

import { AuthConsumer } from "../contexts/AuthProvider";

const useErrorHandler = () => {
  const navigate = useNavigate();
  const authCtxt = AuthConsumer();

  const serverError = err => {
    const errorMessage = err?.message;
    const errorResponse = err?.response;
    if (!errorMessage && !errorResponse) return;
    const responseMessage = errorResponse?.data?.msg;

    if (responseMessage) {
      switch (responseMessage) {
        case "Invalid token":
        case "Missing token":
          // authCtxt won't be defined for useErrorHandler when called from useAuthProvider so we have to check if it's set
          console.log("Token error, logging out.");
          if (authCtxt?.logout) return authCtxt.logout();
          return navigate("/?login=1");
        case "Account not found":
          return navigate("/dashboard");
        default:
          console.log(err);
          return Bugsnag.notify(new Error(err));
      }
    } else if (errorResponse?.config?.responseType === "arraybuffer") {
      // most likely token error when trying to download a document, just logout
      console.log("Token error, logging out.");
      if (authCtxt?.logout) return authCtxt.logout();
      return navigate("/?login=1");
    } else if (errorMessage) {
      switch (errorMessage) {
        case "canceled":
          console.log("Request canceled");
          break;
        default:
          console.log(err);
          Bugsnag.notify(new Error(err));
          break;
      }
    }
  };

  return { serverError };
};

export default useErrorHandler;
