import React from "react";
import PropTypes from "prop-types";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Typography from "@mui/material/Typography";
import AccountIcon from "@mui/icons-material/PersonOutlined";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const DesktopMenu = props => {
  const handlers = props.handlers;

  const {
    user,
    locationPath,
    menuOpen,
    settings,
    handleCloseUserMenu,
    toggleUserMenu
  } = handlers;

  return (
    <ClickAwayListener onClickAway={handleCloseUserMenu}>
      <Accordion
        expanded={menuOpen}
        onClick={toggleUserMenu}
        sx={{
          display: "inline-block",
          minWidth: 200,
          width: "min-content",
          "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: "48px",
            margin: 0
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            px: 1,
            margin: 0,
            "& .Mui-expanded": { margin: "inherit" },
            "& .MuiAccordionSummary-content.Mui-expanded": {
              margin: 0
            },
            "& .MuiAccordionSummary-content": {
              margin: "inherit",
              alignItems: "center"
            }
          }}
        >
          <AccountIcon
            fontSize="medium"
            sx={{
              mb: 0.25
            }}
          />
          <Typography
            variant="button"
            sx={{
              alignSelf: "center",
              ml: 0.65,
              whiteSpace: "nowrap",
              maxWidth: 150,
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {user?.FirstName && user?.LastName
              ? `${user.FirstName} ${user?.LastName}`
              : user.Name}
            <em
              style={{
                display: "block",
                fontStyle: "normal",
                fontSize: "10px",
                marginTop: "-3px",
                color: "#999"
              }}
            >
              {user.IdentityNo}
            </em>
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <List
            sx={{
              width: "100%",
              maxWidth: "unset",
              bgcolor: "background.paper",
              py: 0
            }}
            component="nav"
          >
            {settings.map((setting, key) => (
              <ListItemButton
                key={key}
                disableRipple
                alignItems="flex-start"
                onClick={() => setting.handleClick()}
                sx={{
                  height: 50,
                  p: 0,
                  m: 0,
                  alignItems: "center",
                  color:
                    setting.navTo === locationPath
                      ? "common.white"
                      : "common.black",
                  backgroundColor:
                    setting.navTo === locationPath
                      ? "primary.light"
                      : "transparent",
                  transition: "color 0.3s, background-color 0.3s",
                  borderBottomLeftRadius:
                    key === settings.length - 1 ? "8px" : "inherit",
                  borderBottomRightRadius:
                    key === settings.length - 1 ? "8px" : "inherit",
                  "&:hover": {
                    color: "common.white",
                    backgroundColor: "primary.light",
                    borderBottomLeftRadius:
                      key === settings.length - 1 ? "8px" : "inherit",
                    borderBottomRightRadius:
                      key === settings.length - 1 ? "8px" : "inherit"
                  }
                }}
              >
                {setting?.icon ? (
                  <>
                    <ListItemIcon
                      sx={{
                        color: "inherit",
                        mt: 0,
                        mb: 0.25,
                        ml: 1.5,
                        minWidth: "25px"
                      }}
                    >
                      {setting.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={setting.label}
                      sx={{
                        "& .MuiListItemText-primary": {
                          fontSize: "0.9rem",
                          ml: 0
                        }
                      }}
                    />
                  </>
                ) : (
                  <ListItemText
                    primary={setting.label}
                    sx={{
                      "& .MuiListItemText-primary": {
                        fontSize: "0.9rem",
                        ml: 1.5
                      }
                    }}
                  />
                )}
              </ListItemButton>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </ClickAwayListener>
  );
};

export default DesktopMenu;

DesktopMenu.propTypes = {
  handlers: PropTypes.object
};
