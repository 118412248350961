import { useState, useEffect, useCallback, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import useAxios from "../../hooks/useAxios";
import {
  getObjectKeyByValue,
  findArrayKeyByPropertyValue
} from "../../helpers/functions";
import useErrorHandler from "../../hooks/useErrorHandler";

import useResponsive from "../../hooks/useResponsive";
import { AuthConsumer } from "../../contexts/AuthProvider";
import { DashboardConsumer } from "../../contexts/DashboardProvider";

const useReports = () => {
  const isMobile = useResponsive("only", "xs");
  const auth = AuthConsumer();
  const dashboardCtxt = DashboardConsumer();
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();
  const location = useLocation();
  const navigate = useNavigate();

  const { documentType } = useParams();
  const documentTypes = {
    arsrapporter: "AnnualStatements",
    portfoljrapporter: "PortfolioStatements",
    radgivningsdokument: "AdviseDocuments",
    kostnaderAvgifter: "CostAndCharges"
  };

  const { isCustomer, isImpersonatedBy } = dashboardCtxt.state;

  const [state, setState] = useState({
    loaded: false,
    currentTab: documentTypes[documentType],
    documents: [],
    filteredDocuments: [],
    searchInput: ""
  });

  const handleTabChange = (e, newValue) => {
    const documentType = getObjectKeyByValue(documentTypes, newValue);
    if (e) navigate(`/dashboard/rapporter/${documentType}`); // only navigate if there's a click event i.e tab click from within reports component.
    setState(prevState => ({
      ...prevState,
      currentTab: newValue,
      filteredDocuments: filterDocuments(prevState.documents, newValue),
      searchInput: ""
    }));
  };

  const requestSearch = searched => {
    // if (page > 0) setPage(0); // TODO: sort this out
    const searchString = searched.toLowerCase();
    setState(prevState => ({
      ...prevState,
      searchInput: searched,
      filteredDocuments: filterDocuments(
        state.documents,
        state.currentTab,
        searchString
      )
    }));
  };

  const handleDocumentDelete = async doc => {
    // get document key in state.documents array
    const index = findArrayKeyByPropertyValue(
      state.documents,
      "DocumentId",
      doc.DocumentId
    );

    // set new document states
    state.documents[index].Deleting = true;
    setState(prevState => ({
      ...prevState,
      documents: state.documents,
      filteredDocuments: filterDocuments(state.documents, state.currentTab)
    }));

    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;

    try {
      // delete document
      await axiosInstance.post("/documents/delete", doc);
      // refresh list of documents
      fetchDocuments();
    } catch (err) {
      if (err?.response?.status === 404)
        return console.log("Document not found.");
      errorHandler.serverError(err);
    }
  };

  const handleDocumentDownload = doc => async e => {
    e.preventDefault();

    // get document key in state.documents array
    const index = findArrayKeyByPropertyValue(
      state.documents,
      "DocumentId",
      doc.DocumentId
    );

    // set new document states
    state.documents[index].Downloaded = false;
    state.documents[index].Downloading = true;
    setState(prevState => ({
      ...prevState,
      documents: state.documents,
      filteredDocuments: filterDocuments(state.documents, state.currentTab)
    }));

    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;

    try {
      // get document
      const response = await axiosInstance.post("/documents/download", doc, {
        responseType: "arraybuffer"
      });

      // trigger document file download
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = doc.FileName;
      link.click();

      // update document states
      state.documents[index].Downloading = false;
      state.documents[index].Downloaded = true;
      setState(prevState => ({
        ...prevState,
        documents: state.documents,
        filteredDocuments: filterDocuments(state.documents, state.currentTab)
      }));
    } catch (err) {
      if (err?.response?.status === 404)
        return console.log("Document not found.");
      errorHandler.serverError(err);
    }
  };

  const filterDocuments = useCallback(
    (documents, type, searchString = false) => {
      return documents.filter(document =>
        !searchString
          ? document.DocumentType === type
          : document.DocumentType === type &&
            document.FileName?.toLowerCase().includes(searchString)
      );
    },
    []
  );

  const fetchDocuments = useCallback(async () => {
    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;

    try {
      let documents = await axiosInstance.get("/documents");
      // if any document type is null treat as "AdviseDocuments"
      documents = documents.data.map(doc =>
        !doc.DocumentType
          ? { ...doc, ["DocumentType"]: "AdviseDocuments" }
          : doc
      );

      setState(prevState => ({
        ...prevState,
        loaded: true,
        documents: documents,
        filteredDocuments: filterDocuments(documents, state.currentTab)
      }));
    } catch (err) {
      errorHandler.serverError(err);
    }
  }, [errorHandler, filterDocuments, initAxios, state.currentTab]);

  useEffect(() => {
    if (!state.loaded) {
      fetchDocuments();
    }

    return () => axiosGlobalController.current?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Trigger handleTabChange without click event if there's a location change called from menuDrawer
    handleTabChange(false, documentTypes[documentType]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return {
    isMobile,
    auth,
    state,
    isCustomer,
    isImpersonatedBy,
    fetchDocuments,
    handleTabChange,
    requestSearch,
    handleDocumentDownload,
    handleDocumentDelete
  };
};

export default useReports;
