import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Box, Typography, IconButton, Button } from "@mui/material";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import UploadIcon from "@mui/icons-material/Upload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CircularProgress from "@mui/material/CircularProgress";

import useAxios from "../../../../hooks/useAxios";
import useErrorHandler from "../../../../hooks/useErrorHandler";

const UploadReports = props => {
  const { auth, type, fetchDocuments } = props;
  const { user, elevatedUser } = auth;
  const { initAxios } = useAxios();
  const errorHandler = useErrorHandler();

  const [uploaded, setUploaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);

  const reset = () => {
    setUploaded(false);
    setUploading(false);
    setFiles([]);
  };

  const handleDrop = acceptedFiles => {
    setUploaded(false);
    acceptedFiles.forEach(file => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        file.rawData = reader.result;
      };
      reader.readAsDataURL(file); // Reads data as Base64 string
    });

    setFiles([...files, ...acceptedFiles]);
  };

  const handleRemoveFile = (index, e) => {
    e.stopPropagation();
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const handleClearFiles = e => {
    e.stopPropagation();
    setFiles([]);
  };

  const handleUploadFiles = async e => {
    e.stopPropagation();
    setUploading(true);

    const fileData = files.map(file => {
      return {
        name: file.name,
        extension: file.name.split(".").pop(),
        size: file.size,
        type: file.type,
        owner: user.LegalEntityId,
        creator: elevatedUser.LegalEntityId,
        data: file.rawData
      };
    });

    const { axiosInstance } = initAxios("private");

    try {
      const response = await axiosInstance.post("/documents/upload", {
        documentType: type,
        fileData
      });

      // refresh list of documents
      await fetchDocuments();

      setFiles([]);
      setUploading(false);

      if (response.data.status === "success") {
        setUploaded(true);
      }
    } catch (err) {
      errorHandler.serverError(err);
    }
  };

  // reset uploader on tab change
  useEffect(() => {
    reset();
  }, [type]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: {
      "image/*": [".png", ".gif", ".jpeg", ".jpg"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc", ".docx"],
      "application/vnd.ms-excel": [".xls", ".xlsx"]
      // "application/vnd.ms-powerpoint",
      // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    }
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        minHeight: "180px",
        border: "1px dashed #a5a5a5",
        padding: "15px",
        backgroundColor: "#f5f5f5",
        cursor: "pointer"
      }}
      {...getRootProps()}
    >
      {uploading ? (
        <CircularProgress />
      ) : (
        <Fragment>
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography variant="body2" color="text.primary">
              Släpp dina filer här...
            </Typography>
          ) : (
            <Typography variant="body2" color="text.primary">
              Dra och släpp filer här eller klicka för att välja filer.
            </Typography>
          )}
          <Typography variant="body2" color="text.primary">
            Accepterade filtyper: PDF, MS Word, MS Excel och bilder.
          </Typography>
          {files.length <= 0 && (
            <CloudUploadIcon color="primary" sx={{ fontSize: 80, mt: 1 }} />
          )}
          {uploaded && (
            <Typography variant="body2" color="text.primary" sx={{ mt: 1 }}>
              Filer har laddats upp!
            </Typography>
          )}

          <Box sx={{ mt: files.length > 0 ? 1 : 0 }}>
            {files.map((file, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#ffffff",
                  border: "1px solid #a5a5a5",
                  borderRadius: "4px",
                  px: "10px",
                  mt: 1
                }}
              >
                <Typography variant="body2">
                  {file.name} ({(file.size / 1024 / 1024).toFixed(2)} MB)
                </Typography>
                <IconButton
                  color="secondary"
                  aria-label="delete file"
                  onClick={e => handleRemoveFile(index, e)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
          </Box>
          {files.length > 0 && (
            <Box sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mx: 1,
                  gap: 1
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={e => handleClearFiles(e)}
                  startIcon={<DeleteForeverIcon />}
                >
                  Radera alla
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={e => handleUploadFiles(e)}
                  startIcon={<UploadIcon />}
                >
                  Ladda upp filer
                </Button>
              </Box>
            </Box>
          )}
        </Fragment>
      )}
    </Box>
  );
};

UploadReports.propTypes = {
  auth: PropTypes.object,
  type: PropTypes.string,
  fetchDocuments: PropTypes.func
};

export default UploadReports;
