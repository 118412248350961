import { useState, useEffect, useCallback, useRef } from "react";

import useAxios from "../../hooks/useAxios";
import useErrorHandler from "../../hooks/useErrorHandler";

const useAuditLog = () => {
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();
  const [state, setState] = useState({ loaded: false });

  const handleLogClick = AuditLogEntryId => {
    const { logs } = state;
    // find array index by object prop value
    const logIndex = logs.findIndex(object => {
      return object["AuditLogEntryId"] === AuditLogEntryId;
    });

    let updatedData = [...logs]; // copy prev state data array
    updatedData[logIndex]["toggled"] = !logs[logIndex].toggled;
    setState(prevState => ({
      ...prevState,
      logs: updatedData
    }));
  };

  const fetchAuditLogData = useCallback(async () => {
    const { axiosInstance, axiosController } = initAxios("private");
    axiosGlobalController.current = axiosController;

    try {
      // Audit logs data request
      const requestAuditLogs = await axiosInstance.get("/user/all/logs");
      setState(prevState => ({
        ...prevState,
        loaded: true,
        logs: requestAuditLogs.data
      }));
    } catch (err) {
      errorHandler.serverError(err);
    }
  }, [errorHandler, initAxios]);

  useEffect(() => {
    if (!state.loaded) {
      fetchAuditLogData();
    }

    return () => axiosGlobalController.current?.abort();
  }, [fetchAuditLogData, state.loaded]);

  return {
    state,
    handleLogClick
  };
};

export default useAuditLog;
